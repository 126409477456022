import randomColor from 'randomcolor';
import { FaCircle } from 'react-icons/fa6';
import styles from 'styles/Revenue360.module.css';

export const Legend = ({ company, smartbid }) => {
    const fillColor = randomColor({
        luminosity: 'light',
        hue: 'orange',
        seed: 0,
    });

    return (
        <div className={styles.legendWrapper}>
            {!smartbid && (
                <div className={styles.legendLabel}>
                    <div style={{ color: fillColor }}>
                        <FaCircle />
                    </div>
                    <span>Market</span>
                </div>
            )}
            <div className={styles.legendLabel}>
                <div style={{ color: `var(--primary-500)` }}>
                    <FaCircle />
                </div>
                <span>{company ? company : 'Your Rate'}</span>
            </div>
        </div>
    );
};
