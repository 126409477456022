import { useContext } from 'react';
import { VehiclesContext, VehiclesDispatch } from './VehiclesProvider';

export function useVehicles() {
    const {
        vehicles,
        vehicle_sort_field,
        vehicle_sort_order,
        sorted_vehicles,
        company_id,
        driver_app_access,
        user_list,
        misc_column_names,
        isValidating,
        performingAction,
    } = useContext(VehiclesContext);
    const { syncVehicles, resetVehicles, setVehicleSort, updateVehicle } =
        useContext(VehiclesDispatch);

    return {
        vehicles,
        vehicle_sort_field,
        vehicle_sort_order,
        sorted_vehicles,
        company_id,
        driver_app_access,
        user_list,
        misc_column_names,
        isValidating,
        performingAction,
        syncVehicles,
        resetVehicles,
        setVehicleSort,
        updateVehicle,
    };
}
