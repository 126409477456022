import { useEffect } from 'react';
import classNames from 'classnames';
import { useUser } from 'components/Accounts';
import styles from 'styles/NavBar.module.css';
import { NavTabs } from './NavTabs';

export const SideNav = ({ open, handler }) => {
    const { user, status } = useUser();

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) {
                handler(false);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handler]);

    return (
        <>
            <div
                className={classNames(
                    styles.sidenav,
                    open && styles.openSidenav,
                    user?.preview && styles.previewOn
                )}
            >
                {status !== 'loading' && <NavTabs />}
            </div>
            <div
                className={classNames(
                    styles.overlay,
                    open && styles.openSidenav
                )}
                onClick={() => handler(false)}
            />
        </>
    );
};
