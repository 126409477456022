import React, { lazy, Suspense } from 'react';
import { ProtectedPage } from 'components/Page';
import styles from 'styles/Login.module.css';

const LoginCard = lazy(() =>
    import('components/LoginCard').then((module) => ({
        default: module.LoginCard,
    }))
);

const Login = () => {
    return (
        <ProtectedPage
            title='Login'
            restrictions={['logged-in']}
            includeBackground
            hideSideNav
        >
            <Suspense fallback={<></>}>
                <div className={styles.content}>
                    <LoginCard />
                </div>
            </Suspense>
        </ProtectedPage>
    );
};

export default Login;
