import classNames from 'classnames';
import {
    FaCircleDot,
    FaRegCircle,
    FaRegSquare,
    FaSquareCheck,
} from 'react-icons/fa6';
import styles from 'styles/Revenue360.module.css';

export const PDFInterface = ({
    usageView,
    ratesView,
    loadsView,
    userCount,
    filteredCount,
    usageDateSpan,
    ratesDateSpan,
    loadsDateSpan,
    selected,
    setSelected,
    orientation,
    setOrientation,
}) => {
    const selectOptions = [
        {
            key: 'pdf-chart-smartbid-usage',
            onClick: () => {
                setSelected((prev) => ({
                    smartbidUsage: !prev.smartbidUsage,
                    ratesComparison: prev.ratesComparison,
                    loadsComparison: prev.loadsComparison,
                }));
            },
            selected: selected.smartbidUsage,
            label: (
                <span>
                    SmartBid Usage Trend&nbsp;
                    <span
                        style={{
                            fontSize: 'var(--text-base)',
                            color: 'var(--primary-500)',
                        }}
                    >
                        ({usageDateSpan}
                        {usageView !== 'Individual' && `, ${usageView}`}
                        {userCount !== filteredCount &&
                            usageView === 'Individual' &&
                            `, ${filteredCount}/${userCount} Users`}
                        )
                    </span>
                </span>
            ),
        },
        {
            key: 'csv-chart-rates-comparison',
            onClick: () => {
                setSelected((prev) => ({
                    smartbidUsage: prev.smartbidUsage,
                    ratesComparison: !prev.ratesComparison,
                    loadsComparison: prev.loadsComparison,
                }));
            },
            selected: selected.ratesComparison,
            label: (
                <span>
                    Rates Comparison&nbsp;
                    <span
                        style={{
                            fontSize: 'var(--text-base)',
                            color: 'var(--primary-500)',
                        }}
                    >
                        ({ratesDateSpan}, {ratesView})
                    </span>
                </span>
            ),
        },
        {
            key: 'csv-chart-loads-comparison',
            onClick: () => {
                setSelected((prev) => ({
                    smartbidUsage: prev.smartbidUsage,
                    ratesComparison: prev.ratesComparison,
                    loadsComparison: !prev.loadsComparison,
                }));
            },
            selected: selected.loadsComparison,
            label: (
                <span>
                    Loads Comparison&nbsp;
                    <span
                        style={{
                            fontSize: 'var(--text-base)',
                            color: 'var(--primary-500)',
                        }}
                    >
                        ({loadsDateSpan}, {loadsView})
                    </span>
                </span>
            ),
        },
    ];

    return (
        <>
            <div>
                <h2>Select Charts</h2>
                <ul>
                    {selectOptions.map(({ key, onClick, selected, label }) => (
                        <li key={key} onClick={() => onClick()}>
                            <div
                                className={classNames(
                                    selected && styles.selectedExport
                                )}
                            >
                                {selected ? <FaSquareCheck /> : <FaRegSquare />}
                            </div>
                            {label}
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h2>Print Layout</h2>
                <ul>
                    <li onClick={() => setOrientation('landscape')}>
                        <div
                            className={classNames(
                                orientation === 'landscape' &&
                                    styles.selectedExport
                            )}
                        >
                            {orientation === 'landscape' ? (
                                <FaCircleDot />
                            ) : (
                                <FaRegCircle />
                            )}
                        </div>
                        <span>Landscape</span>
                    </li>
                    <li onClick={() => setOrientation('portrait')}>
                        <div
                            className={classNames(
                                orientation === 'portrait' &&
                                    styles.selectedExport
                            )}
                        >
                            {orientation === 'portrait' ? (
                                <FaCircleDot />
                            ) : (
                                <FaRegCircle />
                            )}
                        </div>
                        <span>Portrait</span>
                    </li>
                </ul>
            </div>
        </>
    );
};
