import { useCallback, useEffect, useState } from 'react';
import { smartbidServer } from 'lib/api';
import { useUser } from 'components/Accounts';
import { isValidAdmin } from 'helpers/user';
import { useCompanies } from 'providers/Companies';
import { DropdownButton } from 'components/Revenue360';
import { ProtectedPage } from 'components/Page';
import { isValidString } from 'helpers/condition';

const MetabaseDashboard = ({ admin_selected_company }) => {
    const { user } = useUser();
    const [iframeUrl, setIframeUrl] = useState('');

    const getIframeUrl = useCallback(async () => {
        if (typeof user !== 'undefined' && user !== null) {
            const params = new URLSearchParams([
                [
                    'companyName',
                    user.role === 'admin'
                        ? admin_selected_company
                        : user.company,
                ],
            ]);
            await smartbidServer
                .get('/api/smartbid/metabase', { params })
                .then((res) => {
                    setIframeUrl(res.data.iframe_url);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [user, admin_selected_company]);

    useEffect(() => {
        getIframeUrl();
    }, [getIframeUrl]);

    if (!isValidString(iframeUrl) && user?.company === 'Everest') {
        return (
            <div>LISA Analytics is unavailable for the selected company</div>
        );
    }

    return (
        <iframe
            src={iframeUrl}
            style={{ border: 'none' }}
            height={800}
            title={`${
                user?.role === 'admin' ? admin_selected_company : user?.company
            }-metabase-dashboard`}
        />
    );
};

const LISAAnalytics = () => {
    const [company, setCompany] = useState(undefined);
    const [mounted, setMounted] = useState(false);
    const { companies } = useCompanies();
    const { user } = useUser();
    const companyList = companies.map((obj) => obj.company);

    useEffect(() => {
        if (mounted && user && Object.keys(user).length !== 0) {
            if (!company) {
                setCompany(user.company);
            }
        }
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [company, mounted, user]);

    return (
        <ProtectedPage
            title='LISA Analytics'
            wide
            restrictions={['login-required']}
        >
            {isValidAdmin(user) && companyList && (
                <DropdownButton
                    value={company}
                    setValue={setCompany}
                    options={companyList}
                    width='min-content'
                />
            )}
            <MetabaseDashboard admin_selected_company={company} />
        </ProtectedPage>
    );
};

export default LISAAnalytics;
