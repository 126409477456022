import { useState } from 'react';
import { Button } from 'components/Button';
import { HiChevronDown } from 'react-icons/hi';
import styles from 'styles/Revenue360.module.css';
import classNames from 'classnames';

export const DropdownButton = ({
    value,
    setValue,
    tight,
    width = '11rem',
    color = 'blue',
    options,
    onSelect,
}) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const closeAll = () => {
        setOpenDropdown(false);
    };

    return (
        <>
            <div className={styles.optionsDropdownWrapper} style={{ width }}>
                <div className={classNames(!tight && styles.dateSpanButton)}>
                    <Button
                        uitype='cta'
                        color={color}
                        size='sm'
                        radius='sm'
                        justifyContent='space-between'
                        fullwidth
                        onClick={() => setOpenDropdown(true)}
                    >
                        <span>{value}</span>
                        <HiChevronDown />
                    </Button>
                </div>
                <div
                    className={classNames(
                        styles.dropdownContainer,
                        openDropdown && styles.open
                    )}
                >
                    <ul className={styles.dropdownOptions}>
                        {options.map((option) => (
                            <li
                                id={option}
                                key={option}
                                tabIndex='0'
                                className={classNames(
                                    styles.dropdownOption,
                                    value === option && styles.selected
                                )}
                                onClick={() => {
                                    if (onSelect) onSelect(option);
                                    setValue(option);
                                    closeAll();
                                }}
                                onKeyDown={(e) => {
                                    switch (e.key) {
                                        case 'Enter':
                                            e.preventDefault();
                                            setValue(e.target.id);
                                            closeAll();
                                            break;
                                        case 'Escape':
                                            e.preventDefault();
                                            closeAll();
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div
                className={classNames(
                    styles.overlay,
                    openDropdown && styles.open
                )}
                onClick={() => closeAll()}
            />
        </>
    );
};
