import { Card } from 'components/Card';
import styles from 'styles/Revenue360.module.css';
import Skeleton from 'react-loading-skeleton';
import { UnavailableText } from './UnavailableText';

const TotalRevenueValue = ({ revenue }) => {
    const dollarUS = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    if (
        !revenue ||
        Array.isArray(revenue) ||
        !revenue?.totalRevenue ||
        revenue?.totalRevenue === 0
    ) {
        return <UnavailableText />;
    } else {
        return (
            <span className={styles.kpi}>
                {dollarUS.format(revenue.totalRevenue)}
            </span>
        );
    }
};

export const RevenueChartCard = ({ revenueDailyData, loading }) => {
    return (
        <Card fullWidth fullHeight smallPadding>
            <div className={styles.narrowHeader}>
                <h1 className={styles.heading}>Daily Total Revenue</h1>
            </div>
            <div className={styles.cardContent}>
                {!loading ? (
                    <TotalRevenueValue revenue={revenueDailyData} />
                ) : (
                    <Skeleton
                        style={{ display: 'flex', margin: '40% auto' }}
                        baseColor='var(--secondary-dark)'
                        highlightColor='var(--secondary)'
                        height={41}
                        width={200}
                        borderRadius={5}
                    />
                )}
                {!loading ? (
                    <>
                        <div className={styles.kpiDates}>
                            {/* <DateDisplay dateSpan={dateSpan} /> */}
                        </div>
                    </>
                ) : (
                    <div className={styles.kpiDates}>
                        <Skeleton
                            style={{ display: 'flex' }}
                            baseColor='var(--secondary-dark)'
                            highlightColor='var(--secondary)'
                            height={32}
                            width={90}
                            borderRadius={5}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
};
