import classNames from 'classnames';
import { NavLink, NavLinkProps } from './NavLink';
import { useUser } from 'components/Accounts';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { smartbidServer } from 'lib/api';
import { isValidString } from 'helpers/condition';
import { useVehicles } from 'providers/Vehicles';

export const NavTabs = ({ className }) => {
    const { user, status } = useUser();
    const { driver_app_access } = useVehicles();
    const [iframeUrl, setIframeUrl] = useState('');

    const props = useMemo(() => {
        return { user, status, driver_app_access };
    }, [user, status, driver_app_access]);

    const getIframeUrl = useCallback(async () => {
        if (typeof user !== 'undefined' && user !== null) {
            const params = new URLSearchParams([['companyName', user.company]]);
            await smartbidServer
                .get('/api/smartbid/metabase', { params })
                .then((res) => {
                    setIframeUrl(res.data.iframe_url);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
    }, [user]);

    useEffect(() => {
        getIframeUrl();
    }, [getIframeUrl]);

    return (
        <ul className={classNames(className)}>
            <NavLink {...NavLinkProps('/smartbid', props)} />
            <NavLink {...NavLinkProps('/optirates', props)} />
            <NavLink {...NavLinkProps('/revenue360', props)} />
            <NavLink {...NavLinkProps('/market-map', props)} />
            {(isValidString(iframeUrl) || user?.company === 'Everest') && (
                <NavLink {...NavLinkProps('/lisa-analytics', props)} />
            )}
            <NavLink {...NavLinkProps('/allowlist', props)} />
            <NavLink {...NavLinkProps('/driver-manager', props)} />
            <NavLink {...NavLinkProps('/vehicle-manager', props)} />
            {/* <NavLink
                label='Support'
                pageLink='/support'
            /> */}
            <NavLink {...NavLinkProps('/admin', props)} />
            <NavLink {...NavLinkProps('/login', props)} />
        </ul>
    );
};
