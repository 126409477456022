import React from 'react';
import { useCSVDownloader } from 'react-papaparse';
import { PulseLoader } from 'react-spinners';
import { FaArrowRotateLeft, FaDownload } from 'react-icons/fa6';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { CO2EmissionCopy } from '../Modules/components';
import { ResultsTable } from './ResultsTable';
import styles from 'styles/OptiRates.module.css';

export const BatchResults = ({
    success,
    setSuccess,
    err,
    setErr,
    ratesInfo,
    setRatesInfo,
    parsedData,
    reset,
    isLoading,
}) => {
    const { CSVDownloader, Type } = useCSVDownloader();

    return (
        <div className={styles.module}>
            <div className={styles.moduleHeader}>
                <h2 className={styles.moduleHeading}>Results for Bulk Rates</h2>
                <p className={styles.moduleCopy}>
                    Rates will load into view below.{' '}
                    <b className={styles.semibold}>
                        Download will be available once rates have been fetched
                        for every row, but you may abort the operation early.
                    </b>
                </p>
                <CO2EmissionCopy />
                {!success && (
                    <div
                        className={classNames(
                            styles.loading,
                            styles.moduleCopy
                        )}
                    >
                        <span>Generating bulk rates</span>
                        <PulseLoader
                            color='var(--secondary-darkest)'
                            size={5}
                            speedMultiplier={0.85}
                        />
                    </div>
                )}
                {success && (
                    <p className={classNames('success', styles.moduleCopy)}>
                        Successfully fetched rates!
                    </p>
                )}
                {err && (
                    <p className={classNames('err', styles.moduleCopy)}>
                        {err}
                    </p>
                )}
            </div>
            <div className={styles.moduleFooter}>
                <Button
                    uitype='ghost'
                    size='md'
                    onClick={() => {
                        reset();
                    }}
                >
                    <FaArrowRotateLeft />
                    Go Back to Import
                </Button>
                <div style={{ display: 'flex', gap: 'var(--gap)' }}>
                    {/* {!success && (
                        <Button
                            uitype='cta'
                            size='md'
                            color='red'
                            onClick={() => setAborted(true)}
                        >
                            <FaCircleXmark />
                            Abort Operation
                        </Button>
                    )} */}
                    {success ? (
                        <>
                            <CSVDownloader
                                type={Type.Link}
                                filename='rates-info'
                                config={{ delimiter: ',' }}
                                data={ratesInfo.map(
                                    ({
                                        id, // omit internal values
                                        loading,
                                        fulfilled,
                                        ...row
                                    }) => {
                                        return row;
                                    }
                                )}
                                className={styles.csvDownload}
                            >
                                <Button
                                    uitype='cta'
                                    size='md'
                                    color='primary-dark'
                                >
                                    <FaDownload />
                                    Download Rates
                                </Button>
                            </CSVDownloader>
                        </>
                    ) : (
                        <Button
                            uitype='cta'
                            size='md'
                            color='primary-dark'
                            disabled
                        >
                            <FaDownload />
                            Download Rates
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles.moduleContent}>
                <ResultsTable parsedData={parsedData} isLoading={isLoading} />
            </div>
        </div>
    );
};
