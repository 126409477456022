import { createContext, useMemo, useReducer } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { useUser } from 'components/Accounts';
import { outputError } from 'helpers/error';
import { smartbidServer } from 'lib/api';
import toast from 'react-hot-toast';
import { compareAsc, compareDesc } from 'date-fns';

const fetcher = (url, params) =>
    smartbidServer
        .get(url, { params })
        .then((res) => {
            let payload = {
                vehicles: res.data?.vehicle_list
                    ?.map((veh) => {
                        return {
                            ...veh,
                            location: `${veh.city}, ${veh.state}${
                                veh.zip ? ' ' + veh.zip : ''
                            }`,
                            available_datetime: veh.available_datetime
                                ? new Date(veh.available_datetime)
                                : null,
                            next_pickup_location:
                                veh.next_pickup_city && veh.next_pickup_state
                                    ? `${veh.next_pickup_city}, ${
                                          veh.next_pickup_state
                                      }${
                                          veh.next_pickup_zip
                                              ? ' ' + veh.next_pickup_zip
                                              : ''
                                      }`
                                    : null,
                            next_pickup_datetime: veh.next_pickup_datetime
                                ? new Date(veh.next_pickup_datetime)
                                : null,
                        };
                    })
                    // sort by vehicle ID so vehicles don't rearrange when updated with new data
                    ?.sort((a, b) =>
                        a?.vehicle_id?.localeCompare(b?.vehicle_id, 'en', {
                            numeric: true,
                        })
                    ),
                company_id: res.data?.company_id,
                driver_app_access: res.data?.driver_app_access,
                user_list: res.data?.user_list,
            };
            if (
                typeof res.data?.column_names !== 'undefined' &&
                res.data?.column_names !== null
            ) {
                const { misc_1, misc_2, misc_3 } = res.data?.column_names;
                payload = {
                    ...payload,
                    misc_column_names: {
                        misc_1,
                        misc_2,
                        misc_3,
                    },
                };
            }
            return payload;
        })
        .catch((err) =>
            outputError({
                err,
                source: 'client:vehicles:fetcher:axios_get',
            })
        );

const initialContext = {
    vehicles: [],
    vehicle_sort_field: undefined,
    vehicle_sort_order: undefined,
    sorted_vehicles: [],
    company_id: undefined,
    driver_app_access: undefined,
    user_list: [],
    misc_column_names: undefined,
    isValidating: false,
    performingAction: false, // if a vehicle is being updated by the user
};

const initialDispatch = {
    setVehicleSort: undefined,
    updateVehicle: undefined,
    resetVehicles: undefined,
    syncVehicles: undefined,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'set-performing-action':
            return {
                ...state,
                performingAction: action.payload.performingAction,
            };
        case 'set-vehicle-sort':
            return {
                ...state,
                vehicle_sort_field: action.payload.vehicle_sort_field,
                vehicle_sort_order: action.payload.vehicle_sort_order,
            };
        case 'reset':
            return {
                vehicles: [],
                vehicle_sort_field: undefined,
                vehicle_sort_order: undefined,
                sorted_vehicles: [],
                company_id: undefined,
                driver_app_access: undefined,
                misc_column_names: undefined,
                isValidating: false,
                performingAction: false,
            };
        default:
            throw new Error('Invalid dispatch.');
    }
};

export const VehiclesContext = createContext(initialContext);
export const VehiclesDispatch = createContext(initialDispatch);

export const VehiclesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialContext);
    const { user } = useUser();
    const SWR_CONFIG = [
        '/api/users/manage/vehicles',
        { company: user?.company },
    ];
    const { data, isValidating } = useSWR(
        user?.company ? SWR_CONFIG : null,
        fetcher,
        {
            onErrorRetry: (
                error,
                _key,
                _config,
                revalidate,
                { retryCount }
            ) => {
                // never retry on 404.
                if (error.status === 404) return;
                // only retry up to 10 times.
                if (retryCount >= 10) return;
                // retry after 1.5 seconds.
                setTimeout(() => revalidate({ retryCount }), 1500);
            },
        }
    );
    const { mutate } = useSWRConfig();

    const syncVehicles = async () => {
        await mutate(SWR_CONFIG);
    };

    const setVehicleSort = ({ sort_field, sort_order }) => {
        dispatch({
            type: 'set-vehicle-sort',
            payload: {
                vehicle_sort_field: sort_field,
                vehicle_sort_order: sort_order,
            },
        });
    };

    const updateVehicle = async ({
        vehicle_id,
        company_id = state.company_id,
        params,
    }) => {
        if (!vehicle_id) return;

        toast.loading(`Updating vehicle #${vehicle_id}...`, {
            id: `update-vehicle-${vehicle_id}`,
        });
        dispatch({
            type: 'set-performing-action',
            payload: { performingAction: true },
        });

        await smartbidServer
            .patch('/api/users/manage/vehicles', {
                vehicle_id,
                company_id,
                ...params,
                // format single quotes for sql
                ...(params?.driver_id
                    ? { driver_id: params?.driver_id?.replace(/'/g, "''") }
                    : {}),
                ...(params?.notes
                    ? { notes: params?.notes?.replace(/'/g, "''") }
                    : {}),
            })
            .then(async () => {
                if (params?.multistate_next_delivery) {
                    // if delivery states get defined, clear delivery location
                    await smartbidServer
                        .patch('/api/users/manage/vehicles', {
                            vehicle_id,
                            company_id,
                            next_pickup_city: null,
                            next_pickup_state: null,
                            next_pickup_zip: null,
                        })
                        .then(() => {
                            syncVehicles().then(() => {
                                toast.success(
                                    `Updated vehicle #${vehicle_id}`,
                                    {
                                        id: `update-vehicle-${vehicle_id}`,
                                    }
                                );
                            });
                        });
                } else if (
                    params?.next_pickup_city ||
                    params?.next_pickup_state ||
                    params?.next_pickup_zip
                ) {
                    // if delivery location gets defined, clear delivery states
                    await smartbidServer
                        .patch('/api/users/manage/vehicles', {
                            vehicle_id,
                            company_id,
                            multistate_next_delivery: null,
                        })
                        .then(() => {
                            syncVehicles().then(() => {
                                toast.success(
                                    `Updated vehicle #${vehicle_id}`,
                                    {
                                        id: `update-vehicle-${vehicle_id}`,
                                    }
                                );
                            });
                        });
                } else {
                    syncVehicles().then(() => {
                        toast.success(`Updated vehicle #${vehicle_id}`, {
                            id: `update-vehicle-${vehicle_id}`,
                        });
                    });
                }
            })
            .catch((err) => {
                toast.error(`Failed to update vehicle #${vehicle_id}`, {
                    id: `update-vehicle-${vehicle_id}`,
                });
                outputError({
                    err,
                    source: `client:vehicles:updateVehicle`,
                });
            })
            .finally(() => {
                dispatch({
                    type: 'set-performing-action',
                    payload: { performingAction: false },
                });
            });
    };

    const resetVehicles = () => {
        dispatch({ type: 'reset' });
    };

    const sorted_vehicles = useMemo(
        () =>
            (data?.vehicles ? [...data?.vehicles] : []).sort((a, b) => {
                // the following handles cases where cell values aren't defined or null
                // if a isn't defined or null, place after b
                if (
                    typeof a[state.vehicle_sort_field] === 'undefined' ||
                    a[state.vehicle_sort_field] === null ||
                    (Array.isArray(a[state.vehicle_sort_field]) &&
                        a[state.vehicle_sort_field].length === 0)
                ) {
                    return 1;
                }
                // if b isn't defined or null, place after a
                if (
                    typeof b[state.vehicle_sort_field] === 'undefined' ||
                    b[state.vehicle_sort_field] === null ||
                    (Array.isArray(b[state.vehicle_sort_field]) &&
                        b[state.vehicle_sort_field].length === 0)
                ) {
                    return -1;
                }
                // if a or b are both undefined or null, leave as is
                if (
                    ((typeof a[state.vehicle_sort_field] === 'undefined' ||
                        a[state.vehicle_sort_field] === null) &&
                        (typeof b[state.vehicle_sort_field] === 'undefined' ||
                            b[state.vehicle_sort_field] === null)) ||
                    (Array.isArray(a[state.vehicle_sort_field]) &&
                        a[state.vehicle_sort_field].length === 0 &&
                        Array.isArray(b[state.vehicle_sort_field]) &&
                        b[state.vehicle_sort_field].length === 0)
                ) {
                    return 0;
                }

                // the following code handles the direction the cells should be ordered (i.e. ascending or descending)
                if (state.vehicle_sort_order === 'asc') {
                    if (state.vehicle_sort_field?.includes('datetime')) {
                        return compareAsc(
                            b[state.vehicle_sort_field],
                            a[state.vehicle_sort_field]
                        );
                    }

                    if (Array.isArray(a[state.vehicle_sort_field])) {
                        if (
                            state.vehicle_sort_field === 'assignments' &&
                            a[state.vehicle_sort_field].length ===
                                b[state.vehicle_sort_field].length
                        ) {
                            return a[state.vehicle_sort_field][0]?.name
                                ?.toString()
                                ?.localeCompare(
                                    b[
                                        state.vehicle_sort_field
                                    ][0]?.name?.toString(),
                                    'en',
                                    {
                                        numeric: true,
                                    }
                                );
                        }
                        return (
                            a[state.vehicle_sort_field].length -
                            b[state.vehicle_sort_field].length
                        );
                    }

                    return a[state.vehicle_sort_field]
                        .toString()
                        .localeCompare(
                            b[state.vehicle_sort_field].toString(),
                            'en',
                            {
                                numeric: true,
                            }
                        );
                } else if (state.vehicle_sort_order === 'desc') {
                    if (state.vehicle_sort_field?.includes('datetime')) {
                        return compareDesc(
                            b[state.vehicle_sort_field],
                            a[state.vehicle_sort_field]
                        );
                    }

                    if (Array.isArray(b[state.vehicle_sort_field])) {
                        if (
                            state.vehicle_sort_field === 'assignments' &&
                            b[state.vehicle_sort_field].length ===
                                a[state.vehicle_sort_field].length
                        ) {
                            return b[state.vehicle_sort_field][0]?.name
                                ?.toString()
                                ?.localeCompare(
                                    a[
                                        state.vehicle_sort_field
                                    ][0]?.name?.toString(),
                                    'en',
                                    {
                                        numeric: true,
                                    }
                                );
                        }
                        return (
                            b[state.vehicle_sort_field].length -
                            a[state.vehicle_sort_field].length
                        );
                    }

                    return b[state.vehicle_sort_field]
                        .toString()
                        .localeCompare(
                            a[state.vehicle_sort_field].toString(),
                            'en',
                            {
                                numeric: true,
                            }
                        );
                } else {
                    return 0;
                }
            }),
        [data?.vehicles, state.vehicle_sort_field, state.vehicle_sort_order]
    );

    return (
        <VehiclesContext.Provider
            value={{
                vehicles: data ? data?.vehicles : [],
                vehicle_sort_field: state.vehicle_sort_field,
                vehicle_sort_order: state.vehicle_sort_order,
                sorted_vehicles,
                company_id: data ? data?.company_id : undefined,
                driver_app_access: data ? data?.driver_app_access : undefined,
                user_list: data?.user_list ?? [],
                misc_column_names: data ? data?.misc_column_names : undefined,
                isValidating,
            }}
        >
            <VehiclesDispatch.Provider
                value={{
                    syncVehicles,
                    resetVehicles,
                    setVehicleSort,
                    updateVehicle,
                }}
            >
                {children}
            </VehiclesDispatch.Provider>
        </VehiclesContext.Provider>
    );
};
