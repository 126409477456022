import { useState } from 'react';
import classNames from 'classnames';
import { HiChevronDown } from 'react-icons/hi';
import { CustomOption } from './CustomOption';
import { getFilteredByDate } from 'components/Revenue360/helpers';
import { Button } from 'components/Button';
import styles from 'styles/Revenue360.module.css';

export const Options = ({
    dateSpan,
    setDateSpan,
    custom,
    data,
    setFiltered,
    referenced,
    smartbid,
}) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openCustom, setOpenCustom] = useState(false);
    const [error, setError] = useState(undefined);
    const options = smartbid
        ? ['Last 7 Days', 'Last 15 Days', 'Last Month']
        : ['Last Week', 'Last 2 Weeks', 'Last 3 Weeks', 'Last Month'];

    const closeAll = () => {
        setOpenDropdown(false);
        setOpenCustom(false);
        setError(undefined);
    };

    return (
        <>
            <div
                className={classNames(
                    styles.optionsDropdownWrapper,
                    dateSpan.includes('Custom') && styles.customSelected
                )}
            >
                <Button
                    uitype='cta'
                    color='primary'
                    size='sm'
                    radius='sm'
                    justifyContent='space-between'
                    fullwidth
                    onClick={() => setOpenDropdown(true)}
                >
                    <span>
                        {dateSpan.includes('Custom')
                            ? dateSpan.replace('Custom: ', '')
                            : dateSpan}
                    </span>
                    <HiChevronDown />
                </Button>
                {!referenced && (
                    <div
                        className={classNames(
                            styles.dropdownContainer,
                            openDropdown && styles.open
                        )}
                    >
                        <ul className={styles.dropdownOptions}>
                            {options.map((option) => (
                                <li
                                    id={option}
                                    key={option}
                                    tabIndex='0'
                                    className={classNames(
                                        styles.dropdownOption,
                                        dateSpan === option && styles.selected
                                    )}
                                    onClick={() => {
                                        setFiltered(
                                            getFilteredByDate(option, data)
                                        );
                                        setDateSpan(option);
                                        closeAll();
                                    }}
                                    onKeyDown={(e) => {
                                        switch (e.key) {
                                            case 'Enter':
                                                e.preventDefault();
                                                setFiltered(
                                                    getFilteredByDate(
                                                        e.target.id,
                                                        data
                                                    )
                                                );
                                                setDateSpan(e.target.id);
                                                closeAll();
                                                break;
                                            case 'Escape':
                                                e.preventDefault();
                                                closeAll();
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    {option}
                                </li>
                            ))}
                            {custom && (
                                <CustomOption
                                    data={data}
                                    dateSpan={dateSpan}
                                    setFiltered={setFiltered}
                                    setDateSpan={setDateSpan}
                                    openCustom={openCustom}
                                    setOpenCustom={setOpenCustom}
                                    error={error}
                                    setError={setError}
                                    closeAll={closeAll}
                                />
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <div
                className={classNames(
                    styles.overlay,
                    openDropdown && styles.open
                )}
                onClick={() => closeAll()}
            />
        </>
    );
};
