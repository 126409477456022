import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { DataCell } from './DataCell';
import styles from 'styles/OptiRates.module.css';
import { FaRegSquare, FaSquareCheck } from 'react-icons/fa6';
import {
    CAN_PROVINCES,
    CAN_PROVINCES_MAPPING,
    USA_STATES,
    USA_STATES_MAPPING,
    USA_ZIP_REGEX,
    CAN_ZIP_REGEX,
    EXTENDED_USA_ZIP_REGEX,
} from 'helpers/location';
const isNull = (val) => {
    return val === '' || val === 'NaN' || val === 'null' || val === 'undefined';
};

const VALID_VEHICLE_CLASSES = [
    'SPRINTER/CARGO VAN',
    'SMALL STRAIGHT',
    'LARGE STRAIGHT',
    'STRAIGHT TRUCK',
    'TRACTOR TRAILER',
    'REFRIGERATED',
    'FLATBED',
    'OTHER',
];

const isValidLocation = (location) => {
    let valid = true;
    try {
        // check if location is only a zip code and if valid
        if (
            !location.includes(',') &&
            !(
                USA_ZIP_REGEX.test(location?.trim()) ||
                CAN_ZIP_REGEX.test(location?.trim())
            )
        ) {
            valid = false;
        }

        // get state and zip code
        let state_zip = location?.split(',')?.at(1)?.trim()?.split(' ');
        if (state_zip) {
            let zip_index = state_zip?.findIndex((e) => {
                return (
                    USA_ZIP_REGEX.test(e?.trim()) ||
                    CAN_ZIP_REGEX.test(e?.trim())
                );
            });
            let state_abbr, zip;

            if (zip_index !== -1) {
                state_abbr = state_zip?.slice(0, zip_index)?.join(' ');
                zip = state_zip?.slice(zip_index)?.join(' ');
            } else {
                state_abbr = state_zip?.join(' ');
            }

            // check if state abbreviation is valid
            if (
                !(
                    USA_STATES.includes(state_abbr?.trim()?.toUpperCase()) ||
                    USA_STATES_MAPPING[state_abbr?.trim()?.toUpperCase()] ||
                    (CAN_PROVINCES.includes(
                        state_abbr?.trim()?.toUpperCase()
                    ) &&
                        state_abbr?.trim()?.toUpperCase() === 'ON') ||
                    (CAN_PROVINCES_MAPPING[state_abbr?.trim()?.toUpperCase()] &&
                        state_abbr?.trim()?.toUpperCase() === 'ONTARIO')
                )
            ) {
                valid = false;
            }

            // check if zip code is valid
            if (
                zip &&
                zip?.trim() !== '' &&
                !(
                    USA_ZIP_REGEX.test(zip?.trim()) ||
                    CAN_ZIP_REGEX.test(zip?.trim())
                )
            ) {
                valid = false;
            }
        }
    } catch (err) {
        valid = false;
    }
    return valid;
};

export const hasError = (row) => {
    if (row.ignored) return false;

    const origin = row['Origin']?.replace(
        EXTENDED_USA_ZIP_REGEX,
        row['Origin']?.match(EXTENDED_USA_ZIP_REGEX)?.at(0)?.slice(0, -5)
    );
    const destination = row['Destination']?.replace(
        EXTENDED_USA_ZIP_REGEX,
        row['Destination']?.match(EXTENDED_USA_ZIP_REGEX)?.at(0)?.slice(0, -5)
    );
    const vehicle_class = row['Vehicle Class'];
    const weight = row['Weight (lb)'];

    return (
        isNull(origin) ||
        isNull(destination) ||
        isNull(vehicle_class) ||
        isNaN(weight) ||
        !isValidLocation(origin?.trim()) ||
        !isValidLocation(destination?.trim()) ||
        !VALID_VEHICLE_CLASSES.includes(vehicle_class?.toUpperCase())
    );
};

export const DataRow = ({
    id,
    row,
    setParsedData,
    selectedRows,
    setSelectedRows,
}) => {
    const {
        register,
        watch,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            [`Origin-${id}`]: row['Origin'],
            [`Destination-${id}`]: row['Destination'],
            [`Vehicle Class-${id}`]: row['Vehicle Class'],
            [`Weight (lb)-${id}`]: row['Weight (lb)'],
        },
    });
    const [err, setErr] = useState(false);

    const [editOrigin, setEditOrigin] = useState(false);
    const [editDestination, setEditDestination] = useState(false);
    const [editVehicleClass, setEditVehicleClass] = useState(false);
    const [editWeight, setEditWeight] = useState(false);

    const origin = useMemo(
        () =>
            row['Origin']?.replace(
                EXTENDED_USA_ZIP_REGEX,
                row['Origin']
                    ?.match(EXTENDED_USA_ZIP_REGEX)
                    ?.at(0)
                    ?.slice(0, -5)
            ),
        [row]
    );
    const destination = useMemo(
        () =>
            row['Destination']?.replace(
                EXTENDED_USA_ZIP_REGEX,
                row['Destination']
                    ?.match(EXTENDED_USA_ZIP_REGEX)
                    ?.at(0)
                    ?.slice(0, -5)
            ),
        [row]
    );
    const vehicle_class = row['Vehicle Class'];
    const weight = row['Weight (lb)'];

    const originHasError = useMemo(
        () => isNull(origin) || !isValidLocation(origin?.trim()),
        [origin]
    );
    const destinationHasError = useMemo(
        () => isNull(destination) || !isValidLocation(destination?.trim()),
        [destination]
    );
    const vehicleClassHasError = useMemo(
        () =>
            isNull(vehicle_class) ||
            !VALID_VEHICLE_CLASSES.includes(vehicle_class?.toUpperCase()),
        [vehicle_class]
    );
    const weightHasError = useMemo(() => isNaN(weight), [weight]);

    const getErrorMessages = useCallback(() => {
        let errors = [];

        const nullOriginMsg = 'Origin is empty';
        const invalidOriginMsg = 'Origin is not valid or is not supported';
        const nullDestinationMsg = 'Destination is empty';
        const invalidDestinationMsg =
            'Destination is not valid or is not supported';
        const nullVehicleClassMsg = 'Vehicle class is empty';
        const invalidVehicleClassMsg = 'Vehicle class is not supported';
        const invalidWeightMsg = 'Weight is not a valid numerical value';

        // check origin and destination
        if (isNull(origin)) {
            errors.push(nullOriginMsg);
        }
        if (!isValidLocation(origin?.trim())) {
            errors.push(invalidOriginMsg);
        }
        if (isNull(destination)) {
            errors.push(nullDestinationMsg);
        }
        if (!isValidLocation(destination?.trim())) {
            errors.push(invalidDestinationMsg);
        }

        // check vehicle class
        if (isNull(vehicle_class)) {
            errors.push(nullVehicleClassMsg);
        }
        if (!VALID_VEHICLE_CLASSES.includes(vehicle_class?.toUpperCase())) {
            errors.push(invalidVehicleClassMsg);
        }

        // check weight
        if (isNaN(weight)) {
            errors.push(invalidWeightMsg);
        }

        return errors;
    }, [origin, destination, vehicle_class, weight]);

    useEffect(() => {
        setErr(false);
        if (hasError(row)) setErr(true);
    }, [row]);

    return (
        <div
            className={classNames(
                styles.reviewTableRow,
                err && styles.flaggedErr,
                row.ignored && styles.flaggedIgnore,
                selectedRows.includes(id) && styles.selected
            )}
        >
            <div className={styles.inner}>
                <span
                    className={classNames(
                        styles.checkbox,
                        selectedRows.includes(id) && styles.selected
                    )}
                >
                    {selectedRows.includes(id) ? (
                        <FaSquareCheck
                            onClick={() =>
                                setSelectedRows((prev) => [
                                    ...prev.slice(
                                        0,
                                        prev.findIndex(
                                            (selected) => selected === id
                                        )
                                    ),
                                    ...prev.slice(
                                        prev.findIndex(
                                            (selected) => selected === id
                                        ) + 1
                                    ),
                                ])
                            }
                        />
                    ) : (
                        <FaRegSquare
                            onClick={() =>
                                setSelectedRows((prev) => [...prev, id])
                            }
                        />
                    )}
                </span>
                <DataCell
                    isEditing={editOrigin}
                    register={register}
                    watch={watch}
                    control={control}
                    errors={errors}
                    setIsEditing={setEditOrigin}
                    attr='Origin'
                    value={origin}
                    id={id}
                    row={row}
                    setParsedData={setParsedData}
                    flaggedErr={originHasError}
                />
                <DataCell
                    isEditing={editDestination}
                    register={register}
                    watch={watch}
                    control={control}
                    errors={errors}
                    setIsEditing={setEditDestination}
                    attr='Destination'
                    value={destination}
                    id={id}
                    row={row}
                    setParsedData={setParsedData}
                    flaggedErr={destinationHasError}
                />
                <DataCell
                    isEditing={editVehicleClass}
                    register={register}
                    watch={watch}
                    control={control}
                    errors={errors}
                    setIsEditing={setEditVehicleClass}
                    attr='Vehicle Class'
                    value={vehicle_class}
                    id={id}
                    row={row}
                    setParsedData={setParsedData}
                    flaggedErr={vehicleClassHasError}
                />
                <DataCell
                    isEditing={editWeight}
                    register={register}
                    watch={watch}
                    control={control}
                    errors={errors}
                    setIsEditing={setEditWeight}
                    attr='Weight (lb)'
                    value={weight}
                    id={id}
                    row={row}
                    setParsedData={setParsedData}
                    flaggedErr={weightHasError}
                />
            </div>
            {err && (
                <div className={styles.errors}>
                    {(getErrorMessages() ?? []).map((msg) => (
                        <span key={`${id}-${msg}`}>⚠ {msg}</span>
                    ))}
                </div>
            )}
        </div>
    );
};
