import classNames from 'classnames';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import { useUser } from 'components/Accounts';
import { TableHeader, UserRow } from './components';
import styles from 'styles/Admin.module.css';

export const UserTable = ({
    setSearch,
    filters,
    setFilters,
    users,
    filteredUsers,
}) => {
    const { user } = useUser();

    return (
        <ScrollSync>
            <div
                className={classNames(
                    styles.container,
                    user?.preview && styles.previewOn
                )}
            >
                <TableHeader
                    setSearch={setSearch}
                    filters={filters}
                    setFilters={setFilters}
                    users={users}
                    count={filteredUsers.length}
                    filteredUsers={filteredUsers}
                />
                <ScrollSyncNode scroll='syncer-only'>
                    <div className={styles.userTable}>
                        {filteredUsers.map((user) => (
                            <UserRow key={user.id} user={user} />
                        ))}
                    </div>
                </ScrollSyncNode>
            </div>
        </ScrollSync>
    );
};
