import React, { lazy, Suspense } from 'react';
import { useUser } from 'components/Accounts';
import { ProtectedPage } from 'components/Page';
import { Slogan } from 'components/Slogan';
import styles from 'styles/Home.module.css';

const LoginCard = lazy(() =>
    import('components/LoginCard').then((module) => ({
        default: module.LoginCard,
    }))
);

const Home = () => {
    const { user, status } = useUser();

    return (
        <ProtectedPage
            restrictions={['logged-in']}
            includeBackground
            hideSideNav
        >
            <Suspense fallback={<></>}>
                <div className={styles.content}>
                    <Slogan />
                    {!user && status !== 'loading' && <LoginCard />}
                </div>
            </Suspense>
        </ProtectedPage>
    );
};

export default Home;
