import { useState } from 'react';
import { Modal } from 'components/Modal';
import { useForm } from 'react-hook-form';
import { Actions } from './components';
import { Header } from './components';
import { AddVehicleForm } from './AddVehicleForm';
import { smartbidServer } from 'lib/api';
import { useUser } from 'components/Accounts';
import { useVehicles } from 'providers/Vehicles';
import toast from 'react-hot-toast';

export const AddVehicleModal = ({ open, handler, randomPassword }) => {
    const [err, setErr] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { user, token } = useUser();
    const { vehicles, syncVehicles } = useVehicles();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            vehicle_class: 'Refrigerated', // TODO: change back to 'Sprinter/Cargo Van' when vehicle class component is brought back
            next_delivery_mode: 'location',
        },
    });

    const onSubmit = async (props) => {
        setLoading(true);
        setErr(undefined);

        // check if vehicle already exists with inputted ID before adding to DB
        if (
            vehicles?.findIndex(
                (vehicle) =>
                    (vehicle?.id || vehicle?.vehicle_id) === props?.vehicle_id
            ) !== -1
        ) {
            toast.error('A vehicle already exists with that id', {
                id: `add-vehicle-${props?.vehicle_id}`,
            });
            setLoading(false);
            setErr('A vehicle already exists with that id');
            setSuccess(false);
            return;
        }

        const config = { headers: { 'x-access-token': token } };
        const request_body = {
            ...props,
            company_id: user?.company_id,
            user_id: user?.id,
            status_description:
                props.status_description?.value || props.status_description,
            next_pickup_location:
                props.next_delivery_mode === 'location'
                    ? props.next_pickup_location
                    : null,
            multistate_next_delivery:
                props.next_delivery_mode === 'multistate' &&
                Array.isArray(props.multistate_next_delivery)
                    ? props.multistate_next_delivery
                          .map((o) => (typeof o === 'object' ? o.value : o))
                          .join(',')
                    : null,
        };
        await smartbidServer
            .post('/api/users/manage/vehicles', request_body, config)
            .then(() => {
                syncVehicles().then(() => {
                    toast.success(`Added vehicle ${props?.vehicle_id}`, {
                        id: `add-vehicle-${props?.vehicle_id}`,
                    });
                });
                setLoading(false);
                setErr(undefined);
                setSuccess(true);
                reset();
            })
            .catch((err) => {
                toast.error(`Failed to add vehicle ${props?.vehicle_id}`, {
                    id: `add-vehicle-${props?.vehicle_id}`,
                });
                setLoading(false);
                setErr(err.response.data.message);
                setSuccess(false);
            });
    };

    return (
        <Modal
            open={open}
            handler={handler}
            reset={() => {
                reset();
                setErr(undefined);
                setSuccess(false);
            }}
            header={<Header err={err} success={success} />}
            actions={
                <Actions
                    handler={handler}
                    reset={() => {
                        reset();
                        setErr(undefined);
                        setSuccess(false);
                    }}
                    loading={loading}
                />
            }
        >
            <AddVehicleForm
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                randomPassword={randomPassword}
                resetOnChange={() => {}}
                watch={watch}
            />
        </Modal>
    );
};
