import { isValidAdmin, isValidRevenue360 } from './user';

// default display restrictions per specified page (by pathname) that affects
// which navtabs user can see and which pages user can navigate to (manually or programmatically)
export const getPageDisplayRestrictions = (user, status, driver_app_access) => {
    const { role, company } = user ?? {};

    return {
        '/login': !user && status !== 'authenticating',
        '/settings': status === 'authenticated',
        '/admin': isValidAdmin(user),
        '/smartbid': status === 'authenticated',
        '/optirates':
            ['Everest', 'DTH Expeditors Inc'].includes(company) &&
            ['admin', 'executive', 'user'].includes(role),
        '/revenue360': isValidRevenue360(user),
        '/lisa-analytics': ['admin', 'executive'].includes(role),
        '/market-map': ['admin', 'executive', 'user'].includes(role),
        '/allowlist': ['admin', 'executive', 'user'].includes(role),
        '/driver-manager':
            Boolean(driver_app_access) && ['admin', 'executive'].includes(role),
        '/vehicle-manager': ['admin', 'executive', 'user'].includes(role),
    };
};
