import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import chartTrendline from 'chartjs-plugin-trendline';
import classNames from 'classnames';
import { ratesChartOptions } from './options';
import styles from 'styles/Revenue360.module.css';
Chart.register(chartTrendline);

const formatNum = (num) => {
    return num?.toFixed(2);
};

export const RatesChart = ({ id, filtered, company, smartbid }) => {
    const options = ratesChartOptions(id);
    const companyRatesArr = filtered.datasets[1].data.map((elem) =>
        elem === null ? undefined : elem
    );
    const trendlineColor = window
        .getComputedStyle(document.getElementById('root'))
        .getPropertyValue('--primary-400');
    const data = {
        datasets: [
            filtered.datasets[0],
            {
                backgroundColor: filtered.datasets[1].backgroundColor,
                borderColor: filtered.datasets[1].borderColor,
                borderWidth: smartbid ? 6 : 3,
                data: companyRatesArr.map((val) =>
                    val !== undefined ? formatNum(parseFloat(val)) : val
                ),
                fill: filtered.datasets[1].fill,
                label: company === 'Demo' ? 'Your Company' : company,
                trendlineLinear: !companyRatesArr.every(
                    (elem) => elem === undefined
                ) && {
                    colorMin: trendlineColor,
                    colorMax: trendlineColor,
                    lineStyle: 'solid',
                    width: 3,
                    projection: false,
                },
            },
        ],
        labels: filtered.labels,
    };

    return (
        <div
            className={classNames(
                styles.chartContainer,
                smartbid && styles.avgRevChart
            )}
            onMouseLeave={() => {
                const chart = Chart.getChart(id);
                options.plugins.annotation.annotations[0].value = -1;
                options.plugins.annotation.annotations[0].endValue = -1;
                chart.update();
            }}
        >
            <Line id={id} height={80} data={data} options={options} />
        </div>
    );
};
