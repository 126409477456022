import { Card } from 'components/Card';
import styles from 'styles/Revenue360.module.css';
import Skeleton from 'react-loading-skeleton';
import { UnavailableText } from './UnavailableText';

const TotalBookedValue = ({ booked }) => {
    if (booked === '0') {
        return <UnavailableText />;
    } else {
        return <span className={styles.kpi}>{booked}</span>;
    }
};

export const HourlyLoadsChartCard = ({ loadsBooked, loading }) => {
    return (
        <Card fullWidth fullHeight smallPadding>
            <div className={styles.header}>
                <h1 className={styles.heading}>Loads Booked Today</h1>
            </div>
            <div className={styles.cardContent}>
                {!loading ? (
                    <TotalBookedValue booked={loadsBooked} />
                ) : (
                    <Skeleton
                        style={{ display: 'flex', margin: '40% auto' }}
                        baseColor='var(--secondary-dark)'
                        highlightColor='var(--secondary)'
                        height={41}
                        width={200}
                        borderRadius={5}
                    />
                )}
            </div>
        </Card>
    );
};
