import React from 'react';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import { useUser } from 'components/Accounts';
import { ResultsRow } from './ResultsRow';
import styles from 'styles/OptiRates.module.css';

export const ResultsTable = ({ parsedData, isLoading }) => {
    const { user } = useUser();
    const short_name = user?.short_name;

    return (
        <ScrollSync>
            <ScrollSyncNode scroll='syncer-only'>
                <div className={styles.importTable}>
                    <div className={styles.importTableHeader}>
                        <div>Origin</div>
                        <div>Origin Country</div>
                        <div>Destination</div>
                        <div>Destination Country</div>
                        <div>Vehicle Class</div>
                        <div>Weight (lb)</div>
                        <div>Distance (mi)</div>
                        <div>
                            CO<sub>2</sub> Emission (metric ton)
                        </div>
                        <div>SmartBid Rate</div>
                        <div>SmartBid Total</div>
                        <div>{short_name} Historical Rate</div>
                        <div>{short_name} Historical Total</div>
                    </div>
                    {parsedData.map((row, idx) => (
                        <ResultsRow
                            key={`${row['Origin']}::${row['Destination']}::${idx}`}
                            parsedData={parsedData}
                            idx={idx}
                            isLoading={isLoading}
                        />
                    ))}
                </div>
            </ScrollSyncNode>
        </ScrollSync>
    );
};
