import React, { useEffect, useState } from 'react';
import { ProtectedPage } from 'components/Page';
import {
    BatchResults,
    ConfirmModule,
    ImportModule,
    ReviewModule,
} from 'components/OptiRates';
import styles from 'styles/OptiRates.module.css';
import { smartbidServer } from 'lib/api';
import { useUser } from 'components/Accounts';
import { outputError } from 'helpers/error';
import { cleanLocation, getCountry } from 'helpers/location';
import { useVehicles } from 'providers/Vehicles';

const VEHICLE_CLASS_RATES_MAPPING = {
    'Sprinter/Cargo Van': 'cargo_vans',
    'Straight Truck': 'straights',
    'Small Straight': 'small_straights',
    'Large Straight': 'large_straights',
    'Tractor Trailer': 'trailers',
    Refrigerated: 'refrigerated',
    Flatbed: 'flatbed',
};

export const EMISSIONS_FACTOR = {
    cargo_vans: 161.8,
    straights: 161.8,
    small_straights: 161.8,
    large_straights: 161.8,
    trailers: 1700,
    refrigerated: 1750,
    flatbed: 1800,
};

// format resulting rate from API
const formatRate = (val) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(val);
};

// format resulting total rate from API
const formatTotalRate = (val) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    }).format(val);
};

// format resulting location from API
const formatLocation = (location) => {
    let formatted = '';

    const city = location.city;
    const state_abbr = location.state;
    const zip = location.zip;

    if (city) {
        formatted = city;
        if (state_abbr) formatted += `, ${state_abbr}`;
        if (zip) formatted += ' ';
    }
    if (zip) formatted += zip;

    return formatted;
};

// convert grams of CO2 to metric tons (standard measurement unit for corporate emissions of greenhouse gases)
// reference: https://business.edf.org/insights/green-freight-math-how-to-calculate-emissions-for-a-truck-move
const convertGramsToMetricTons = (grams) => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    }).format(grams / 1000000);
};

const OptiRates = () => {
    // track whether spreadsheet has been imported
    const [imported, setImported] = useState(false);
    // track whether user has reviewed
    const [reviewed, setReviewed] = useState(false);
    // track whether user has confirmed genereating bulk rates
    const [confirmedBatch, setConfirmedBatch] = useState(false);
    const [parsedData, setParsedData] = useState([]);
    const [ratesInfo, setRatesInfo] = useState([]);
    const [err, setErr] = useState(undefined);
    const [success, setSuccess] = useState(undefined);
    const { user } = useUser();
    const short_name = user?.short_name;
    const pcmilerApiKey = user?.pcmiler_api_key;
    const { company_id } = useVehicles();

    /// track when API resolved already for bulk rates
    const [updatedOnBulk, setUpdatedOnBulk] = useState(false);
    // track when already aborted and values updated accordingly
    // const [updatedOnAbort, setUpdatedOnAbort] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!updatedOnBulk && confirmedBatch) {
            setUpdatedOnBulk(true);
            setIsLoading(true);
            const ratesCalls = [];
            for (let i = 0; i < (parsedData.length + 1) / 500; i++) {
                ratesCalls.push(
                    smartbidServer.post(
                        '/api/smartbid/optirates',
                        {
                            bulkData: JSON.stringify(
                                parsedData
                                    .filter(
                                        (row, ind) =>
                                            !row.ignored &&
                                            ind >= i * 500 &&
                                            ind < (i + 1) * 500
                                    )
                                    .map((row) => {
                                        return {
                                            ...row,
                                            Origin: cleanLocation(
                                                row['Origin']
                                            ),
                                            Destination: cleanLocation(
                                                row['Destination']
                                            ),
                                        };
                                    })
                                    .filter(
                                        (row) => row.Origin && row.Destination
                                    )
                            ),
                            company_id,
                        },
                        {
                            headers: {
                                'pcmiler-authorization': pcmilerApiKey,
                            },
                            timeout: 600000, // reset timeout to ten minutes
                        }
                    )
                );
            }

            Promise.allSettled(ratesCalls)
                .then((res) => {
                    const results = res.reduce(
                        (arr, group) =>
                            group.value ? arr.concat(group.value.data) : arr,
                        []
                    );
                    if (results && ratesInfo) {
                        const updated_rows = [...ratesInfo].map((row) => {
                            const matching_result_idx = results.findIndex(
                                (result) => result?.id === row?.id
                            );

                            if (
                                matching_result_idx === -1 ||
                                !results?.[matching_result_idx]
                            ) {
                                return {
                                    ...row,
                                    'Origin Country': '',
                                    'Destination Country': '',
                                    'Distance (mi)': '',
                                    'SmartBid Rate': '',
                                    'SmartBid Total Rate': '',
                                    [`${short_name} Historical Rate`]: '',
                                    [`${short_name} Historical Total Rate`]: '',
                                    'CO2 Emission (metric ton)': 'N/A',
                                };
                            }

                            const matching_result =
                                results[matching_result_idx];
                            let {
                                Origin,
                                Destination,
                                Mileage: distance,
                                rate_multiplier,
                                smartbid_rate,
                                total_smartbid_rate,
                                historical_rate,
                                total_historical_rate,
                            } = matching_result;

                            // get vehicle class based on mapping for API
                            // and consider "other" vehicle classes as tractor trailers
                            const mapped_vehicle_class =
                                row['Vehicle Class'] !== 'Other'
                                    ? VEHICLE_CLASS_RATES_MAPPING[
                                          row['Vehicle Class']
                                      ]
                                    : VEHICLE_CLASS_RATES_MAPPING[
                                          'Tractor Trailer'
                                      ];

                            // get individual vehicle info
                            const weight = row['Weight (lb)'];

                            const vehicle_rate_multiplier =
                                rate_multiplier || 1;

                            // format rates info
                            smartbid_rate =
                                smartbid_rate && smartbid_rate !== 999999
                                    ? formatRate(
                                          smartbid_rate *
                                              vehicle_rate_multiplier
                                      )
                                    : '';
                            let smartbid_total_rate =
                                smartbid_rate !== ''
                                    ? formatTotalRate(
                                          total_smartbid_rate *
                                              vehicle_rate_multiplier
                                      )
                                    : '';

                            historical_rate =
                                historical_rate && historical_rate !== 999999
                                    ? formatRate(historical_rate)
                                    : '';
                            let historical_total_rate =
                                historical_rate !== ''
                                    ? formatTotalRate(total_historical_rate)
                                    : '';

                            distance =
                                typeof distance !== 'undefined' &&
                                distance !== null &&
                                distance !== 99999
                                    ? distance
                                    : '';

                            let co2_emission =
                                distance !== ''
                                    ? distance *
                                      EMISSIONS_FACTOR[mapped_vehicle_class]
                                    : 'N/A';

                            if (
                                co2_emission !== 'N/A' &&
                                weight !== '' &&
                                // exclude the following vehicles
                                // since their EF is distance-based where weight isn't considered
                                ![
                                    'trailers',
                                    'refrigerated',
                                    'flatbed',
                                ].includes(
                                    EMISSIONS_FACTOR[mapped_vehicle_class]
                                )
                            ) {
                                // convert lb to short ton (2000 lb)
                                co2_emission =
                                    (co2_emission * Number(weight)) / 2000;
                            }
                            if (co2_emission !== 'N/A') {
                                co2_emission =
                                    convertGramsToMetricTons(co2_emission);
                            }

                            // format ETA in hours and mins
                            let eta = '';
                            if (distance !== '') {
                                if (mapped_vehicle_class === 'cargo_vans') {
                                    eta = (distance / 50) * 60 * 60;
                                } else {
                                    eta = (distance / 47) * 60 * 60;
                                }
                                const hr = Math.floor(eta / 60 / 60);
                                const min = Math.round((eta / 60) % 60);
                                eta = `${hr} hr ${min} min`;
                            }

                            if (row['Vehicle Class'] === 'Other') {
                                smartbid_rate = 'Spot Quote';
                                smartbid_total_rate = 'Spot Quote';
                                historical_rate = 'Spot Quote';
                                historical_total_rate = 'Spot Quote';
                            }

                            return {
                                ...row,
                                Origin: formatLocation(Origin),
                                'Origin Country': getCountry(Origin?.state),
                                Destination: formatLocation(Destination),
                                'Destination Country': getCountry(
                                    Destination?.state
                                ),
                                'Distance (mi)': distance,
                                'SmartBid Rate': smartbid_rate,
                                'SmartBid Total Rate': smartbid_total_rate,
                                [`${short_name} Historical Rate`]:
                                    historical_rate,
                                [`${short_name} Historical Total Rate`]:
                                    historical_total_rate,
                                'CO2 Emission (metric ton)': co2_emission,
                            };
                        });
                        setParsedData(updated_rows);
                        setRatesInfo(updated_rows);
                    }
                })
                .catch((err) => {
                    if (err.code === 'ECONNABORTED') {
                        outputError({ err, source: 'optirates request' });
                        setErr('Request timed out. Please try again.');
                    }
                })
                .finally(() => setIsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedOnBulk, confirmedBatch]);

    useEffect(() => {
        if (parsedData.every((row) => row.fulfilled)) {
            setSuccess(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedData, parsedData.every((row) => row.fulfilled), setSuccess]);

    return (
        <ProtectedPage title='OptiRates' wide restrictions={['login-required']}>
            <div className={styles.section}>
                {!confirmedBatch ? (
                    <>
                        {!imported ? (
                            <ImportModule
                                setImported={setImported}
                                setParsedData={setParsedData}
                            />
                        ) : (
                            <>
                                <ReviewModule
                                    processed={reviewed}
                                    parsedData={parsedData}
                                    setParsedData={setParsedData}
                                    setReviewed={setReviewed}
                                    reset={() => {
                                        setImported(false);
                                        setParsedData([]);
                                    }}
                                />
                                <ConfirmModule
                                    processed={!reviewed}
                                    setConfirmedBatch={setConfirmedBatch}
                                    parsedData={parsedData}
                                    setRatesInfo={setRatesInfo}
                                    reset={() => {
                                        setReviewed(false);
                                    }}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <BatchResults
                        success={success}
                        setSuccess={setSuccess}
                        err={err}
                        setErr={setErr}
                        ratesInfo={ratesInfo}
                        setRatesInfo={setRatesInfo}
                        parsedData={parsedData}
                        isLoading={isLoading}
                        reset={() => {
                            setErr(undefined);
                            setSuccess(undefined);
                            setImported(false);
                            setReviewed(false);
                            setConfirmedBatch(false);
                            setParsedData([]);
                            setRatesInfo([]);
                            setUpdatedOnBulk(false);
                            setIsLoading(false);
                        }}
                    />
                )}
            </div>
        </ProtectedPage>
    );
};

export default OptiRates;
