import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useCompanies } from 'providers/Companies';
import { FaSquareCheck, FaRegSquare } from 'react-icons/fa6';
import { usePreview } from 'components/Accounts';
import { Button } from 'components/Button';
import { Input } from 'components/Form';
import { roles, products, accountTypes } from 'data';
import styles from 'styles/PreviewBar.module.css';

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const parseProducts = (subscription) => {
    let parsed = [];
    if (subscription.sb_vans) {
        parsed.push('SB Vans');
    }
    if (subscription.sb_straights) {
        parsed.push('SB Straights');
    }
    if (subscription.sb_margin_calculator) {
        parsed.push('SB Margin Calculator');
    }
    if (subscription.sb_trailers) {
        parsed.push('SB Trailers');
    }
    if (subscription.sb_company_historical) {
        parsed.push('SB Company Historical');
    }
    if (subscription.r360_smartbid_usage) {
        parsed.push('R360 SmartBid Usage');
    }
    if (subscription.r360_van_rates) {
        parsed.push('R360 Van Rates');
    }
    if (subscription.r360_straight_rates) {
        parsed.push('R360 Straight Rates');
    }
    if (subscription.r360_opportunity_map) {
        parsed.push('R360 Opportunity Map');
    }
    if (subscription.r360_combined_rates) {
        parsed.push('R360 Combined Rates');
    }
    if (subscription.r360_van_kpi) {
        parsed.push('R360 Van KPI');
    }
    if (subscription.r360_straight_kpi) {
        parsed.push('R360 Straight KPI');
    }
    if (subscription.r360_pie) {
        parsed.push('R360 Pie');
    }
    if (subscription.r360_scatter) {
        parsed.push('R360 Scatter');
    }
    if (subscription.r360_completed_loads) {
        parsed.push('R360 Completed Loads');
    }
    return parsed;
};

const parseCompanies = (companies) => {
    if (!companies) {
        return [];
    }

    let parsed = [];
    for (let i = 0; i < companies.length; i++) {
        parsed.push({
            value: companies[i],
            label: companies[i],
        });
    }
    return parsed;
};

const SingleSelectOptions = ({ options, buttonLabel, attribute, handler }) => {
    const { preview, update } = usePreview();

    return (
        <ul className={styles.singleSelectOptions}>
            {options.map(({ value, label }) => (
                <li
                    key={value}
                    className={classNames(
                        styles.option,
                        label.toLowerCase() === buttonLabel.toLowerCase() &&
                            styles.selected
                    )}
                    onClick={() => {
                        let newPreview = preview;
                        if (attribute === 'type') {
                            newPreview = {
                                ...preview,
                                subscription: {
                                    ...preview.subscription,
                                    [attribute]: value,
                                },
                            };
                        } else {
                            newPreview = {
                                ...preview,
                                [attribute]: value,
                            };
                        }
                        update(newPreview);
                        handler(false);
                    }}
                >
                    {capitalize(label)}
                </li>
            ))}
        </ul>
    );
};

const CheckboxOptions = ({ options, attribute }) => {
    const { preview, update } = usePreview();
    const currProducts = parseProducts(preview.subscription);

    return (
        <ul className={styles.checkboxOptions}>
            {options.map(({ value, label }) => (
                <li
                    key={value}
                    className={classNames(
                        styles.option,
                        currProducts.includes(label) && styles.checked
                    )}
                    onClick={() => {
                        const newPreview = {
                            ...preview,
                            [attribute]: {
                                ...preview[attribute],
                                [value]: !preview.subscription[value],
                            },
                        };
                        update(newPreview);
                    }}
                >
                    <div>
                        {currProducts.includes(label) ? (
                            <FaSquareCheck />
                        ) : (
                            <FaRegSquare />
                        )}
                    </div>
                    <span>{capitalize(label)}</span>
                </li>
            ))}
        </ul>
    );
};

const RateSensInputOption = ({
    rateSens,
    setRateSens,
    handleInputClose,
    handler,
}) => {
    const { preview, update } = usePreview();
    const {
        handleSubmit,
        register,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            rate_multiplier: preview.rate_multiplier,
        },
    });

    const onSubmit = () => {
        const newPreview = {
            ...preview,
            rate_multiplier: rateSens,
        };
        update(newPreview);
        handler(false);
    };

    return (
        <div className={styles.inputOption}>
            <form id='preview-rate-sens-form' onSubmit={handleSubmit(onSubmit)}>
                <Input
                    type='text'
                    label='rate_multiplier'
                    placeholder='Rate Sensitivity'
                    value={rateSens}
                    register={register}
                    errors={errors}
                    watch={watch}
                    size='sm'
                    inverseColors
                    onChange={(e) => {
                        const format =
                            /^0*([0-9]{1}){0,1}([.]([0-9]{0,2})?)?$|^(100){1}([.]([0]+)?)?$/;
                        if (
                            e.target.value === '' ||
                            format.test(e.target.value)
                        ) {
                            setRateSens(e.target.value);
                        }
                    }}
                    onBlur={(e) => {
                        if (e.target.value !== '') {
                            setRateSens(parseFloat(e.target.value));
                        }
                    }}
                />
            </form>
            <div className={styles.inputActions}>
                <Button
                    uitype='ghost'
                    size='sm'
                    onClick={() => {
                        handleInputClose();
                        reset();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    uitype='cta'
                    color='primary-dark'
                    size='sm'
                    form='preview-rate-sens-form'
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
};

const Option = ({
    open,
    handler,
    buttonLabel,
    options,
    type,
    attribute,
    rateSens,
    setRateSens,
}) => {
    const { preview } = usePreview();

    const handleInputClose = () => {
        handler(false);
        if (setRateSens) {
            setRateSens(preview.rate_multiplier);
        }
    };

    return (
        <span className={classNames(styles.optionWrapper, open && styles.open)}>
            <Button
                uitype='ghost'
                color='primary-dark'
                size='xs'
                onClick={() => handler(true)}
            >
                {buttonLabel}
            </Button>
            <div className={styles.dropdown}>
                {type === 'single-select' && (
                    <SingleSelectOptions
                        options={options}
                        buttonLabel={buttonLabel}
                        attribute={attribute}
                        handler={handler}
                    />
                )}
                {type === 'checkbox' && (
                    <CheckboxOptions options={options} attribute={attribute} />
                )}
                {type === 'input' && attribute === 'rate_multiplier' && (
                    <RateSensInputOption
                        rateSens={rateSens}
                        setRateSens={setRateSens}
                        handleInputClose={handleInputClose}
                        handler={handler}
                    />
                )}
            </div>
            <div
                className={styles.overlay}
                onClick={() => {
                    if (type === 'input') {
                        handleInputClose();
                    } else {
                        handler(false);
                    }
                }}
            />
        </span>
    );
};

export const PreviewBar = ({ wide }) => {
    const { preview, update, disable } = usePreview();
    const { companies } = useCompanies();
    const [rateSens, setRateSens] = useState(preview.rate_multiplier);
    const [openRole, setOpenRole] = useState(false);
    const [openCompany, setOpenCompany] = useState(false);
    const [openRateSens, setOpenRateSens] = useState(false);
    const [openProducts, setOpenProducts] = useState(false);
    const [openType, setOpenType] = useState(false);
    const currProducts = parseProducts(preview.subscription);
    const companyList = companies.map((obj) => obj.company);

    return (
        <div className={classNames(styles.previewBar, wide && styles.wide)}>
            <div className={styles.previewWrapper}>
                <span style={{ visibility: 'hidden' }}>
                    <Button
                        uitype='ghost'
                        color='primary-dark'
                        size='xs'
                        onClick={() => disable()}
                    >
                        Disable
                    </Button>
                </span>
                <div className={styles.previewSettings}>
                    <span
                        style={{ fontWeight: 600, color: 'var(--primary-600)' }}
                    >
                        Previewing
                        {!preview.customized &&
                            ` | 
                            ${preview.inherited?.name} 
                            (${capitalize(preview.role)} @ ${preview.company})`}
                    </span>
                    {!preview.customized ? (
                        <Button
                            uitype='ghost'
                            color='primary-dark'
                            size='xs'
                            onClick={() =>
                                update({ ...preview, customized: true })
                            }
                        >
                            Customize
                        </Button>
                    ) : (
                        <>
                            <Option
                                open={openRole}
                                handler={setOpenRole}
                                buttonLabel={capitalize(preview.role)}
                                options={roles}
                                type='single-select'
                                attribute='role'
                            />
                            <Option
                                open={openCompany}
                                handler={setOpenCompany}
                                buttonLabel={preview.company}
                                options={parseCompanies(companyList)}
                                type='single-select'
                                attribute='company'
                            />
                            <Option
                                open={openRateSens}
                                handler={setOpenRateSens}
                                buttonLabel={`Rate Sens: ${preview.rate_multiplier}`}
                                type='input'
                                attribute='rate_multiplier'
                                rateSens={rateSens}
                                setRateSens={setRateSens}
                            />
                            <Option
                                open={openProducts}
                                handler={setOpenProducts}
                                buttonLabel={`Products (${currProducts.length})`}
                                options={products}
                                type='checkbox'
                                attribute='subscription'
                            />
                            <Option
                                open={openType}
                                handler={setOpenType}
                                buttonLabel={capitalize(
                                    preview.subscription.type
                                )}
                                options={accountTypes}
                                type='single-select'
                                attribute='type'
                            />
                        </>
                    )}
                </div>
                <Button
                    uitype='ghost'
                    color='primary-dark'
                    size='xs'
                    onClick={() => disable()}
                >
                    Exit Preview
                </Button>
            </div>
        </div>
    );
};
