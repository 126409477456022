'use client';

// import {
//     IANA_TIMEZONES,
//     getCurrentOffsetDifference,
// } from '@everest/automail-parser';
import classNames from 'classnames';
import { addHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import DateTimePicker from 'react-datetime-picker';
import { Controller } from 'react-hook-form';
import {
    FaAngleLeft,
    FaAngleRight,
    FaArrowRotateLeft,
    FaCalendarDay,
    FaCircleExclamation,
    FaCircleXmark,
} from 'react-icons/fa6';

// import { useSettings } from 'providers/Settings';

import styles from 'styles/Manage.module.css';

// TODO: copied over from @everest/automail-parser; resolve import issue
export const IANA_TIMEZONES = {
    EDT: 'America/New_York',
    EST: 'America/New_York',
    CDT: 'America/Chicago',
    CST: 'America/Chicago',
    MDT: 'America/Denver',
    MST: 'America/Denver',
    PDT: 'America/Los_Angeles',
    PST: 'America/Los_Angeles',
};

// TODO: copied over from @everest/automail-parser; resolve import issue
export const getOffset = (timezone) => {
    if (timezone === 'EDT') {
        return 4;
    } else if (timezone === 'CDT' || timezone === 'EST') {
        return 5;
    } else if (timezone === 'MDT' || timezone === 'CST') {
        return 6;
    } else if (timezone === 'PDT' || timezone === 'MST') {
        return 7;
    } else if (timezone === 'PST') {
        return 8;
    } else {
        return 0;
    }
};

// TODO: copied over from @everest/automail-parser; resolve import issue
export const inDaylightSavings = (datetime = null) => {
    let start_daylights, end_daylights;
    const curr_date =
        // @ts-ignore
        datetime && !isNaN(new Date(datetime))
            ? new Date(datetime)
            : new Date();

    // second Sunday in March
    start_daylights = new Date(curr_date.getFullYear(), 2, 1);
    start_daylights.setDate(14 - start_daylights.getDay() + 1);

    // first Sunday in November
    end_daylights = new Date(curr_date.getFullYear(), 10, 1);
    end_daylights.setDate(7 - end_daylights.getDay() + 1);

    return curr_date >= start_daylights && curr_date <= end_daylights;
};

// TODO: copied over from @everest/automail-parser; resolve import issue
export const getCurrTimeZone = () => {
    const curr_date = new Date();
    const offset = curr_date.getTimezoneOffset();
    switch (offset / 60) {
        case 4:
            if (inDaylightSavings()) {
                return 'EDT';
            }
            return '';
        case 5:
            if (inDaylightSavings()) {
                return 'CDT';
            }
            return 'EST';
        case 6:
            if (inDaylightSavings()) {
                return 'MDT';
            }
            return 'CST';
        case 7:
            if (inDaylightSavings()) {
                return 'PDT';
            }
            return 'MST';
        case 8:
            if (inDaylightSavings()) {
                return '';
            }
            return 'PST';
        default:
            return '';
    }
};

// TODO: copied over from @everest/automail-parser; resolve import issue
export const getCurrentOffsetDifference = (timezone) => {
    return getOffset(timezone) - getOffset(getCurrTimeZone());
};

const UndoButton = ({ setUndo, dirtyFields }) => {
    if (
        !dirtyFields ||
        (dirtyFields && Object.keys(dirtyFields).length === 0)
    ) {
        return null;
    }
    return (
        <button
            className={classNames(
                'react-datetime-picker__button',
                styles.undoButton
            )}
            onClick={() => setUndo && setUndo(true)}
            type='button'
        >
            <FaArrowRotateLeft />
        </button>
    );
};

const DateTimePickerInput = ({
    control,
    name,
    label,
    setUndo,
    dirtyFields,
    value,
    setValue,
    onChange,
    required,
}) => {
    // const { time_zone } = useSettings(); // TODO: implement useSettings() for smartbid-client
    const time_zone = getCurrTimeZone();
    // const time_zone = 'CDT';
    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required: required,
            }}
            render={({ formState: { errors } }) => (
                <div
                    className={classNames(
                        styles.dateTimeWrapper,
                        errors && errors[name] && styles.error
                    )}
                >
                    <UndoButton setUndo={setUndo} dirtyFields={dirtyFields} />
                    <DateTimePicker
                        onChange={(val) => {
                            // handle conversion on change so value displayed isn't offset
                            const value = val
                                ? addHours(
                                      val,
                                      getCurrentOffsetDifference(time_zone)
                                  )
                                : null;
                            if (onChange) onChange(value);
                            setValue(value);
                        }}
                        value={
                            // pass null to avoid weird date showing when input is cleared
                            value
                                ? utcToZonedTime(
                                      new Date(value),
                                      IANA_TIMEZONES[time_zone]
                                  )
                                : null
                        }
                        format='MM/dd/y HH:mm'
                        disableClock
                        calendarType='US'
                        minDetail='year'
                        monthPlaceholder='MM'
                        dayPlaceholder='dd'
                        yearPlaceholder='yyyy'
                        hourPlaceholder='HH'
                        minutePlaceholder='mm'
                        formatShortWeekday={(locale, date) => {
                            return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][
                                date.getDay()
                            ];
                        }}
                        formatMonth={(locale, date) =>
                            date.toLocaleString(locale, { month: 'short' })
                        }
                        formatMonthYear={(locale, date) =>
                            date.toLocaleString(locale, {
                                month: 'short',
                                year: 'numeric',
                            })
                        }
                        calendarIcon={<FaCalendarDay />}
                        clearIcon={<FaCircleXmark />}
                        prevLabel={<FaAngleLeft />}
                        nextLabel={<FaAngleRight />}
                    />
                    <label>
                        {errors && errors[name] && <FaCircleExclamation />}
                        {label} ({time_zone})
                    </label>
                </div>
            )}
        />
    );
};
export { DateTimePickerInput };
