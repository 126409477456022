export const EXTENDED_USA_ZIP_REGEX = /(?:\d{4,5}[-]\d{4}?)/;

export const USA_ZIP_REGEX = /^(\d{4,5}|\d{9}|\d{4,5}-\d{4})$/;
export const CAN_ZIP_REGEX =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

export const USA_ZIP_REGEX_MATCH = /(\d{4,5}|\d{9}|\d{4,5}-\d{4})/;
export const CAN_ZIP_REGEX_MATCH =
    /[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d/i;

export const USA_STATES = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
];

export const USA_STATES_MAPPING = {
    ALABAMA: 'AL',
    ALASKA: 'AK',
    'AMERICAN SAMOA': 'AS',
    ARIZONA: 'AZ',
    ARKANSAS: 'AR',
    CALIFORNIA: 'CA',
    COLORADO: 'CO',
    CONNECTICUT: 'CT',
    DELAWARE: 'DE',
    'DISTRICT OF COLUMBIA': 'DC',
    FLORIDA: 'FL',
    GEORGIA: 'GA',
    GUAM: 'GU',
    HAWAII: 'HI',
    IDAHO: 'ID',
    ILLINOIS: 'IL',
    INDIANA: 'IN',
    IOWA: 'IA',
    KANSAS: 'KS',
    KENTUCKY: 'KY',
    LOUISIANA: 'LA',
    MAINE: 'ME',
    MARYLAND: 'MD',
    MASSACHUSETTS: 'MA',
    MICHIGAN: 'MI',
    MINNESOTA: 'MN',
    MISSISSIPPI: 'MS',
    MISSOURI: 'MO',
    MONTANA: 'MT',
    NEBRASKA: 'NE',
    NEVADA: 'NV',
    'NEW HAMPSHIRE': 'NH',
    'NEW JERSEY': 'NJ',
    'NEW MEXICO': 'NM',
    'NEW YORK': 'NY',
    'NORTH CAROLINA': 'NC',
    'NORTH DAKOTA': 'ND',
    OHIO: 'OH',
    OKLAHOMA: 'OK',
    OREGON: 'OR',
    PENNSYLVANIA: 'PA',
    'PUERTO RICO': 'PR',
    'RHODE ISLAND': 'RI',
    'SOUTH CAROLINA': 'SC',
    'SOUTH DAKOTA': 'SD',
    TENNESSEE: 'TN',
    TEXAS: 'TX',
    UTAH: 'UT',
    VERMONT: 'VT',
    'VIRGIN ISLANDS': 'VI',
    VIRGINIA: 'VA',
    WASHINGTON: 'WA',
    'WEST VIRGINIA': 'WV',
    WISCONSIN: 'WI',
    WYOMING: 'WY',
};

export const CAN_PROVINCES = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
];

export const CAN_PROVINCES_MAPPING = {
    ALBERTA: 'AB',
    'BRITISH COLUMBIA': 'BC',
    MANITOBA: 'MB',
    'NEW BRUNSWICK': 'NB',
    'NEWFOUNDLAND AND LABRADOR': 'NL',
    'NORTHWEST TERRITORIES': 'NT',
    'NOVA SCOTIA': 'NS',
    NUNAVUT: 'NU',
    ONTARIO: 'ON',
    'PRINCE EDWARD ISLAND': 'PE',
    QUEBEC: 'QC',
    SASKATCHEWAN: 'SK',
    YUKON: 'YK',
};

export const getCountry = (state) => {
    let country = '';
    if (!state) return country;

    if (
        USA_STATES.includes(state?.trim()?.toUpperCase()) ||
        USA_STATES_MAPPING[state?.trim()?.toUpperCase()]
    ) {
        country = 'USA';
    } else if (
        CAN_PROVINCES.includes(state?.trim()?.toUpperCase()) ||
        CAN_PROVINCES_MAPPING[state?.trim()?.toUpperCase()]
    ) {
        country = 'CAN';
    }
    return country;
};

// clean location to be ready to process when getting rates
export const cleanLocation = (location) => {
    let result = '';
    let city, state_abbr, zip;
    // check if location is only a zip code
    if (
        USA_ZIP_REGEX.test(location?.trim()) ||
        CAN_ZIP_REGEX.test(location?.trim())
    ) {
        if (EXTENDED_USA_ZIP_REGEX.test(location?.trim())) {
            zip = location?.trim().slice(0, -5);
        } else {
            zip = location?.trim();
        }
    } else {
        city = location?.split(',')?.at(0)?.trim();

        // get state and zip code
        let state_zip = location?.split(',')?.at(1)?.trim()?.split(' ');
        let zip_index = state_zip?.findIndex((e) => {
            return (
                USA_ZIP_REGEX.test(e?.trim()) || CAN_ZIP_REGEX.test(e?.trim())
            );
        });

        if (zip_index !== -1) {
            state_abbr = state_zip?.slice(0, zip_index)?.join(' ');
            zip = state_zip?.slice(zip_index)?.join(' ')?.trim();
        } else {
            state_abbr = state_zip?.join(' ');
        }

        // convert full state name to abbreviation
        state_abbr =
            USA_STATES_MAPPING[state_abbr?.trim()?.toUpperCase()] ??
            CAN_PROVINCES[state_abbr?.trim()?.toUpperCase()] ??
            state_abbr?.trim();

        if (EXTENDED_USA_ZIP_REGEX.test(zip?.trim())) {
            zip = zip?.trim().slice(0, -5);
        } else {
            zip = zip?.trim();
        }
    }

    if (city) {
        result = city;
        if (state_abbr) result += `, ${state_abbr}`;
        if (zip) result += ' ';
    }
    if (zip) result += zip;

    return result;
};

export const stripZip = (location) => {
    let result = '';
    let city, state_abbr, zip;
    // check if location is only a zip code
    if (
        USA_ZIP_REGEX.test(location?.trim()) ||
        CAN_ZIP_REGEX.test(location?.trim())
    ) {
        if (EXTENDED_USA_ZIP_REGEX.test(location?.trim())) {
            zip = location?.trim().slice(0, -5);
        } else {
            zip = location?.trim();
        }
    } else {
        city = location?.split(',')?.at(0)?.trim();

        // get state and zip code
        let state_zip = location?.split(',')?.at(1)?.trim()?.split(' ');
        let zip_index = state_zip?.findIndex((e) => {
            return (
                USA_ZIP_REGEX.test(e?.trim()) || CAN_ZIP_REGEX.test(e?.trim())
            );
        });

        if (zip_index !== -1) {
            state_abbr = state_zip?.slice(0, zip_index)?.join(' ');
            zip = state_zip?.slice(zip_index)?.join(' ')?.trim();
        } else {
            state_abbr = state_zip?.join(' ');
        }

        // convert full state name to abbreviation
        state_abbr =
            USA_STATES_MAPPING[state_abbr?.trim()?.toUpperCase()] ??
            CAN_PROVINCES[state_abbr?.trim()?.toUpperCase()] ??
            state_abbr?.trim();

        if (EXTENDED_USA_ZIP_REGEX.test(zip?.trim())) {
            zip = zip?.trim().slice(0, -5);
        } else {
            zip = zip?.trim();
        }
    }

    if (city) {
        result = city;
        if (state_abbr) result += `, ${state_abbr}`;
    }

    return result;
};
