import React, { lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import { ProtectedPage } from 'components/Page';
import { Button } from 'components/Button';
import styles from 'styles/Settings.module.css';

const ChangePasswordCard = lazy(() =>
    import('components/Settings').then((module) => ({
        default: module.ChangePasswordCard,
    }))
);

const Settings = () => {
    const navigate = useNavigate();

    return (
        <ProtectedPage title='Settings' restrictions={['login-required']}>
            <Suspense fallback={<></>}>
                <div className={styles.section}>
                    <div className={styles.header}>
                        <Button
                            uitype='icon'
                            radius='round'
                            color='primary'
                            size='lg'
                            onClick={() => navigate(-1)}
                        >
                            <HiArrowLeft />
                        </Button>
                        <h1>Settings</h1>
                    </div>
                    <div className={styles.wrapper}>
                        <ChangePasswordCard />
                    </div>
                </div>
            </Suspense>
        </ProtectedPage>
    );
};

export default Settings;
