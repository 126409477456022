import classNames from 'classnames';
import styles from 'styles/Modal.module.css';

export const Modal = ({
    open,
    handler,
    reset,
    header,
    children,
    fullWidth,
    actions,
    wide,
}) => {
    return (
        <>
            <div
                className={classNames(
                    styles.modal,
                    open && styles.open,
                    wide && styles.wide
                )}
            >
                <div className={styles.header}>{header}</div>
                <div className={styles.body}>{children}</div>
                {actions && (
                    <div
                        className={classNames(
                            styles.actions,
                            fullWidth && styles.fullWidth
                        )}
                    >
                        {actions}
                    </div>
                )}
            </div>
            <div
                onClick={() => {
                    handler(!open);
                    if (reset) {
                        reset();
                    }
                }}
                className={classNames(styles.overlay, open && styles.open)}
            />
        </>
    );
};
