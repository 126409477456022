import { FaBan } from 'react-icons/fa6';

export const UnavailableText = () => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--gap-quarter)',
            marginBottom: '0.75rem',
            color: 'var(--secondary-darkest)',
            fontWeight: 500,
        }}
    >
        <div style={{ display: 'flex' }}>
            <FaBan />
        </div>
        <span>No data available yet</span>
    </div>
);
