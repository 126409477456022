import { useState, useEffect, forwardRef } from 'react';
// import { HiExternalLink } from 'react-icons/hi';
import { useUser } from 'components/Accounts';
import { Options, Legend, RatesChart, Toggle } from './components';
import {
    RatesLoadsHeaderSkeleton,
    LegendSkeleton,
    ChartSkeleton,
} from './Skeletons';
import { UnavailableText } from './UnavailableText';
import { getFilteredByDate } from './helpers';
import styles from 'styles/Revenue360.module.css';

export const RatesChartCard = forwardRef(
    (
        {
            vanData,
            smallStraightData,
            largeStraightData,
            combinedData,
            company,
            dateSpan,
            setDateSpan,
            ratesView,
            setRatesView,
            loading,
            referenced,
        },
        ref
    ) => {
        const { user } = useUser();
        const sub = user?.subscription;
        const [filtered, setFiltered] = useState(undefined);
        const [views, setViews] = useState([]);
        const [mounted, setMounted] = useState(false);

        const Header = () => (
            <div className={styles.actionsWrapper}>
                <div className={styles.optionsWrapper}>
                    <Options
                        dateSpan={dateSpan}
                        setDateSpan={setDateSpan}
                        data={[
                            vanData,
                            smallStraightData,
                            largeStraightData,
                            combinedData,
                        ]}
                        setFiltered={setFiltered}
                        referenced={referenced}
                    />
                    <Toggle
                        views={views}
                        currentView={ratesView}
                        setCurrentView={setRatesView}
                    />
                </div>
            </div>
        );

        const Charts = () => (
            <>
                {ratesView === 'Sprinter/Cargo Vans' && (
                    <RatesChart
                        id='van-rates-chart'
                        filtered={filtered[0]}
                        company={company}
                    />
                )}
                {ratesView === 'Sm Straight Trucks' &&
                    sub?.r360_straight_rates && (
                        <RatesChart
                            id='sm-straight-rates-chart'
                            filtered={filtered[1]}
                            company={company}
                        />
                    )}
                {ratesView === 'Lg Straight Trucks' &&
                    sub?.r360_straight_rates && (
                        <RatesChart
                            id='lg-straight-rates-chart'
                            filtered={filtered[2]}
                            company={company}
                        />
                    )}
                {ratesView === 'Combined' && sub?.r360_combined_rates && (
                    <RatesChart
                        id='combined-rates-chart'
                        filtered={filtered[3]}
                        company={company}
                    />
                )}
            </>
        );
        useEffect(() => {
            if (
                !loading &&
                (vanData?.labels.length > 0 ||
                    ((smallStraightData?.labels.length > 0 ||
                        largeStraightData?.labels.length > 0) &&
                        combinedData?.labels.length > 0))
            ) {
                setFiltered(
                    getFilteredByDate(dateSpan, [
                        vanData,
                        smallStraightData,
                        largeStraightData,
                        combinedData,
                    ])
                );
            }
            if (mounted) {
                let chart_views = [];
                if (sub?.r360_van_rates) {
                    chart_views.push('Sprinter/Cargo Vans');
                }
                if (sub?.r360_straight_rates) {
                    chart_views.push('Sm Straight Trucks');
                    chart_views.push('Lg Straight Trucks');
                }
                if (sub?.r360_combined_rates) {
                    chart_views.push('Combined');
                }
                setViews(chart_views);

                if (!sub?.r360_straight_rates || !sub?.r360_combined_rates) {
                    setRatesView('Sprinter/Cargo Vans');
                }
                if (!sub?.r360_van_rates) {
                    setRatesView('Sm Straight Trucks');
                }
            }
            setMounted(true);
        }, [
            vanData,
            smallStraightData,
            largeStraightData,
            combinedData,
            loading,
            setFiltered,
            dateSpan,
            mounted,
            sub,
            company,
            setRatesView,
        ]);

        return (
            <>
                {(sub?.r360_van_rates || sub?.r360_straight_rates) && (
                    <div className={styles.chartCard} ref={ref}>
                        <div className={styles.header}>
                            <h1 className={styles.heading}>
                                {'Rates Comparison'}
                            </h1>
                            {loading ? (
                                <RatesLoadsHeaderSkeleton />
                            ) : (
                                mounted && filtered && <Header />
                            )}
                        </div>
                        {loading ? (
                            <LegendSkeleton />
                        ) : (
                            filtered && (
                                <div className={styles.secondHeader}>
                                    <Legend
                                        company={
                                            company !== 'Demo'
                                                ? company
                                                : 'Your Company'
                                        }
                                    />
                                </div>
                            )
                        )}
                        {loading ? (
                            <ChartSkeleton />
                        ) : filtered &&
                          (vanData?.labels.length !== 0 ||
                              smallStraightData?.labels.length !== 0 ||
                              largeStraightData?.labels.length !== 0) ? (
                            <Charts />
                        ) : (
                            <UnavailableText />
                        )}
                    </div>
                )}
            </>
        );
    }
);
