import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Toggle } from '../Toggle';
import { PDFInterface, CSVInterface } from './Interface';
import { CSVDownloader } from './CSVDownloader';
import { formatData } from './helpers';
import { Button } from 'components/Button';
import styles from 'styles/Revenue360.module.css';

export const ExportModal = ({
    loading,
    selected,
    setSelected,
    usageView,
    ratesView,
    loadsView,
    usageDateSpan,
    visibleUsers,
    ratesDateSpan,
    loadsDateSpan,
    orgUsageData,
    userUsageData,
    vanRatesData,
    smallStraightRatesData,
    vanLoadsData,
    smallStraightLoadsData,
    printSmartBidUsage,
    printRatesComparison,
    printLoadsComparison,
    printMultiple,
    printAll,
    orientation,
    setOrientation,
    setPrintable,
    open,
    handler,
}) => {
    const [mounted, setMounted] = useState(false);
    const [formatted, setFormatted] = useState(undefined);
    const [csvSelected, setCSVSelected] = useState('');
    const [currOption, setCurrOption] = useState('PDF');
    const options = ['PDF', 'CSV'];
    const [chartView, setChartView] = useState('');
    const chartViews = {
        smartbidUsage: ['Overall', 'Individual'],
        ratesComparison: ['Sprinter/Cargo Vans', 'Small Straights'],
        loadsComparison: ['Sprinter/Cargo Vans', 'Small Straights'],
    };

    const getSelectCount = () => {
        let count = 0;
        if (selected.smartbidUsage) {
            count++;
        }
        if (selected.ratesComparison) {
            count++;
        }
        if (selected.loadsComparison) {
            count++;
        }
        return count;
    };

    const printSelected = () => {
        if (getSelectCount() > 1) {
            printMultiple();
        } else if (selected.smartbidUsage) {
            printSmartBidUsage();
        } else if (selected.ratesComparison) {
            printRatesComparison();
        } else if (selected.loadsComparison) {
            printLoadsComparison();
        }
    };

    const getCSVData = () => {
        if (csvSelected === 'smartbidUsage') {
            if (chartView === chartViews.smartbidUsage[0]) {
                return formatted.orgUsage.data;
            } else {
                return formatted.userUsage.data;
            }
        } else if (csvSelected === 'ratesComparison') {
            if (chartView === chartViews.ratesComparison[0]) {
                return formatted.vanRates.data;
            } else {
                return formatted.smallStraightRates.data;
            }
        } else if (csvSelected === 'loadsComparison') {
            if (chartView === chartViews.loadsComparison[0]) {
                return formatted.vanLoads.data;
            } else {
                return formatted.smallStraightLoads.data;
            }
        }
    };

    const getCSVFilename = () => {
        if (csvSelected === 'smartbidUsage') {
            if (chartView === chartViews.smartbidUsage[0]) {
                return formatted.orgUsage.filename;
            } else {
                return formatted.userUsage.filename;
            }
        } else if (csvSelected === 'ratesComparison') {
            if (chartView === chartViews.ratesComparison[0]) {
                return formatted.vanRates.filename;
            } else {
                return formatted.smallStraightRates.filename;
            }
        } else if (csvSelected === 'loadsComparison') {
            if (chartView === chartViews.loadsComparison[0]) {
                return formatted.vanLoads.filename;
            } else {
                return formatted.smallStraightLoads.filename;
            }
        }
    };

    useEffect(() => {
        if (mounted) {
            if (
                orgUsageData &&
                userUsageData &&
                vanRatesData &&
                smallStraightRatesData &&
                vanLoadsData &&
                smallStraightLoadsData
            ) {
                setFormatted(
                    formatData(
                        [usageDateSpan, ratesDateSpan, loadsDateSpan],
                        [
                            orgUsageData,
                            userUsageData,
                            vanRatesData,
                            smallStraightRatesData,
                            vanLoadsData,
                            smallStraightLoadsData,
                        ],
                        visibleUsers
                    )
                );
            }
            if (selected.smartbidUsage) {
                setCSVSelected('smartbidUsage');
                setChartView(usageView);
            } else if (selected.ratesComparison) {
                setCSVSelected('ratesComparison');
                setChartView(ratesView);
            } else if (selected.loadsComparison) {
                setCSVSelected('loadsComparison');
                setChartView(loadsView);
            }
        }
        setMounted(true);
    }, [
        mounted,
        selected,
        usageView,
        ratesView,
        loadsView,
        usageDateSpan,
        ratesDateSpan,
        loadsDateSpan,
        orgUsageData,
        userUsageData,
        vanRatesData,
        smallStraightRatesData,
        vanLoadsData,
        smallStraightLoadsData,
        visibleUsers,
    ]);

    return (
        <>
            <div
                className={classNames(
                    styles.exportModal,
                    open && styles.openExport
                )}
            >
                <div className={styles.exportHeader}>
                    <h1>Export</h1>
                    <Toggle
                        views={options}
                        currentView={currOption}
                        setCurrentView={setCurrOption}
                    />
                </div>
                {currOption === options[0] && (
                    <PDFInterface
                        usageView={usageView}
                        ratesView={ratesView}
                        loadsView={loadsView}
                        userCount={userUsageData?.datasets?.length}
                        filteredCount={visibleUsers?.length}
                        usageDateSpan={usageDateSpan}
                        ratesDateSpan={ratesDateSpan}
                        loadsDateSpan={loadsDateSpan}
                        selected={selected}
                        setSelected={setSelected}
                        orientation={orientation}
                        setOrientation={setOrientation}
                    />
                )}
                {currOption === options[1] && (
                    <CSVInterface
                        userCount={userUsageData?.datasets?.length}
                        filteredCount={visibleUsers?.length}
                        usageDateSpan={usageDateSpan}
                        ratesDateSpan={ratesDateSpan}
                        loadsDateSpan={loadsDateSpan}
                        csvSelected={csvSelected}
                        setCSVSelected={setCSVSelected}
                        chartView={chartView}
                        setChartView={setChartView}
                        chartViews={chartViews}
                        usageView={usageView}
                        ratesView={ratesView}
                        loadsView={loadsView}
                    />
                )}
                <div className={styles.exportModalButtons}>
                    <Button
                        uitype='ghost'
                        size='md'
                        onClick={() => {
                            setSelected({
                                smartbidUsage: false,
                                ratesComparison: false,
                                loadsComparison: false,
                            });
                            setCurrOption('PDF');
                            setPrintable(false);
                            handler(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <div className={styles.exportButtons}>
                        {currOption === options[0] && (
                            <>
                                <Button
                                    uitype='cta'
                                    color='primary-dark'
                                    size='md'
                                    disabled={getSelectCount() === 0}
                                    isLoading={loading}
                                    onClick={printSelected}
                                >
                                    Export Selected{' '}
                                    {getSelectCount() !== 0 &&
                                        `(${getSelectCount()})`}
                                </Button>
                                <Button
                                    uitype='cta'
                                    color='primary-dark'
                                    size='md'
                                    isLoading={loading}
                                    onClick={printAll}
                                >
                                    Export All
                                </Button>
                            </>
                        )}
                        {currOption === options[1] && (
                            <CSVDownloader
                                data={getCSVData()}
                                filename={getCSVFilename()}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div
                className={classNames(
                    styles.exportOverlay,
                    open && styles.open
                )}
                onClick={() => {
                    setSelected({
                        smartbidUsage: false,
                        ratesComparison: false,
                        loadsComparison: false,
                    });
                    setCurrOption('PDF');
                    setPrintable(false);
                    handler(false);
                }}
            />
        </>
    );
};
