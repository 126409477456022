import React, {
    lazy,
    Suspense,
    useState,
    useEffect,
    useRef /*forwardRef*/,
} from 'react';
import { smartbidServer } from 'lib/api';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useUser } from 'components/Accounts';
import { useCompanies } from 'providers/Companies';
import { R360Grid } from 'components/Revenue360/R360Grid';
import { ProtectedPage } from 'components/Page';
import { isValidAdmin } from 'helpers/user';
import styles from 'styles/Revenue360.module.css';
import { Chart } from 'chart.js';

const KPICard = lazy(() =>
    import('components/Revenue360').then((module) => ({
        default: module.KPICard,
    }))
);

const PieChartCard = lazy(() =>
    import('components/Revenue360').then((module) => ({
        default: module.PieChartCard,
    }))
);

const ScatterChartCard = lazy(() =>
    import('components/Revenue360').then((module) => ({
        default: module.ScatterChartCard,
    }))
);

const CompletedLoadsChartCard = lazy(() =>
    import('components/Revenue360').then((module) => ({
        default: module.CompletedLoadsChartCard,
    }))
);

const RatesChartCard = lazy(() =>
    import('components/Revenue360').then((module) => ({
        default: module.RatesChartCard,
    }))
);

const DropdownButton = lazy(() =>
    import('components/Revenue360').then((module) => ({
        default: module.DropdownButton,
    }))
);

const Revenue360 = () => {
    const [vanRatesData, setVanRatesData] = useState(undefined);
    const [smallStraightRatesData, setSmallStraightRatesData] =
        useState(undefined);
    const [largeStraightRatesData, setLargeStraightRatesData] =
        useState(undefined);
    const [combinedRatesData, setCombinedRatesData] = useState(undefined);
    const [kpiData, setKPIData] = useState({
        total_revenue: {},
    });
    const [pieData, setPieData] = useState(undefined);
    const [scatterData, setScatterData] = useState(undefined);
    const [completedLoadsData, setCompletedLoadsData] = useState(undefined);
    const [kpiView, setKPIView] = useState('Sprinter/Cargo Vans');
    const { user } = useUser();
    const [company, setCompany] = useState(undefined);
    const { companies } = useCompanies();
    const companyList = companies.map((obj) => obj.company);
    const [mounted, setMounted] = useState(false);
    const [chartView, setChartView] = useState('Rates Comparison');
    const [refreshInterval, setRefreshInterval] = useState(false);
    const [intervalID, setIntervalID] = useState('');

    // shared states
    const [dateSpan, setDateSpan] = useState('Last 30 Days');
    const [ratesDateSpan, setRatesDateSpan] = useState('Last 2 Weeks');
    const [ratesView, setRatesView] = useState('Sprinter/Cargo Vans');

    // react-to-print setup
    const chartsRef = useRef([null, null, null, null, null]);

    Chart.defaults.font.size = 12;

    useEffect(() => {
        setIntervalID(
            setInterval(() => {
                setRefreshInterval(true);
            }, 1200000)
        ); //every 20 minutes for production
        return () => {
            clearInterval(intervalID);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mounted && user && Object.keys(user).length !== 0) {
            if (!company) {
                setCompany(user.company);
                if (
                    user.subscription?.r360_van_rates &&
                    user.subscription?.r360_straight_rates
                ) {
                    setRatesView('Combined');
                } else if (
                    !user.subscription?.r360_van_rates &&
                    user.subscription?.r360_straight_rates
                ) {
                    setRatesView('Sm Straight Trucks');
                }
            }
        }
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [company, mounted, user]);

    useEffect(() => {
        if (mounted && user && Object.keys(user).length !== 0) {
            if (
                user?.subscription?.type === 'broker' ||
                (chartView === 'Rates Comparison' &&
                    !(
                        user.subscription?.r360_van_rates ||
                        user.subscription?.r360_straight_rates
                    ))
            ) {
                setChartView('SmartBid Usage');
            }
        }
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [company, mounted, user, chartView]);

    useEffect(() => {
        let cancel = false;
        if (mounted && company) {
            const params = new URLSearchParams([
                ['company', company],
                ['dateSpan', dateSpan],
            ]);
            smartbidServer
                .get('/api/analytics/kpi', { params })
                .then((res) => {
                    if (cancel) {
                        return;
                    }
                    const { total_revenue } = res.data;
                    setKPIData({
                        total_revenue: total_revenue,
                    });
                })
                .catch((err) => {
                    console.error(err.message);
                });
            smartbidServer
                .get('/api/analytics/pie', { params })
                .then((res) => {
                    if (cancel) {
                        return;
                    }
                    setPieData(res.data);
                })
                .catch((err) => {
                    console.error(err.message);
                });
            smartbidServer
                .get('/api/analytics/completedloads', { params })
                .then((res) => {
                    if (cancel) {
                        return;
                    }
                    setCompletedLoadsData(res.data);
                })
                .catch((err) => {
                    console.error(err.message);
                });
            smartbidServer
                .get('/api/analytics/scatter', { params })
                .then((res) => {
                    if (cancel) {
                        return;
                    }
                    setScatterData(res.data);
                })
                .catch((err) => {
                    console.error(err.message);
                });
            smartbidServer
                .get('/api/analytics/rates', { params })
                .then((res) => {
                    if (cancel) {
                        return;
                    }
                    const { van, sm_straight, lg_straight, combined } =
                        res.data;
                    setVanRatesData(van);
                    setSmallStraightRatesData(sm_straight);
                    setLargeStraightRatesData(lg_straight);
                    setCombinedRatesData(combined);
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }
        setMounted(true);
        setRefreshInterval(false);
        return () => {
            cancel = true;
            setVanRatesData(undefined);
            setSmallStraightRatesData(undefined);
            setLargeStraightRatesData(undefined);
            setCombinedRatesData(undefined);
            setKPIData({
                total_revenue: {},
            });
            setPieData(undefined);
            setScatterData(undefined);
            setCompletedLoadsData(undefined);
            setMounted(false);
        };
    }, [company, dateSpan, mounted, refreshInterval]);

    const DATE_RANGE_TOGGLE_OPTIONS = [
        'Last 30 Days',
        'Last 60 Days',
        'Last 180 Days',
    ];

    return (
        <ProtectedPage
            title='Revenue360'
            wide
            restrictions={['login-required']}
        >
            <Suspense fallback={<Skeleton height={400} borderRadius={15} />}>
                {isValidAdmin(user) && companyList && (
                    <DropdownButton
                        value={company}
                        setValue={setCompany}
                        options={companyList}
                        width='min-content'
                    />
                )}
                <DropdownButton
                    value={dateSpan}
                    setValue={setDateSpan}
                    options={DATE_RANGE_TOGGLE_OPTIONS}
                />
                <R360Grid>
                    {user?.subscription?.r360_van_kpi && (
                        <div className={styles.gridElemSmall}>
                            <KPICard
                                kpiData={kpiData}
                                kpiView={kpiView}
                                setKPIView={setKPIView}
                                loading={
                                    Object.keys(kpiData.total_revenue)
                                        .length === 0
                                }
                                dateSpan={dateSpan}
                            />
                        </div>
                    )}
                    {user?.subscription?.r360_pie && (
                        <div className={styles.gridElemSmall}>
                            <PieChartCard
                                pieData={pieData}
                                loading={!pieData}
                                dateSpan={dateSpan}
                            />
                        </div>
                    )}
                    {user?.subscription?.r360_scatter && (
                        <div className={styles.gridElemMedium}>
                            <ScatterChartCard
                                scatterData={scatterData}
                                loading={!scatterData}
                                dateSpan={dateSpan}
                            />
                        </div>
                    )}
                    {user?.subscription?.r360_completed_loads && (
                        <div className={styles.gridElemMedium}>
                            <CompletedLoadsChartCard
                                completedLoadsData={completedLoadsData}
                                loading={!completedLoadsData}
                                dateSpan={dateSpan}
                            />
                        </div>
                    )}
                </R360Grid>
                <div className={classNames(styles.content)}>
                    {chartView === 'Rates Comparison' &&
                        (user?.subscription?.r360_van_rates ||
                            user?.subscription?.r360_straight_rates) && (
                            <RatesChartCard
                                vanData={vanRatesData}
                                smallStraightData={smallStraightRatesData}
                                largeStraightData={largeStraightRatesData}
                                combinedData={combinedRatesData}
                                company={company}
                                dateSpan={ratesDateSpan}
                                setDateSpan={setRatesDateSpan}
                                ratesView={ratesView}
                                setRatesView={setRatesView}
                                loading={
                                    !(
                                        vanRatesData &&
                                        smallStraightRatesData &&
                                        largeStraightRatesData &&
                                        combinedRatesData
                                    )
                                }
                                ref={(el) => (chartsRef.current[3] = el)}
                            />
                        )}
                </div>
            </Suspense>
        </ProtectedPage>
    );
};

export default Revenue360;
