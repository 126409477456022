import { Card } from 'components/Card';
import { Pie } from 'react-chartjs-2';
import styles from 'styles/Revenue360.module.css';
import { getBackdate } from './helpers';
import Skeleton from 'react-loading-skeleton';
import { UnavailableText } from './UnavailableText';

const DateDisplay = ({ dateSpan, loading }) => {
    if (loading) {
        return null;
    }
    switch (dateSpan) {
        case 'Last 30 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(30, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        case 'Last 60 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(60, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        case 'Last 180 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(180, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        default:
            return <p>{dateSpan}</p>;
    }
};

const getVehicleRevenue = (data, dateSpan) => {
    let startDate;
    switch (dateSpan) {
        case 'Last 30 Days':
            startDate = new Date(getBackdate(31));
            break;
        case 'Last 60 Days':
            startDate = new Date(getBackdate(61));
            break;
        case 'Last 180 Days':
            startDate = new Date(getBackdate(181));
            break;
        default:
            startDate = new Date(getBackdate(0));
            break;
    }

    const filteredData = data?.filter(
        ({ date }) => new Date(date) >= startDate
    );
    const initialValue = 0;
    const vehicleRevenue = filteredData?.reduce(
        (sum, datapoint) => sum + parseFloat(datapoint.revenue),
        initialValue
    );

    return parseFloat(vehicleRevenue)?.toFixed(2);
};

export const PieChartCard = ({ pieData, loading, dateSpan }) => {
    const data = {
        labels: [
            'Sprinter/Cargo Vans',
            'Sm Straight Trucks',
            'Lg Straight Trucks',
        ],
        datasets: [
            {
                data: [
                    getVehicleRevenue(pieData?.van_revenue, dateSpan),
                    getVehicleRevenue(
                        pieData?.small_straight_revenue,
                        dateSpan
                    ),
                    getVehicleRevenue(
                        pieData?.large_straight_revenue,
                        dateSpan
                    ),
                ],
                backgroundColor: [
                    window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--graph-light-blue-transparent'),
                    window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--graph-orange-transparent'),
                    window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--graph-green-transparent'),
                ],
                borderColor: [
                    window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--graph-light-blue'),
                    window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--graph-orange'),
                    window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--graph-green'),
                ],
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'right',
            },
        },
        aspectRatio: 1.7,
    };

    return (
        <Card fullWidth fullHeight smallPadding>
            <div className={styles.header}>
                <h1 className={styles.heading}>Revenue Composition</h1>
            </div>
            <DateDisplay dateSpan={dateSpan} loading={loading} />
            {!loading ? (
                <div className={styles.cardContent}>
                    {pieData?.van_revenue.length !== 0 &&
                    pieData?.small_straight_revenue.length !== 0 &&
                    pieData?.large_straight_revenue.length !== 0 ? (
                        <Pie data={data} options={options} />
                    ) : (
                        <UnavailableText />
                    )}
                </div>
            ) : (
                <Skeleton
                    style={{ margin: '5% auto' }}
                    baseColor='var(--secondary-dark)'
                    highlightColor='var(--secondary)'
                    height={250}
                    borderRadius={5}
                />
            )}
        </Card>
    );
};
