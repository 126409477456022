import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from 'styles/Admin.module.css';

export const DashboardSkeleton = () => {
    return (
        <div className={styles.skeletons}>
            <Skeleton height={110} borderRadius={15} />
            {Array.from(Array(15).keys()).map((_, idx) => (
                <Skeleton key={idx} height={70} borderRadius={15} />
            ))}
        </div>
    );
};
