import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { FaMagnifyingGlass, FaRegSquare, FaSquareCheck } from 'react-icons/fa6';
import { getUsers } from 'components/Revenue360/helpers';
import { Button } from 'components/Button';
import styles from 'styles/UsageFilter.module.css';
import { Input } from 'components/Form';

export const FilterDropdown = ({
    data,
    visible,
    setVisible,
    openDropdown,
    setOpenDropdown,
}) => {
    const getUsersCallback = useCallback(() => {
        return getUsers(data);
    }, [data]);
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState(getUsersCallback());
    const {
        register,
        watch,
        formState: { errors },
    } = useForm();

    const toggleVisibility = (user) => {
        if (visible.includes(user)) {
            const userIdx = visible.indexOf(user);
            setVisible((prev) => [
                ...prev.slice(0, userIdx),
                ...prev.slice(userIdx + 1),
            ]);
        } else {
            setVisible((prev) => [...prev, user]);
        }
    };

    useEffect(() => {
        setUsers(getUsersCallback());
    }, [getUsersCallback]);

    return (
        <>
            <div
                className={classNames(
                    styles.dropdown,
                    openDropdown && styles.open
                )}
            >
                <div className={styles.searchInputWrapper}>
                    <Input
                        type='text'
                        label='search'
                        placeholder='Search users'
                        register={register}
                        errors={errors}
                        watch={watch}
                        onChange={(e) => setSearch(e.target.value)}
                        startIcon={<FaMagnifyingGlass />}
                        size='sm'
                    />
                    <span className={styles.subtitle}>
                        Displaying&nbsp;
                        {visible &&
                            (visible.length === users.length
                                ? 'all'
                                : visible.length)}
                        &nbsp;of {users.length} users
                    </span>
                    <div className={styles.selectButtonRow}>
                        <Button
                            uitype='cta'
                            color='primary'
                            size='sm'
                            fullwidth
                            radius='sm'
                            disabled={
                                visible && visible.length === users.length
                            }
                            onClick={() => setVisible(users)}
                        >
                            Select All
                        </Button>
                        <Button
                            uitype='cta'
                            color='primary'
                            size='sm'
                            fullwidth
                            radius='sm'
                            disabled={visible && visible.length === 0}
                            onClick={() => setVisible([])}
                        >
                            Deselect All
                        </Button>
                    </div>
                </div>
                <ul className={styles.userList}>
                    {users
                        .filter(
                            (u) =>
                                search === '' ||
                                u.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((user) => (
                            <li
                                key={user}
                                className={classNames(
                                    styles.userItem,
                                    visible &&
                                        visible.includes(user) &&
                                        styles.visible
                                )}
                                onClick={() => toggleVisibility(user)}
                            >
                                <div>
                                    {visible && visible.includes(user) ? (
                                        <FaSquareCheck />
                                    ) : (
                                        <FaRegSquare />
                                    )}
                                </div>
                                <span>{user}</span>
                            </li>
                        ))}
                </ul>
            </div>
            <div
                className={classNames(
                    styles.overlay,
                    openDropdown && styles.open
                )}
                onClick={() => setOpenDropdown(false)}
            />
        </>
    );
};
