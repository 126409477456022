import React, { lazy, Suspense } from 'react';
import { ProtectedPage } from 'components/Page';
import styles from 'styles/Login.module.css';

const ResetPasswordCard = lazy(() =>
    import('components/ResetPasswordCard').then((module) => ({
        default: module.ResetPasswordCard,
    }))
);

const ResetPassword = () => {
    return (
        <ProtectedPage
            title='Reset Password'
            restrictions={['logged-in']}
            includeBackground
            hideSideNav
        >
            <Suspense fallback={<></>}>
                <div className={styles.content}>
                    <ResetPasswordCard />
                </div>
            </Suspense>
        </ProtectedPage>
    );
};

export default ResetPassword;
