import { NavLink } from 'react-router-dom';
import styles from 'styles/UserDropdown.module.css';

export const Divider = () => {
    return <hr className={styles.divider} />;
};
export const Tab = ({ icon, label, pageLink, onClick }) => {
    if (pageLink) {
        return (
            <NavLink to={pageLink} className={styles.link}>
                <div className={styles.tab}>
                    <div className={styles.icon}>{icon}</div>
                    <span>{label}</span>
                </div>
            </NavLink>
        );
    }

    return (
        <div className={styles.tab} onClick={() => onClick()}>
            <div className={styles.icon}>{icon}</div>
            <span>{label}</span>
        </div>
    );
};
