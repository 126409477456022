import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { lazy, useEffect, useRef, useState } from 'react';
import styles from 'styles/Manage.module.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'styles/ag-theme-laneData.css';
import { DropdownButton } from 'components/Revenue360';
import Skeleton from 'react-loading-skeleton';
import { outputError } from 'helpers/error';
import { smartbidServer } from 'lib/api';

const DriverRatesCard = lazy(() =>
    import('components/Manage/').then((module) => ({
        default: module.DriverRatesCard,
    }))
);

const DriverAppManage = ({ user, companyId }) => {
    const [usersColumnDefs, setUsersColumnDefs] = useState([]);
    const [companyUsers, setCompanyUsers] = useState();
    const [selectedDriver, setSelectedDriver] = useState();
    const driverList = useRef(); //used in the opportunities list dropdown button, also used to identify which user opportunities to pull
    const oppData = useRef(); //object which houses all opportunities data to be referenced for opportunities list when dropdown is selected
    const [oppsColumnDefs, setOppsColumnDefs] = useState([]);
    const [opportunitiesList, setOpportunitiesList] = useState();
    const [isLoading, setLoading] = useState(true);
    const [dateSpan, setDateSpan] = useState('Last 7 Days'); //In case we wish to allow changing the date at some point

    useEffect(() => {
        if (user && companyId && !companyUsers) {
            const params = new URLSearchParams([
                ['companyId', companyId],
                ['timeSpan', dateSpan],
            ]);
            smartbidServer
                .get('/api/users/manage/drivers', { params })
                .then((res) => {
                    const compile_user_data = {};
                    const compile_opp_data = {};
                    const reorganized_opp_data = {};
                    //iterates over driver Users to create organized data objects to easily assign data aggregations using constant lookup speed
                    if (Array.isArray(res.data?.drivers)) {
                        for (const driver of res.data.drivers) {
                            //sets the compile_user_data as a referencing object back to res[0].value but with easy caching keys
                            compile_user_data[driver.user_id] = driver;

                            //sets reorganized_opp_data as a referencing object with different keys for easier object reference
                            reorganized_opp_data[
                                `${driver.name} - ${driver.vehicle_id}`
                            ] = //enumerable reference for driver select dropdown for O(1) time complexity
                                reorganized_opp_data[driver.vehicle_id] = //non-enumerable reference to add seen count and to add to oppList from large data pull for O(n) time complexity
                                compile_opp_data[driver.vehicle_id] =
                                    {
                                        name: driver.name,
                                        oppList: [],
                                        vehicle_id: driver.vehicle_id,
                                        seen_count: 0,
                                    };
                            Object.defineProperty(
                                reorganized_opp_data,
                                driver.vehicle_id,
                                { enumerable: false }
                            );
                        }
                        setCompanyUsers(res.data?.drivers);
                    }
                    //create total bids and total rejected count numbers
                    if (Array.isArray(res.data?.events)) {
                        for (const event of res.data.events) {
                            const { event_scope, user_id } = event;
                            switch (event_scope) {
                                default: {
                                    break;
                                }
                                case 'request_bid': {
                                    if (user_id in compile_user_data) {
                                        compile_user_data[user_id].total_bids =
                                            (compile_user_data[user_id]
                                                .total_bids ?? 0) + 1;
                                    } else {
                                        console.log(
                                            `[DEBUG::client:pages:manage:assigningDriverInfo] user_id: ${user_id} not found in compile_user_data`
                                        );
                                    }
                                    break;
                                }
                                case 'reject_bid': {
                                    compile_user_data[user_id].total_rejected =
                                        (compile_user_data[user_id]
                                            .total_rejected ?? 0) + 1;

                                    break;
                                }
                            }
                        }
                    }
                    //assign column definitions for Driver User table
                    setUsersColumnDefs([
                        {
                            headerName: `${
                                user?.short_name ?? 'Company'
                            } DriverApp Users`,
                            children: [
                                {
                                    headerName: 'Name',
                                    field: 'name',
                                    width: 180,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'Email',
                                    field: 'email',
                                    width: 200,
                                    suppressMovable: true,
                                },
                                {
                                    headerName: 'Vehicle ID',
                                    field: 'vehicle_id',
                                    width: 100,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'Notifications',
                                    field: 'push_notifications_enabled',
                                    width: 80,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'App Login Status',
                                    field: 'driver_uid',
                                    width: 130,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'Driver Location', //this will just be a concat of city and state
                                    field: 'location',
                                    width: 170,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'Driver Status',
                                    field: 'status_description',
                                    width: 90,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                            ],
                        },
                        {
                            headerName: 'Bids Last 7 Days',
                            children: [
                                {
                                    headerName: 'Requested',
                                    field: 'total_bids',
                                    width: 100,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                                {
                                    headerName: 'Rejected',
                                    field: 'total_rejected',
                                    width: 100,
                                    suppressMovable: true,
                                    sortable: true,
                                },
                            ],
                        },
                    ]);
                    driverList.current = Object.keys(reorganized_opp_data);
                    oppData.current = reorganized_opp_data; //oppData is used to populate the
                })
                .catch((err) => {
                    outputError({
                        err,
                        source: 'client:pages:manage:assigningDriverInfo',
                    });
                });
        }
    }, [user, dateSpan, companyId, companyUsers]);

    useEffect(() => {
        if (oppData.current && !opportunitiesList && companyId) {
            const config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: user.google_api_key,
                },
            };
            const oppListCalls = companyUsers.map((driver) =>
                smartbidServer
                    .get(
                        `/api/automail/opportunities/${companyId},${driver.vehicle_id}`,
                        config
                    )
                    .catch((err) =>
                        outputError({
                            err,
                            source: 'client:pages:manage:oppListCalls',
                        })
                    )
            );
            (async function () {
                if (
                    oppListCalls !== null &&
                    typeof oppListCalls !== 'undefined'
                ) {
                    for await (const list of oppListCalls) {
                        if (
                            typeof list === 'undefined' ||
                            list === null ||
                            typeof list.data._results[0]?.vehicle_id ===
                                'undefined'
                        )
                            continue;
                        oppData.current[
                            list.data._results[0].vehicle_id
                        ].oppList = list.data._results;
                        if (
                            typeof selectedDriver === 'undefined' ||
                            selectedDriver === null
                        )
                            setSelectedDriver(driverList.current[0]);
                    }
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyUsers, companyId]);

    useEffect(() => {
        if (selectedDriver && oppData.current) {
            const timeFormat = new Intl.DateTimeFormat('en-US', {
                dateStyle: 'short',
                timeStyle: 'short',
                hourCycle: 'h24',
            });
            for (const opp of oppData.current[selectedDriver].oppList) {
                if (opp.origin) continue;
                if (opp.seen_status)
                    oppData.current[selectedDriver].seen_count += 1;
                const { origin, destination, miles, pickup_datetime } =
                    opp.email_details;
                opp.origin = origin;
                opp.destination = destination;
                opp.pickup_datetime = pickup_datetime;
                opp.miles = miles;
                opp.driver_bid = opp.driver_bid ? `$${opp.driver_bid}` : '-';
                opp.email_received_timestamp = timeFormat.format(
                    opp.email_received_timestamp * 1000
                );
            }
            //[re]setting column definitions to update selected driver's name in header
            setOppsColumnDefs([
                {
                    headerName: `Last 7 Days DriverApp Opportunities - ${oppData.current[selectedDriver].name}`,
                    children: [
                        {
                            headerName: 'Load Identified',
                            field: 'email_received_timestamp',
                            width: 130,
                            suppressMovable: true,
                            sortable: true,
                            sort: 'desc',
                        },
                        {
                            headerName: 'Pickup Date',
                            field: 'pickup_datetime',
                            width: 130,
                            suppressMovable: true,
                            sortable: true,
                        },
                        {
                            headerName: 'Pickup',
                            field: 'origin',
                            width: 180,
                            suppressMovable: true,
                            sortable: true,
                        },
                        {
                            headerName: 'Dropoff',
                            field: 'destination',
                            width: 180,
                            suppressMovable: true,
                        },
                    ],
                },
                {
                    headerName: `Opportunities Seen: ${oppData.current[selectedDriver].seen_count}`,
                    children: [
                        {
                            headerName: 'Driver Bid',
                            field: 'driver_bid',
                            width: 100,
                            suppressMovable: true,
                            sortable: true,
                        },
                        {
                            headerName: 'Miles',
                            field: 'miles',
                            width: 80,
                            suppressMovable: true,
                            sortable: true,
                        },
                        {
                            headerName: 'Deadhead Miles',
                            field: 'last_distance_from_pickup',
                            width: 140,
                            suppressMovable: true,
                            sortable: true,
                        },
                    ],
                },
            ]);
            setOpportunitiesList(oppData.current[selectedDriver].oppList);
        }
        setLoading(false);
    }, [selectedDriver]);

    useEffect(() => {
        if (driverList?.current?.length === 1) {
            setSelectedDriver(driverList?.current?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driverList?.current]);

    return (
        <div className={styles.manageDriverAppWrapper}>
            <div
                className={classNames(
                    'ag-theme-laneData',
                    styles.driverDetailTable
                )}
            >
                <AgGridReact
                    columnDefs={usersColumnDefs}
                    rowData={companyUsers}
                    alwaysShowVerticalScroll
                    alwaysShowHorizontalScroll
                />
            </div>
            {Array.isArray(driverList.current) && selectedDriver && (
                <div className={styles.driverSelector}>
                    {driverList?.current?.length > 1 && (
                        <DropdownButton
                            width='100%'
                            value={selectedDriver}
                            setValue={setSelectedDriver}
                            options={driverList.current}
                            tight
                        />
                    )}
                </div>
            )}
            <div className={styles.rateSelector}>
                <DriverRatesCard company_id={companyId} />
            </div>
            <div
                className={classNames(
                    'ag-theme-laneData',
                    styles.opportunityListTable
                )}
            >
                {isLoading ? (
                    <Skeleton
                        containerClassName={styles.loading}
                        baseColor='var(--secondary-dark)'
                        highlightColor='var(--secondary)'
                        height={'100%'}
                        borderRadius={5}
                    />
                ) : (
                    <AgGridReact
                        columnDefs={oppsColumnDefs}
                        rowData={opportunitiesList}
                        alwaysShowVerticalScroll
                        alwaysShowHorizontalScroll
                    />
                )}
            </div>
        </div>
    );
};

export default DriverAppManage;
