import React, { useState } from 'react';
import classNames from 'classnames';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { HiX, HiOutlineMenu } from 'react-icons/hi';
import { useUser } from 'components/Accounts';
import logo from 'assets/images/Everest_Logo.svg';
import { Avatar } from 'components/Avatar';
import { UserDropdown } from 'components/UserDropdown';
import { PreviewBar } from '../Preview/PreviewBar';
import styles from 'styles/NavBar.module.css';
import { NavTabs } from './NavTabs';

export const NavBar = ({ wide, openSideNav, setOpenSideNav }) => {
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const { user, status } = useUser();
    const defaultPathname = status === 'authenticated' ? '/smartbid' : '/';
    // const pagesHideSideNav = ['/', '/login', '/reset-password'];

    return (
        <div className={styles.background}>
            {user?.preview && <PreviewBar wide={wide} />}
            <div className={styles.navbarWrapper}>
                <nav
                    className={classNames(
                        styles.navbar,
                        // !pagesHideSideNav.includes(location.pathname) && styles.fullPage,
                        wide && styles.wide
                    )}
                >
                    <span className={styles.homeMenuSpan}>
                        <button
                            onClick={() => setOpenSideNav(!openSideNav)}
                            className={styles.menuButton}
                        >
                            {openSideNav ? (
                                <div>
                                    <HiX />
                                </div>
                            ) : (
                                <div>
                                    <HiOutlineMenu />
                                </div>
                            )}
                        </button>
                        <Link to={defaultPathname}>
                            <img
                                src={logo}
                                alt='Everst Logo'
                                width={125}
                                height={33}
                            />
                        </Link>
                    </span>
                    {status !== 'loading' && (
                        <span className={styles.navWrapper}>
                            <NavTabs className={styles.navTabs} />
                            {(user || status === 'authenticating') &&
                                !['/', '/login'].includes(
                                    location.pathname
                                ) && (
                                    <div className={styles.profileWrapper}>
                                        <Avatar
                                            onClick={() =>
                                                setShowDropdown(!showDropdown)
                                            }
                                        />
                                        <UserDropdown
                                            show={showDropdown}
                                            handler={setShowDropdown}
                                        />
                                    </div>
                                )}
                        </span>
                    )}
                </nav>
            </div>
        </div>
    );
};
