import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import {
    FaArrowRotateLeft,
    FaCheck,
    FaRegSquare,
    FaSquareCheck,
} from 'react-icons/fa6';
import { Button } from 'components/Button';
import {
    CO2EmissionCopy,
    FormattingCopy,
    VehicleClassDropdown,
} from '../components';
import { DataRow, hasError } from './DataRow';
import styles from 'styles/OptiRates.module.css';
import { useForm } from 'react-hook-form';

const TableActions = ({
    parsedData,
    setParsedData,
    selectedRows,
    setSelectedRows,
    errCount,
}) => {
    const [changingVehicleClass, setChangingVehicleClass] = useState(false);
    const [confirmingDelete, setConfirmingDelete] = useState(false);
    const [confirmingIgnore, setConfirmingIgnore] = useState(false);
    const [confirmingUndoIgnore, setConfirmingUndoIgnore] = useState(false);
    const {
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            'bulk-vehicle-class': 'Sprinter/Cargo Van',
        },
    });

    // reset state when all rows have been deselected
    useEffect(() => {
        if (selectedRows.length === 0) {
            setChangingVehicleClass(false);
            setConfirmingDelete(false);
            setConfirmingIgnore(false);
            setConfirmingUndoIgnore(false);
            reset();
        }
    }, [selectedRows, reset]);

    return (
        <div className={styles.tableActions}>
            {selectedRows.length > 0 ? (
                <>
                    <div className={styles.selectCount}>
                        {selectedRows.length} Selected
                    </div>
                    <div className={styles.bulkActionButtons}>
                        {!confirmingDelete &&
                            !(confirmingIgnore || confirmingUndoIgnore) && (
                                <>
                                    {changingVehicleClass && (
                                        <VehicleClassDropdown
                                            id='bulk-vehicle-class'
                                            control={control}
                                            errors={errors}
                                            className={styles.bulkEditInput}
                                        />
                                    )}
                                    <Button
                                        uitype='cta'
                                        size='sm'
                                        color='primary'
                                        onClick={() => {
                                            if (changingVehicleClass) {
                                                setChangingVehicleClass(false);
                                                setSelectedRows([]);
                                                setParsedData((prev) =>
                                                    prev.map((row) => {
                                                        if (
                                                            selectedRows.includes(
                                                                row.id
                                                            )
                                                        ) {
                                                            return {
                                                                ...row,
                                                                'Vehicle Class':
                                                                    watch(
                                                                        'bulk-vehicle-class'
                                                                    ),
                                                            };
                                                        }
                                                        return row;
                                                    })
                                                );
                                            } else {
                                                setChangingVehicleClass(true);
                                            }
                                        }}
                                    >
                                        {changingVehicleClass
                                            ? 'Apply Vehicle Class'
                                            : 'Change Vehicle Class'}
                                    </Button>
                                    {changingVehicleClass && (
                                        <Button
                                            uitype='ghost'
                                            size='sm'
                                            onClick={() =>
                                                setChangingVehicleClass(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                </>
                            )}

                        {!changingVehicleClass && !confirmingDelete && (
                            <>
                                {selectedRows.every(
                                    (id) =>
                                        !parsedData.find((row) => row.id === id)
                                            ?.ignored
                                ) ? (
                                    <>
                                        {/* prevent from ignoring all rows */}
                                        {selectedRows.length !==
                                            parsedData.length && (
                                            <>
                                                {confirmingIgnore ? (
                                                    <>
                                                        <Button
                                                            uitype='cta'
                                                            size='sm'
                                                            color='secondary-dark'
                                                            onClick={() => {
                                                                setSelectedRows(
                                                                    []
                                                                );
                                                                setParsedData(
                                                                    (prev) =>
                                                                        prev.map(
                                                                            (
                                                                                row
                                                                            ) => {
                                                                                if (
                                                                                    selectedRows.includes(
                                                                                        row.id
                                                                                    )
                                                                                ) {
                                                                                    return {
                                                                                        ...row,
                                                                                        ignored: true,
                                                                                    };
                                                                                }
                                                                                return row;
                                                                            }
                                                                        )
                                                                );
                                                            }}
                                                        >
                                                            Confirm Ignore
                                                        </Button>
                                                        <Button
                                                            uitype='ghost'
                                                            size='sm'
                                                            onClick={() =>
                                                                setConfirmingIgnore(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        uitype='ghost'
                                                        size='sm'
                                                        onClick={() =>
                                                            setConfirmingIgnore(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Ignore Row
                                                        {selectedRows.length ===
                                                        1
                                                            ? ''
                                                            : 's'}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {confirmingUndoIgnore ? (
                                            <>
                                                <Button
                                                    uitype='cta'
                                                    size='sm'
                                                    color='secondary-dark'
                                                    onClick={() => {
                                                        setSelectedRows([]);
                                                        setParsedData((prev) =>
                                                            prev.map((row) => {
                                                                if (
                                                                    selectedRows.includes(
                                                                        row.id
                                                                    )
                                                                ) {
                                                                    return {
                                                                        ...row,
                                                                        ignored: false,
                                                                    };
                                                                }
                                                                return row;
                                                            })
                                                        );
                                                    }}
                                                >
                                                    Confirm Unignore
                                                </Button>
                                                <Button
                                                    uitype='ghost'
                                                    size='sm'
                                                    onClick={() =>
                                                        setConfirmingUndoIgnore(
                                                            false
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                uitype='ghost'
                                                size='sm'
                                                onClick={() =>
                                                    setConfirmingUndoIgnore(
                                                        true
                                                    )
                                                }
                                            >
                                                Unignore Row
                                                {selectedRows.length === 1
                                                    ? ''
                                                    : 's'}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {/* prevent from deleting all rows */}
                        {!changingVehicleClass &&
                            !(confirmingIgnore || confirmingUndoIgnore) &&
                            selectedRows.length !== parsedData.length && (
                                <>
                                    {confirmingDelete ? (
                                        <>
                                            <Button
                                                uitype='cta'
                                                size='sm'
                                                color='red'
                                                onClick={() => {
                                                    setSelectedRows([]);
                                                    setParsedData((prev) =>
                                                        prev.filter(
                                                            (row) =>
                                                                !selectedRows.includes(
                                                                    row.id
                                                                )
                                                        )
                                                    );
                                                }}
                                            >
                                                Confirm Delete
                                            </Button>
                                            <Button
                                                uitype='ghost'
                                                size='sm'
                                                onClick={() =>
                                                    setConfirmingDelete(false)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            uitype='ghost'
                                            size='sm'
                                            color='red'
                                            onClick={() =>
                                                setConfirmingDelete(true)
                                            }
                                        >
                                            Delete Row
                                            {selectedRows.length === 1
                                                ? ''
                                                : 's'}
                                        </Button>
                                    )}
                                </>
                            )}
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.selectCount}>
                        Select some rows to bulk edit or delete.
                    </div>
                    {errCount > 0 && (
                        <Button
                            uitype='ghost'
                            size='md'
                            color='red'
                            onClick={() =>
                                setParsedData(
                                    parsedData.map((row) => {
                                        if (hasError(row)) {
                                            return {
                                                ...row,
                                                ignored: true,
                                            };
                                        }
                                        return row;
                                    })
                                )
                            }
                        >
                            Ignore All Errors
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

const ReviewTable = ({
    parsedData,
    setParsedData,
    selectedRows,
    setSelectedRows,
}) => {
    return (
        <ScrollSync>
            <ScrollSyncNode scroll='syncer-only'>
                <div className={styles.reviewTable}>
                    <div className={styles.reviewTableHeader}>
                        <span
                            className={classNames(
                                styles.checkbox,
                                selectedRows.length >= parsedData.length &&
                                    styles.selected
                            )}
                        >
                            {selectedRows.length < parsedData.length ? (
                                <FaRegSquare
                                    onClick={() =>
                                        setSelectedRows(
                                            parsedData.reduce((acc, curr) => {
                                                acc.push(curr.id);
                                                return acc;
                                            }, [])
                                        )
                                    }
                                />
                            ) : (
                                <FaSquareCheck
                                    onClick={() => setSelectedRows([])}
                                />
                            )}
                        </span>
                        <span>Origin</span>
                        <span>Destination</span>
                        <span>Vehicle Class</span>
                        <span>Weight (lb)</span>
                    </div>
                    {parsedData
                        .sort((a, b) => {
                            return (
                                Number(b.id.slice(0, 6)) -
                                Number(a.id.slice(0, 6))
                            );
                        })
                        .sort((a, b) => {
                            if (hasError(a) && hasError(b)) {
                                return 0;
                            } else if (hasError(a) && !hasError(b)) {
                                return -1;
                            } else if (hasError(b) && !hasError(a)) {
                                return 1;
                            }
                            return -2;
                        })
                        .map((row) => (
                            <DataRow
                                key={row.id}
                                id={row.id}
                                row={row}
                                setParsedData={setParsedData}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        ))}
                </div>
            </ScrollSyncNode>
        </ScrollSync>
    );
};

export const ReviewModule = ({
    processed,
    parsedData,
    setParsedData,
    setReviewed,
    reset,
}) => {
    // list of IDs of rows that have been selected
    const [selectedRows, setSelectedRows] = useState([]);
    const [err, setErr] = useState(false);

    const errCount = useMemo(
        () =>
            parsedData.reduce((acc, curr) => {
                if (hasError(curr)) {
                    acc++;
                }
                return acc;
            }, 0),
        [parsedData]
    );

    const ignoreCount = useMemo(
        () =>
            parsedData.reduce((acc, curr) => {
                if (curr.ignored) {
                    acc++;
                }
                return acc;
            }, 0),
        [parsedData]
    );

    useEffect(() => {
        setErr(false);
        for (let row of parsedData) {
            if (hasError(row)) setErr(true);
        }
    }, [parsedData]);

    return (
        <div
            className={classNames(styles.module, processed && styles.processed)}
        >
            <div className={styles.moduleStep}>1</div>
            <div className={styles.moduleHeader}>
                <h2 className={styles.moduleHeading}>Review Input</h2>
                <p className={styles.moduleCopy}>
                    Double check to see if your spreadsheet was read correctly.{' '}
                    <b className={styles.semibold}>
                        You may make changes by clicking the respective text in
                        the row you want to edit.
                    </b>
                </p>
                <p className={styles.moduleCopy}>
                    Columns that won&apos;t be used to get bulk rates with have
                    been hidden, but will be included in the final export.
                </p>
                <FormattingCopy />
                <CO2EmissionCopy />
                {err && (
                    <p className={classNames('err', styles.moduleCopy)}>
                        <b className={styles.semibold}>
                            {errCount} error{errCount > 1 ? 's' : ''}{' '}
                            {errCount > 1 ? 'were' : 'was'} detected in your
                            upload.
                        </b>{' '}
                        Rows with an error are outlined in red and sorted to the
                        top. Please update the text to fix the error.
                    </p>
                )}
            </div>
            <div className={styles.moduleFooter}>
                <Button uitype='ghost' size='md' onClick={() => reset()}>
                    <FaArrowRotateLeft />
                    Go Back to Import
                </Button>
                <Button
                    uitype='cta'
                    size='md'
                    color='primary-dark'
                    disabled={err}
                    onClick={() => setReviewed(true)}
                >
                    <FaCheck />
                    Confirm Input
                </Button>
            </div>
            <div className={styles.moduleContent}>
                <TableActions
                    parsedData={parsedData}
                    setParsedData={setParsedData}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    errCount={errCount}
                />
                {ignoreCount > 0 && (
                    <p className={classNames(styles.info, styles.moduleCopy)}>
                        <b className={styles.semibold}>
                            *{ignoreCount} row{ignoreCount > 1 ? 's' : ''}{' '}
                            {ignoreCount > 1 ? 'are' : 'is'} currently ignored.
                        </b>{' '}
                        Rows that are ignored will be skipped when generating
                        rates, but will still be included in the final output.
                    </p>
                )}
                <ReviewTable
                    parsedData={parsedData}
                    setParsedData={setParsedData}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
            </div>
        </div>
    );
};
