import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import classNames from 'classnames';

import { Button } from 'components/Button';
import { Input } from 'components/Form';
import { CheckboxInput } from 'components/Form/Input/CheckboxInput';

import styles from 'styles/VehicleManager.module.css';

export const AssignDropdown = ({ users, assigned, onChange }) => {
    const [newAssigned, setNewAssigned] = useState(assigned ?? []);

    const isMountingRef = useRef(false);

    const {
        register,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            user_search: '',
        },
    });

    const search = watch('user_search');

    const userList = useMemo(
        () =>
            users.filter((user) => {
                if (!search) return true;

                return user.name
                    .toUpperCase()
                    .includes(search.toUpperCase().trim());
            }),
        [search, users]
    );

    const handleSelectAllClick = useCallback(() => {
        const allUsers = users?.map(({ user_id }) => user_id) ?? [];
        setNewAssigned(allUsers);
    }, [users, setNewAssigned]);

    const handleDeselectAllClick = useCallback(
        () => setNewAssigned([]),
        [setNewAssigned]
    );

    const handleChange = useCallback(
        (event) => {
            const value = Number(event.target.value);
            setNewAssigned((prev) => {
                if (prev?.includes(value)) {
                    return prev.filter((v) => v !== value);
                }
                return [...(prev ?? []), value];
            });
        },
        [setNewAssigned]
    );

    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!isMountingRef.current) {
            return onChange(newAssigned);
        }
        isMountingRef.current = false;
    }, [newAssigned, onChange]);

    return (
        <div className={classNames(styles.assignDropdown)}>
            <div className={styles.dropdownHeader}>
                <Input
                    type='text'
                    id='user_search'
                    label='user_search'
                    size='sm'
                    placeholder='Search Users'
                    register={register}
                    watch={watch}
                    errors={errors}
                    hideErrorMessage
                />
                <div className={styles.assignButtons}>
                    <Button
                        uitype='cta'
                        radius='sm'
                        color='secondary-dark'
                        size='xs'
                        fullwidth
                        disabled={newAssigned.length === users?.length}
                        onClick={handleSelectAllClick}
                    >
                        Select all
                    </Button>
                    <Button
                        uitype='cta'
                        radius='sm'
                        color='secondary-dark'
                        size='xs'
                        fullwidth
                        disabled={newAssigned.length === 0}
                        onClick={handleDeselectAllClick}
                    >
                        Deselect All
                    </Button>
                </div>
            </div>

            <div className={styles.options}>
                {userList.length > 0 ? (
                    userList.map(({ user_id, name }) => (
                        <CheckboxInput
                            key={user_id}
                            id={`checkbox-${user_id}`}
                            name='new_assigned'
                            value={user_id}
                            label={name}
                            checked={newAssigned?.includes(user_id)}
                            onChange={handleChange}
                        />
                    ))
                ) : (
                    <p className={styles.emptySearch}>No Results</p>
                )}
            </div>
        </div>
    );
};
