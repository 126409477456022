import { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'components/Button';
import 'styles/datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';
import classNames from 'classnames';
import { HiCalendar } from 'react-icons/hi';
import styles from 'styles/CustomDateWindow.module.css';

export const CustomDateWindow = ({
    data,
    dateSpan,
    setDateSpan,
    openCustom,
    setOpenCustom,
    error,
    setError,
    closeAll,
}) => {
    const [start, setStart] = useState(new Date(data[0].labels[0]));
    const [end, setEnd] = useState(new Date());
    const { control, handleSubmit } = useForm();

    const StartInput = forwardRef(({ value, onChange, onClick }, ref) => (
        <div className={styles.wrapper}>
            <div className={classNames(styles.input, styles.hasIcons)}>
                <div className={styles.icon}>
                    <HiCalendar />
                </div>
                <label
                    htmlFor='start_date'
                    className={classNames(value && styles.active)}
                >
                    Start Date
                </label>
                <input
                    type='text'
                    name='start_date'
                    id='start_date'
                    value={value}
                    ref={ref}
                    autoComplete='off'
                    onClick={onClick}
                    onChange={onChange}
                    onBlur={(e) => {
                        e.target.value = format(start, 'MM/dd/yyyy');
                    }}
                />
            </div>
        </div>
    ));

    const EndInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <div className={styles.wrapper}>
            <div className={classNames(styles.input, styles.hasIcons)}>
                <div className={styles.icon}>
                    <HiCalendar />
                </div>
                <label
                    htmlFor='start_date'
                    className={classNames(value && styles.active)}
                >
                    End Date
                </label>
                <input
                    type='text'
                    name='end_date'
                    id='end_date'
                    value={value}
                    ref={ref}
                    autoComplete='off'
                    onClick={onClick}
                    onChange={(e) => onChange(e)}
                    onBlur={(e) => {
                        e.target.value = format(end, 'MM/dd/yyyy');
                    }}
                />
            </div>
        </div>
    ));

    const onSubmit = ({ start_date, end_date }) => {
        if (end_date < start_date) {
            setError('⚠ End date cannot be before start date');
            return;
        }

        const formattedStart = format(start_date, 'MM/dd/yyyy');
        const formattedEnd = format(end_date, 'MM/dd/yyyy');

        let customLabel = '';
        if (start_date === end_date) {
            customLabel = formattedStart;
        } else if (formattedEnd === format(new Date(), 'MM/dd/yyyy')) {
            customLabel = formattedStart + ' - Today';
        } else if (start_date.getFullYear() === end_date.getFullYear()) {
            customLabel = formattedStart.slice(0, 5) + ' - ' + formattedEnd;
        }

        setError(undefined);
        setDateSpan(`Custom: ${customLabel}`);
        closeAll();
    };

    return (
        <div
            className={classNames(
                styles.customDateWindow,
                dateSpan.includes('Custom') && styles.customSelected,
                openCustom && styles.open
            )}
        >
            <form id='custom-date-form' onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name='start_date'
                    defaultValue={start}
                    render={({ field }, ref) => (
                        <DatePicker
                            selected={start}
                            onChange={(date) => {
                                field.onChange(date);
                                setStart(date);
                            }}
                            minDate={new Date(data[0].labels[0])}
                            maxDate={new Date()}
                            customInput={<StartInput ref={ref} />}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name='end_date'
                    defaultValue={end}
                    render={({ field }, ref) => (
                        <DatePicker
                            selected={end}
                            onChange={(date) => {
                                field.onChange(date);
                                setEnd(date);
                            }}
                            minDate={start}
                            maxDate={new Date()}
                            customInput={<EndInput ref={ref} />}
                        />
                    )}
                />
            </form>
            <div className={styles.customDateActions}>
                <Button
                    uitype='ghost'
                    size='md'
                    onClick={(e) => {
                        e.preventDefault();
                        setOpenCustom(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    uitype='cta'
                    size='md'
                    color='primary-dark'
                    form='custom-date-form'
                >
                    Confirm
                </Button>
            </div>
            {error && (
                <p className={classNames('err', styles.errMessage)}>{error}</p>
            )}
        </div>
    );
};
