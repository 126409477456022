/**
 * @function fetcher
 * @description A wrapper function for the fetch API
 * to be used with SWR.
 * @param  {...any} args 
 */
const fetcher = async (...args) => {
    const res = await fetch(...args);
    return res.json();
}

export default fetcher;