import {
    HorizontalInput,
    HorizontalSelect,
    DateTimePickerInput,
    HorizontalAutocompleteInput,
    HorizontalGroupedDropdownInput,
    Input,
} from 'components/Form';
import styles from 'styles/AdminModal.module.css';
import { vehicle_classes, vehicle_statuses } from 'data';
import { CAN_PROVINCES, USA_STATES } from 'helpers/location';
import { useEffect, useState } from 'react';

export const AddVehicleForm = ({
    handleSubmit,
    onSubmit,
    register,
    errors,
    validate,
    control,
    setValue,
    resetOnChange,
    watch,
}) => {
    const [mounted, setMounted] = useState(false);

    const status_descriptions = vehicle_statuses.map((veh_status) => {
        return { value: veh_status, label: veh_status };
    });

    const vehicle_class_options = vehicle_classes.map((veh_class) => {
        return { value: veh_class, label: veh_class };
    });

    useEffect(() => {
        if (!mounted) {
            setValue('next_delivery_mode', 'location');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted]);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <form
            id='vehicle-create-form'
            onSubmit={handleSubmit(onSubmit)}
            className={styles.form}
        >
            <HorizontalInput
                type='text'
                id='vehicle_id'
                label='Vehicle ID'
                register={register}
                required
                errors={errors}
                autoComplete='vehicle_id'
                resetOnChange={resetOnChange}
            />
            <HorizontalInput
                type='text'
                id='driver_id'
                label='Driver Name'
                register={register}
                errors={errors}
                autoComplete='driver_name'
                resetOnChange={resetOnChange}
            />
            <HorizontalSelect
                id='vehicle_class'
                label='Vehicle Class'
                control={control}
                required
                errors={errors}
                defaultValue={vehicle_class_options[0].value}
                options={vehicle_class_options}
                resetOnChange={resetOnChange}
            />
            <HorizontalSelect
                id='status_description'
                label='Vehicle Status'
                control={control}
                required
                errors={errors}
                defaultValue={status_descriptions[0].value}
                options={status_descriptions}
                resetOnChange={resetOnChange}
            />
            <HorizontalAutocompleteInput
                id='available_location'
                label='Pickup Location'
                register={register}
                errors={errors}
                control={control}
                watch={watch}
                validate={validate}
                setValue={setValue}
                required
            />
            <DateTimePickerInput
                id='available_datetime'
                label='Pickup Date'
                register={register}
                control={control}
                errors={errors}
            />
            <div className={styles.radioInputGroup}>
                <span className={styles.radioInputGroupLabel}>
                    Delivery Mode
                </span>
                <div className={styles.radioInputs}>
                    <div
                        className={styles.radioInput}
                        onClick={() =>
                            setValue('next_delivery_mode', 'location')
                        }
                    >
                        <Input
                            type='radio'
                            label='next_delivery_mode_1'
                            register={register}
                            errors={errors}
                            watch={watch}
                            narrow
                            onChange={() => {
                                setValue('next_delivery_mode', 'location');
                            }}
                            additionalProps={{
                                checked:
                                    watch('next_delivery_mode') === 'location',
                            }}
                        />
                        <span className={styles.radioInputLabel}>
                            Single Location
                        </span>
                    </div>
                    <div
                        className={styles.radioInput}
                        onClick={() =>
                            setValue('next_delivery_mode', 'multistate')
                        }
                    >
                        <Input
                            type='radio'
                            label='next_delivery_mode_2'
                            register={register}
                            errors={errors}
                            watch={watch}
                            narrow
                            onChange={() => {
                                setValue('next_delivery_mode', 'multistate');
                            }}
                            additionalProps={{
                                checked:
                                    watch('next_delivery_mode') ===
                                    'multistate',
                            }}
                        />
                        <span className={styles.radioInputLabel}>
                            Multiple States
                        </span>
                    </div>
                </div>
            </div>
            {watch('next_delivery_mode') === 'location' && (
                <HorizontalAutocompleteInput
                    id='next_pickup_location'
                    label='Delivery Location'
                    register={register}
                    errors={errors}
                    control={control}
                    watch={watch}
                    validate={validate}
                    setValue={setValue}
                />
            )}
            {watch('next_delivery_mode') === 'multistate' && (
                <HorizontalGroupedDropdownInput
                    id='multistate_next_delivery'
                    name='multistate_next_delivery'
                    label='Delivery States'
                    control={control}
                    errors={errors}
                    multiselect
                    groupedOptions={[
                        {
                            label: 'USA States',
                            options: USA_STATES.map((state) => {
                                return { value: state, label: state };
                            }),
                        },
                        {
                            label: 'CAN Provinces',
                            options: CAN_PROVINCES.map((province) => {
                                return {
                                    value: province,
                                    label: province,
                                };
                            }),
                        },
                    ]}
                    resetOnChange={resetOnChange}
                />
            )}
            <DateTimePickerInput
                id='next_pickup_datetime'
                label='Delivery Date'
                register={register}
                control={control}
                errors={errors}
            />
            <HorizontalInput
                type='text'
                id='notes'
                label='Notes'
                register={register}
                errors={errors}
                resetOnChange={resetOnChange}
            />
        </form>
    );
};
