import classNames from 'classnames';
import { FaRegSquare, FaSquareCheck } from 'react-icons/fa6';

import styles from 'styles/Input.module.css';

const CheckboxInput = ({
    id,
    name,
    value,
    label,
    checked,
    onChange = () => {},
}) => {
    return (
        <div
            className={classNames(
                styles.checkboxInput,
                checked && styles.checked
            )}
        >
            <label htmlFor={id}>
                <input
                    type='checkbox'
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={styles.checkbox}
                />
                <div className={styles.checkboxIcon}>
                    {checked ? <FaSquareCheck /> : <FaRegSquare />}
                </div>
                <span>{label}</span>
            </label>
        </div>
    );
};
export { CheckboxInput };
