import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import classNames from 'classnames';
import styles from 'styles/Button.module.css';

export const Button = ({
    form,
    uitype,
    radius,
    color,
    shadow,
    size,
    justifyContent,
    disabled,
    onClick,
    tabIndex,
    fullwidth,
    isLoading,
    tooltip,
    children,
}) => {
    return (
        <span className={classNames(fullwidth && styles.fullwidth)}>
            <button
                form={form}
                tabIndex={tabIndex ? tabIndex : '0'}
                className={classNames(
                    uitype === 'cta' && styles.cta,
                    uitype === 'ghost' && styles.ghost,
                    uitype === 'text' && styles.text,
                    uitype === 'fab' && styles.fab,
                    uitype === 'icon' && styles.icon,
                    !radius && styles.radius,
                    radius === 'round' && styles.radiusRound,
                    radius === 'sm' && styles.radiusSm,
                    color === 'primary' && styles.primary,
                    color === 'primary-dark' && styles.primaryDark,
                    color === 'secondary-dark' && styles.secondaryDark,
                    color === 'secondary-darker' && styles.secondaryDarker,
                    color === 'blue' && styles.blue,
                    color === 'red' && styles.red,
                    shadow && styles.shadow,
                    shadow === 'sm' && styles.shadowSm,
                    shadow === 'md' && styles.shadowMd,
                    size === 'xs' && styles.xsmall,
                    size === 'sm' && styles.small,
                    size === 'md' && styles.medium,
                    size === 'lg' && styles.large,
                    size === 'xl' && styles.xlarge,
                    justifyContent === 'space-between' &&
                        styles.justifySpaceBetween,
                    fullwidth && styles.fullwidth,
                    isLoading && styles.loading
                )}
                disabled={disabled || isLoading}
                onClick={onClick}
            >
                {isLoading ? (
                    <ScaleLoader
                        color='var(--everest-white)'
                        height={size === 'md' ? 12 : 25}
                        width={7}
                    />
                ) : (
                    children
                )}
            </button>
            {tooltip && <div className={styles.tooltip}>{tooltip}</div>}
        </span>
    );
};
