import { useContext } from 'react';
import { CompaniesContext, CompaniesDispatch } from './CompaniesProvider';

export function useCompanies() {
    const { companies, isValidating } = useContext(CompaniesContext);
    const { syncOpportunities } = useContext(CompaniesDispatch);

    return {
        companies,
        isValidating,
        syncOpportunities,
    };
}
