import { NavLink as Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from 'styles/NavBar.module.css';
import { getPageDisplayRestrictions } from 'helpers/page';

export const NavLink = ({ icon, label, displayOn, pageLink, clickAction }) => {
    const location = useLocation();
    let display = true;
    if (displayOn !== undefined) {
        display = displayOn;
    }

    if (pageLink === undefined) {
        return (
            display && (
                <li onClick={clickAction}>
                    <h2>{label}</h2>
                </li>
            )
        );
    }

    return (
        <>
            {display && (
                <Link
                    to={pageLink}
                    className={classNames(
                        styles.link,
                        location.pathname === pageLink && styles.active
                    )}
                >
                    <li onClick={clickAction}>
                        {icon && <div>{icon}</div>}
                        <h2>{label}</h2>
                    </li>
                </Link>
            )}
        </>
    );
};

export const NavLinkProps = (type, { user, status, driver_app_access }) => {
    const props = {
        '/smartbid': {
            label: 'SmartBid',
            pageLink: '/smartbid',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/smartbid'],
        },
        '/optirates': {
            label: 'OptiRates',
            pageLink: '/optirates',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/optirates'],
        },
        '/revenue360': {
            label: 'Revenue360',
            pageLink: '/revenue360',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/revenue360'],
        },
        '/lisa-analytics': {
            label: 'LISA Analytics',
            pageLink: '/lisa-analytics',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/lisa-analytics'],
        },
        '/market-map': {
            label: 'Market Map',
            pageLink: '/market-map',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/market-map'],
        },
        '/login': {
            label: 'Login',
            pageLink: '/login',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/login'],
        },
        '/allowlist': {
            label: 'Allowlist',
            pageLink: '/allowlist',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/allowlist'],
        },
        '/admin': {
            label: 'Admin',
            pageLink: '/admin',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/admin'],
        },
        '/driver-manager': {
            label: 'Driver Manager',
            pageLink: '/driver-manager',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/driver-manager'],
        },
        '/vehicle-manager': {
            label: 'Vehicle Manager',
            pageLink: '/vehicle-manager',
            displayOn: getPageDisplayRestrictions(
                user,
                status,
                driver_app_access
            )['/vehicle-manager'],
        },
    };

    return props[type];
};
