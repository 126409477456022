import { useState, useCallback, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { UserTable } from './UserTable';
import { DashboardSkeleton } from './DashboardSkeleton';
export { DashboardSkeleton };

export const Dashboard = ({ users }) => {
    // const [mounted, setMounted] = useState();
    // const [users, setUsers] = useState(undefined);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState([
        { id: nanoid(), column: null, condition: null, values: null },
    ]);

    const hasMatching = useCallback(
        (user) => {
            // string is not empty and not just whitespace
            if (/\S/.test(search)) {
                const { name, email } = user;
                const searchCase = search.toLowerCase();
                return (
                    name.toLowerCase().includes(searchCase) ||
                    email.toLowerCase().includes(searchCase)
                );
            } else {
                return true;
            }
        },
        [search]
    );

    const matchesFilters = useCallback(
        (user) => {
            let matches = true;
            const validFilters = filters.filter(
                (f) => f.column && f.condition && f.values
            );
            for (let i = 0; i < validFilters.length; i++) {
                const filter = validFilters[i];
                const boolean = filter.boolean;
                const column = filter.column;
                const condition = filter.condition;
                const values = filter.values;
                let filterCondition = true;

                const includesProducts =
                    (values.includes('sb_vans') && user.sb_vans) ||
                    (values.includes('sb_straights') && user.sb_straights) ||
                    (values.includes('sb_margin_calculator') &&
                        user.sb_margin_calculator) ||
                    (values.includes('sb_trailers') && user.sb_trailers) ||
                    (values.includes('sb_company_historical') &&
                        user.sb_company_historical) ||
                    (values.includes('r360_smartbid_usage') &&
                        user.r360_smartbid_usage) ||
                    (values.includes('r360_van_rates') &&
                        user.r360_van_rates) ||
                    (values.includes('r360_straight_rates') &&
                        user.r360_straight_rates) ||
                    (values.includes('r360_opportunity_map') &&
                        user.r360_opportunity_map) ||
                    (values.includes('r360_combined_rates') &&
                        user.r360_combined_rates) ||
                    (values.includes('r360_van_kpi') && user.r360_van_kpi) ||
                    (values.includes('r360_straight_kpi') &&
                        user.r360_straight_kpi) ||
                    (values.includes('r360_pie') && user.r360_pie) ||
                    (values.includes('r360_scatter') && user.r360_scatter) ||
                    (values.includes('r360_completed_loads') &&
                        user.r360_completed_loads);

                if (condition === 'is' || condition === 'contain') {
                    if (column === 'products') {
                        filterCondition = includesProducts;
                    } else {
                        filterCondition = values.includes(user[column]);
                    }
                } else {
                    if (column === 'products') {
                        filterCondition = !includesProducts;
                    } else {
                        filterCondition = !values.includes(user[column]);
                    }
                }

                if (boolean) {
                    if (boolean === 'and') {
                        matches = matches && filterCondition;
                    } else {
                        matches = matches || filterCondition;
                    }
                } else {
                    matches = filterCondition;
                }
            }
            return matches;
        },
        [filters]
    );

    const filteredUsers = useMemo(() => {
        if (users) {
            return users.filter(
                (user) => hasMatching(user) && matchesFilters(user)
            );
        } else {
            return [];
        }
    }, [hasMatching, matchesFilters, users]);

    return (
        <>
            {users && filteredUsers ? (
                <UserTable
                    setSearch={setSearch}
                    filters={filters}
                    setFilters={setFilters}
                    users={users}
                    filteredUsers={filteredUsers}
                />
            ) : (
                <DashboardSkeleton />
            )}
        </>
    );
};
