import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';
import styles from 'styles/Manage.module.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'styles/ag-theme-vehicle-manager.css';
import {
    FaPlus,
    FaRegSquare,
    FaTruck,
    FaSort,
    FaSortUp,
    FaSortDown,
    FaSquareCheck,
} from 'react-icons/fa6';
import { GroupedDropdownInput, Input } from 'components/Form';
import { useForm } from 'react-hook-form';
import { DropdownButton } from 'components/Revenue360';
import { AutocompleteInput } from 'components/Form';
import { isValidObject, isValidString } from 'helpers/condition';
import { useVehicles } from 'providers/Vehicles';
import { DateTimePickerInput } from 'components/Manage/VehicleManager/DateTimePicker';
import { Button } from 'components/Button';
import { AddVehicleModal } from './AddVehicleModal';
import { vehicle_classes, vehicle_statuses } from 'data';
import { IANA_TIMEZONES, getCurrTimeZone } from 'components/Form';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { smartbidServer } from 'lib/api';
import { useUser } from 'components/Accounts';
import toast from 'react-hot-toast';
import { CAN_ZIP_REGEX_MATCH, USA_ZIP_REGEX_MATCH } from 'helpers/location';
import { AssignDropdown } from './AssignDropDown';

export const USA_STATES = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
];

export const CAN_PROVINCES = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
];

const getCityStateVarName = (accessor) => {
    let city = 'city';
    let state = 'state';
    let zip = 'zip';
    if (accessor.includes('next')) {
        city = 'next_pickup_city';
        state = 'next_pickup_state';
        zip = 'next_pickup_zip';
    }
    return {
        city,
        state,
        zip,
    };
};

const getCityStateParams = (city, state, zip, location) => {
    return {
        [city]: location.at(0),
        [state]: location
            .at(1)
            ?.replace(USA_ZIP_REGEX_MATCH, '')
            ?.replace(CAN_ZIP_REGEX_MATCH, '')
            ?.trim(),
        [zip]:
            location.at(1)?.match(USA_ZIP_REGEX_MATCH, '')?.at(0)?.trim() ||
            location.at(1)?.match(CAN_ZIP_REGEX_MATCH, '')?.at(0)?.trim(),
    };
};

const useSortableTable = (setVehicleSort, columns, setColumns) => {
    const handleSorting = (sortField) => {
        // find column to sort by based on sortField
        const idx = columns.findIndex((obj) => obj.accessor === sortField);

        // set new sort for selected column
        let sortOrder = null;
        if (!columns[idx].sortOrder) {
            setColumns((prev) =>
                prev.map((column, i) => {
                    if (i !== idx) {
                        // reset sort order from other columns
                        return { ...column, sortOrder: null };
                    }
                    return { ...column, sortOrder: 'asc' };
                })
            );
            sortOrder = 'asc';
        } else if (columns[idx].sortOrder === 'asc') {
            setColumns((prev) =>
                prev.map((column, i) => {
                    if (i !== idx) {
                        // reset sort order from other columns
                        return { ...column, sortOrder: null };
                    }
                    return { ...column, sortOrder: 'desc' };
                })
            );
            sortOrder = 'desc';
        } else {
            setColumns((prev) =>
                prev.map((column) => {
                    return { ...column, sortOrder: null };
                })
            );
        }
        setVehicleSort({ sort_field: sortField, sort_order: sortOrder });
    };
    return { handleSorting };
};

const CheckboxCell = ({
    isSelected,
    setIsSelected,
    row,
    selectedVehicles,
    setSelectedVehicles,
}) => {
    return (
        <td
            className={styles.clickable}
            onClick={() => {
                setIsSelected(!isSelected);
                if (!isSelected) {
                    if (selectedVehicles.length < 1) {
                        setSelectedVehicles([row?.vehicle_id]);
                    } else {
                        setSelectedVehicles([
                            ...selectedVehicles,
                            row?.vehicle_id,
                        ]);
                    }
                } else {
                    const idx = selectedVehicles.indexOf(row?.vehicle_id);
                    if (idx !== -1) {
                        setSelectedVehicles((prev) =>
                            prev.filter((_item, i) => i !== idx)
                        );
                    }
                }
            }}
        >
            {isSelected ? <FaSquareCheck /> : <FaRegSquare />}
        </td>
    );
};

const RenderedCell = ({ row, accessor, cellStylizer, valueFormatter }) => {
    if (
        accessor === 'status_description' ||
        accessor === 'multistate_next_delivery' ||
        (accessor === 'assignments' && row[accessor]) ||
        accessor.includes('datetime')
    ) {
        return cellStylizer(row, accessor);
    }

    if (
        !isValidString(valueFormatter(row, accessor)) &&
        !isValidObject(valueFormatter(row, accessor))
    ) {
        return '—';
    }

    return valueFormatter(row, accessor);
};

const EditableCell = ({
    row,
    accessor,
    editType,
    index,
    columns,
    companyId,
    customMinWidth,
    idx,
}) => {
    const valueFormatter = (row, accessor) => {
        const value = row[accessor];

        if (accessor === 'assignments' && Array.isArray(value)) {
            return value.map(({ user_id }) => user_id);
        } else if (typeof value === 'string') {
            return value.trim();
        } else if (typeof value === 'object' && value !== null) {
            const date_value =
                process.env.REACT_APP_SMARTBID_SERVER_BASE_URL.includes(
                    'localhost'
                )
                    ? zonedTimeToUtc(value)
                    : value;

            return date_value.toLocaleString('en-US', {
                timeZone: IANA_TIMEZONES[getCurrTimeZone()],
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZoneName: 'short',
            });
        } else {
            return value;
        }
    };

    const { updateVehicle, user_list } = useVehicles();
    const [isEditing, setIsEditing] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors, dirtyFields },
    } = useForm({
        [accessor]: valueFormatter(row, accessor),
        [`${accessor}-${index}`]: valueFormatter(row, accessor),
    });

    const cellStylizer = (row, accessor) => {
        const value = row[accessor];
        if (accessor === 'status_description') {
            let color = '';
            switch (value?.toUpperCase()) {
                case 'IN SERVICE':
                    color = 'green';
                    break;
                case 'IN SERVICE CONFIRMED':
                    color = 'green';
                    break;
                case 'ON A LOAD':
                    color = 'orange';
                    break;
                case 'OUT OF SERVICE':
                    color = 'red';
                    break;
                default:
                    break;
            }
            return (
                <div
                    className={classNames(
                        styles.vehicleStatus,
                        color === 'green' && styles.vehicleStatusGreen,
                        color === 'orange' && styles.vehicleStatusOrange,
                        color === 'red' && styles.vehicleStatusRed
                    )}
                >
                    {value}
                </div>
            );
        } else if (accessor === 'next_pickup_location') {
            if (isValidString(value)) {
                return value;
            } else {
                return (
                    <span
                        style={{ color: 'var(--green-500)', fontWeight: 600 }}
                    >
                        EVERYWHERE
                    </span>
                );
            }
        } else if (accessor === 'multistate_next_delivery') {
            if (isValidString(value)) {
                return value.replaceAll(',', ', ');
            } else {
                return (
                    <span
                        style={{ color: 'var(--green-500)', fontWeight: 600 }}
                    >
                        EVERYWHERE
                    </span>
                );
            }
        } else if (accessor === 'assignments') {
            if (Array.isArray(value) && value?.length !== 0) {
                return value?.map(({ name }) => name).join(', ');
            } else {
                return '—';
            }
        } else if (typeof value === 'object') {
            if (value !== null) {
                const date_value =
                    process.env.REACT_APP_SMARTBID_SERVER_BASE_URL.includes(
                        'localhost'
                    )
                        ? zonedTimeToUtc(value)
                        : value;
                const datetime = date_value
                    .toLocaleString('en-US', {
                        timeZone: IANA_TIMEZONES[getCurrTimeZone()],
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        timeZoneName: 'short',
                    })
                    .split(', ');
                return (
                    <span>
                        {datetime.at(0)}
                        <br />
                        {datetime.at(1)}
                    </span>
                );
            } else {
                return (
                    <span
                        style={{ color: 'var(--green-500)', fontWeight: 600 }}
                    >
                        ANYTIME
                    </span>
                );
            }
        }
    };

    const [cellValue, setCellValue] = useState(valueFormatter(row, accessor));
    const [vehicleId, setVehicleId] = useState(null);
    const [updateType, setUpdateType] = useState(null);

    const wrapperRef = useRef(null);
    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (e) => {
                if (ref.current && !ref.current.contains(e.target)) {
                    setIsEditing(false);
                } else {
                    console.log(
                        `DEV::Clicked outside ${
                            valueFormatter(row, accessor) ?? ''
                        }`
                    );
                    console.log(
                        `DEV::(row, accessor, cellValue)`,
                        row,
                        accessor,
                        cellValue
                    );
                }
            };
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    };
    useOutsideAlerter(wrapperRef);

    // also updates database when clicking outside cell that has been edited
    useEffect(() => {
        if (!isEditing && vehicleId !== null && updateType !== null) {
            // console.log('DEV::Not editing!');
            const updateVehicleData = async () => {
                if (updateType?.includes('location')) {
                    const { city, state, zip } = getCityStateVarName(accessor);
                    const location = cellValue
                        .replace(', USA', '')
                        .replace(', Canada', '')
                        .split(', ');
                    if (location.length === 2) {
                        await updateVehicle({
                            vehicle_id: vehicleId,
                            company_id: companyId,
                            params: getCityStateParams(
                                city,
                                state,
                                zip,
                                location
                            ),
                        });
                    }
                } else if (updateType?.includes('datetime')) {
                    await updateVehicle({
                        vehicle_id: vehicleId,
                        company_id: companyId,
                        params: { [accessor]: utcToZonedTime(cellValue) },
                    });
                } else if (editType === 'dropdown_multiselect') {
                    await updateVehicle({
                        vehicle_id: vehicleId,
                        company_id: companyId,
                        params: {
                            [accessor]: Array.isArray(cellValue)
                                ? cellValue
                                      .map((o) =>
                                          typeof o === 'object' ? o.value : o
                                      )
                                      .join(',')
                                : null,
                        },
                    });
                } else {
                    await updateVehicle({
                        vehicle_id: vehicleId,
                        company_id: companyId,
                        params: { [accessor]: cellValue },
                    });
                }
            };
            updateVehicleData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    // update cell values whenever changes are made or if sorting updates
    useEffect(() => {
        if (isEditing) {
            setValue(accessor, valueFormatter(row, accessor));
            setValue(`${accessor}-${index}`, valueFormatter(row, accessor));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    const onSubmit = () => {
        console.log(new Date().toISOString(), 'DEBUG:form submitted');
    };

    return (
        <td
            className={classNames(
                accessor !== 'vehicle_id' &&
                    editType !== 'none' &&
                    styles.clickable,
                styles.editableCell,
                idx === 0 && styles.stickyColumn
            )}
            onClick={() => {
                if (editType !== 'none') {
                    setIsEditing(true);
                }
            }}
            ref={wrapperRef}
            style={
                customMinWidth
                    ? {
                          minWidth: customMinWidth,
                      }
                    : null
            }
        >
            {!isEditing && (
                <RenderedCell
                    row={row}
                    accessor={accessor}
                    cellStylizer={cellStylizer}
                    valueFormatter={valueFormatter}
                />
            )}
            {isEditing && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    {editType === 'text' && (
                        <Input
                            type='text'
                            label={accessor}
                            placeholder={
                                cellValue === undefined ||
                                (typeof cellValue === 'string' &&
                                    cellValue.trim() === '')
                                    ? columns.find(
                                          (obj) => obj?.accessor === accessor
                                      )?.label
                                    : undefined
                            }
                            register={register}
                            errors={errors}
                            watch={watch}
                            value={cellValue}
                            onChange={(e) => {
                                setCellValue(e.target.value);
                                setVehicleId(row?.vehicle_id);
                                setUpdateType(accessor);
                            }}
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter') {
                                    setIsEditing(false);
                                    await updateVehicle({
                                        vehicle_id: vehicleId,
                                        company_id: companyId,
                                        params: {
                                            [accessor]: cellValue,
                                        },
                                    });
                                }
                            }}
                        />
                    )}
                    {editType === 'dropdown' && (
                        <DropdownButton
                            width='100%'
                            value={cellValue}
                            setValue={setCellValue}
                            options={
                                columns.find((obj) => obj.accessor === accessor)
                                    ?.editOptions
                            }
                            tight
                            onSelect={async (option) => {
                                setVehicleId(row?.vehicle_id);
                                setIsEditing(false);
                                await updateVehicle({
                                    vehicle_id: row?.vehicle_id,
                                    company_id: companyId,
                                    params: {
                                        [accessor]: option,
                                    },
                                });
                            }}
                        />
                    )}
                    {editType === 'dropdown_multiselect' && (
                        <GroupedDropdownInput
                            name={accessor}
                            control={control}
                            multiselect
                            groupedOptions={[
                                {
                                    label: 'USA States',
                                    options: USA_STATES.map((state) => {
                                        return { value: state, label: state };
                                    }),
                                },
                                {
                                    label: 'CAN Provinces',
                                    options: CAN_PROVINCES.map((province) => {
                                        return {
                                            value: province,
                                            label: province,
                                        };
                                    }),
                                },
                            ]}
                            onChangeCallback={async () => {
                                setVehicleId(row?.vehicle_id);
                                const multistate_next_delivery = watch(
                                    'multistate_next_delivery'
                                );
                                if (multistate_next_delivery) {
                                    await updateVehicle({
                                        vehicle_id: row?.vehicle_id,
                                        company_id: companyId,
                                        params: {
                                            [accessor]: Array.isArray(
                                                multistate_next_delivery
                                            )
                                                ? multistate_next_delivery
                                                      .map((o) =>
                                                          typeof o === 'object'
                                                              ? o.value
                                                              : o
                                                      )
                                                      .join(',')
                                                : null,
                                        },
                                    });
                                }
                            }}
                        />
                    )}
                    {editType === 'autocomplete' && (
                        <>
                            <AutocompleteInput
                                id={`${accessor}-${index}`}
                                value={watch(`${accessor}-${index}`) || ''}
                                setValue={setValue}
                                label={accessor}
                                register={register}
                                errors={errors}
                                watch={watch}
                                onSelect={async (e) => {
                                    const location = e
                                        .replace(', USA', '')
                                        .replace(', Canada', '')
                                        .split(', ');
                                    const { city, state, zip } =
                                        getCityStateVarName(accessor);
                                    if (location.length === 2) {
                                        await updateVehicle({
                                            vehicle_id: vehicleId,
                                            company_id: companyId,
                                            params: getCityStateParams(
                                                city,
                                                state,
                                                zip,
                                                location
                                            ),
                                        });
                                    }
                                }}
                                onClear={async () => {
                                    const { city, state, zip } =
                                        getCityStateVarName(accessor);
                                    await updateVehicle({
                                        vehicle_id: row?.vehicle_id,
                                        company_id: companyId,
                                        params: {
                                            [city]: null,
                                            [state]: null,
                                            [zip]: null,
                                        },
                                    });
                                }}
                                onChange={(e) => {
                                    setCellValue(e);
                                    setVehicleId(row?.vehicle_id);
                                    setUpdateType(accessor);
                                }}
                                overrideInputVal
                            />
                        </>
                    )}
                    {editType === 'datetime' && (
                        <DateTimePickerInput
                            name={accessor}
                            label='Date & Time'
                            control={control}
                            dirtyFields={dirtyFields}
                            value={cellValue}
                            setValue={setCellValue}
                            onChange={async (val) => {
                                await updateVehicle({
                                    vehicle_id: row?.vehicle_id,
                                    company_id: companyId,
                                    params: {
                                        [accessor]: val
                                            ? val.toISOString()
                                            : null,
                                    },
                                });
                            }}
                        />
                    )}
                    {editType === 'checkbox' && (
                        <AssignDropdown
                            users={user_list}
                            assigned={cellValue}
                            onChange={(newAssigned) => {
                                setCellValue(newAssigned);
                                setVehicleId(row?.vehicle_id);
                                setUpdateType(accessor);
                            }}
                        />
                    )}
                </form>
            )}
        </td>
    );
};

const EditingTableButtons = ({
    isEditingVehicles,
    setIsEditingVehicles,
    selectedVehicles,
    setSelectedVehicles,
}) => {
    const { token } = useUser();
    const { company_id, syncVehicles } = useVehicles();
    if (!isEditingVehicles) {
        return (
            <Button
                uitype='ghost'
                color='primary'
                size='md'
                onClick={() => {
                    setIsEditingVehicles(true);
                }}
            >
                Select Vehicles
            </Button>
        );
    } else {
        const num_selected_vehicles =
            selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : '';
        return (
            <>
                <Button
                    uitype='cta'
                    color='red'
                    size='md'
                    disabled={selectedVehicles.length < 1}
                    onClick={async () => {
                        const config = {
                            headers: { 'x-access-token': token },
                            data: {
                                company_id,
                                vehicle_id_arr: selectedVehicles,
                            },
                        };
                        await smartbidServer
                            .delete('/api/users/manage/vehicles', config)
                            .then(() => {
                                syncVehicles().then(() => {
                                    toast.success(`Deleted vehicles`, {
                                        id: `delete-vehicles-${selectedVehicles.join(
                                            '-'
                                        )}`,
                                    });
                                });
                            })
                            .catch(() => {
                                toast.error(`Failed to delete vehicles`, {
                                    id: `delete-vehicles-${selectedVehicles.join(
                                        '-'
                                    )}`,
                                });
                            });
                        setIsEditingVehicles(false);
                        setSelectedVehicles([]);
                    }}
                >
                    {['Delete Selected', num_selected_vehicles].join(' ')}
                </Button>
                <Button
                    uitype='ghost'
                    color='secondary'
                    size='md'
                    onClick={() => {
                        setIsEditingVehicles(false);
                        setSelectedVehicles([]);
                    }}
                >
                    Cancel
                </Button>
            </>
        );
    }
};

const VehicleManagerRow = ({
    row,
    isEditingVehicles,
    columns,
    index,
    companyId,
    selectedVehicles,
    setSelectedVehicles,
}) => {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (!isEditingVehicles) setIsSelected(false);
    }, [isEditingVehicles]);

    return (
        <tr
            key={`${row?.vehicle_id}-row`}
            className={classNames(
                isSelected && isEditingVehicles && styles.rowIsSelected
            )}
        >
            <>
                {isEditingVehicles && (
                    <CheckboxCell
                        isSelected={isSelected}
                        setIsSelected={setIsSelected}
                        row={row}
                        selectedVehicles={selectedVehicles}
                        setSelectedVehicles={setSelectedVehicles}
                    />
                )}
                {columns.map(
                    (
                        {
                            accessor,
                            editType,
                            render = true,
                            customMinWidth = null,
                        },
                        idx
                    ) =>
                        render && (
                            <EditableCell
                                row={row}
                                accessor={accessor}
                                editType={editType}
                                index={index}
                                companyId={companyId}
                                columns={columns}
                                key={`${row?.vehicle_id}-${accessor}`}
                                customMinWidth={customMinWidth}
                                idx={idx}
                            />
                        )
                )}
            </>
        </tr>
    );
};

const VehicleManagerTable = ({
    vehicles,
    companyId,
    setVehicleSort,
    columns,
    setColumns,
}) => {
    const { handleSorting } = useSortableTable(
        setVehicleSort,
        columns,
        setColumns
    );
    const [openCreate, setOpenCreate] = useState(false);
    const [isEditingVehicles, setIsEditingVehicles] = useState(false);
    const [selectedVehicles, setSelectedVehicles] = useState([]);

    return (
        <div className={styles.vehicleManagerTableWrapper}>
            <table cellSpacing='0'>
                <caption>
                    <div className={styles.vehicleManagerTableCaption}>
                        <div className={styles.title}>Vehicle Manager</div>
                        <div className={styles.subtitle}>
                            *Vehicles can only either have "Delivery Location"
                            or "Delivery States" at one time. Entering one of
                            the two will clear the other.
                        </div>
                        <div className={styles.vehicleManagerTableActions}>
                            <Button
                                uitype='cta'
                                color='primary'
                                size='md'
                                onClick={() => {
                                    setOpenCreate(true);
                                }}
                            >
                                <FaPlus />
                                <FaTruck />
                                Add Vehicle
                            </Button>
                            <EditingTableButtons
                                isEditingVehicles={isEditingVehicles}
                                setIsEditingVehicles={setIsEditingVehicles}
                                selectedVehicles={selectedVehicles}
                                setSelectedVehicles={setSelectedVehicles}
                            />
                        </div>
                    </div>
                </caption>
                <thead>
                    <tr>
                        <>
                            {isEditingVehicles && (
                                <th
                                    className={styles.vehicleManagerTableHeader}
                                    key='selector-column'
                                />
                            )}
                            {columns?.map(
                                (
                                    {
                                        label,
                                        accessor,
                                        sortable = true,
                                        sortOrder = null,
                                        render = true,
                                        customMinWidth = null,
                                    },
                                    idx
                                ) =>
                                    render && (
                                        <th
                                            className={classNames(
                                                styles.vehicleManagerTableHeader,
                                                sortable && styles.clickable,
                                                styles.editableCellColumnHeader,
                                                idx === 0 && styles.stickyColumn
                                            )}
                                            key={`${accessor}-column`}
                                            onClick={
                                                sortable
                                                    ? () =>
                                                          handleSorting(
                                                              accessor
                                                          )
                                                    : null
                                            }
                                            style={
                                                customMinWidth
                                                    ? {
                                                          minWidth:
                                                              customMinWidth,
                                                      }
                                                    : null
                                            }
                                        >
                                            <span>
                                                {label}
                                                <span>
                                                    {sortable && !sortOrder && (
                                                        <FaSort />
                                                    )}
                                                    {sortable &&
                                                        sortOrder === 'asc' && (
                                                            <FaSortUp />
                                                        )}
                                                    {sortable &&
                                                        sortOrder ===
                                                            'desc' && (
                                                            <FaSortDown />
                                                        )}
                                                </span>
                                            </span>
                                        </th>
                                    )
                            )}
                        </>
                    </tr>
                </thead>
                <tbody>
                    {vehicles?.map((row, index) => (
                        <VehicleManagerRow
                            key={row?.vehicle_id}
                            row={row}
                            index={index}
                            isEditingVehicles={isEditingVehicles}
                            columns={columns}
                            companyId={companyId}
                            selectedVehicles={selectedVehicles}
                            setSelectedVehicles={setSelectedVehicles}
                        />
                    ))}
                </tbody>
            </table>
            <AddVehicleModal open={openCreate} handler={setOpenCreate} />
        </div>
    );
};

export const VehicleManager = () => {
    const {
        vehicles,
        vehicle_sort_order,
        sorted_vehicles,
        company_id,
        isValidating: loading,
        setVehicleSort,
    } = useVehicles();
    const [columns, setColumns] = useState([
        {
            label: 'Vehicle ID',
            accessor: 'vehicle_id',
            editType: 'none',
        },
        {
            label: 'Driver Name',
            accessor: 'driver_id',
            editType: 'text',
        },
        {
            label: 'Vehicle Class',
            accessor: 'vehicle_class',
            editType: 'dropdown',
            editOptions: vehicle_classes,
        },
        {
            label: 'Status',
            accessor: 'status_description',
            editType: 'dropdown',
            editOptions: vehicle_statuses,
            customMinWidth: 200,
        },
        {
            label: 'Pickup Location',
            accessor: 'location',
            editType: 'autocomplete',
        },
        {
            label: 'Pickup Date',
            accessor: 'available_datetime',
            editType: 'datetime',
        },
        {
            label: 'Delivery Location*',
            accessor: 'next_pickup_location',
            editType: 'autocomplete',
        },
        {
            label: 'Delivery States*',
            accessor: 'multistate_next_delivery',
            editType: 'dropdown_multiselect',
        },
        {
            label: 'Delivery Date',
            accessor: 'next_pickup_datetime',
            editType: 'datetime',
        },
        {
            label: 'Assign Users',
            accessor: 'assignments',
            editType: 'checkbox',
            customMinWidth: 200,
        },
        {
            label: 'Notes',
            accessor: 'notes',
            sortable: false,
            editType: 'text',
            customMinWidth: 200,
        },
        {
            label: 'Dispatcher Notes',
            accessor: 'dispatcher_notes',
            sortable: false,
            editType: 'none',
            customMinWidth: 200,
        },
        {
            label: 'Length',
            accessor: 'length',
            editType: 'text',
            render: false,
        },
        {
            label: 'Width',
            accessor: 'width',
            editType: 'text',
            render: false,
        },
        {
            label: 'Height',
            accessor: 'height',
            editType: 'text',
            render: false,
        },
        {
            label: 'Weight',
            accessor: 'weight',
            editType: 'text',
            render: false,
        },
        {
            label: 'Door Width',
            accessor: 'door_width',
            editType: 'text',
            render: false,
        },
        {
            label: 'Door Height',
            accessor: 'door_height',
            editType: 'text',
            render: false,
        },
    ]);

    return (
        <div className={classNames('ag-theme-laneData', styles.vehicleManager)}>
            {/* only show loading skeleton when fetching vehicle data the first time */}
            {loading && !vehicles ? (
                <Skeleton
                    containerClassName={styles.loading}
                    baseColor='var(--secondary-dark)'
                    highlightColor='var(--secondary)'
                    height={'100%'}
                    borderRadius={5}
                />
            ) : (
                <>
                    <VehicleManagerTable
                        vehicles={
                            !vehicle_sort_order ? vehicles : sorted_vehicles
                        }
                        companyId={company_id}
                        setVehicleSort={setVehicleSort}
                        columns={columns}
                        setColumns={setColumns}
                    />
                </>
            )}
        </div>
    );
};
