import React, { useState, useEffect } from 'react';
import { useUser } from 'components/Accounts';
import { useNavigate, useLocation } from 'react-router-dom';
import { Page } from 'components/Page';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getPageDisplayRestrictions } from 'helpers/page';
import { useVehicles } from 'providers/Vehicles';

export const ProtectedPage = ({
    children,
    restrictions,
    includeBackground,
    title,
    wide,
    noPadding,
    fullPage,
    hideSideNav,
}) => {
    const { user, status } = useUser();
    const { driver_app_access } = useVehicles();

    const [mounted, setMounted] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const show = () => {
        if (status === 'authenticated' && restrictions.includes('logged-in')) {
            return false;
        } else if (status === 'unauthenticated') {
            return true; // always show, assuming user is redirected to login page
        }

        // get page-specific display restrictions when authenticated or in limbo state
        // (i.e. in the middle of checking for authentication)
        // to prevent user from peeking at page contents when attempting to access a specific page manually
        return Boolean(
            getPageDisplayRestrictions(user, status, driver_app_access)[
                location.pathname
            ]
        );
    };

    useEffect(() => {
        if (mounted) {
            if (status === 'unauthenticated') {
                if (restrictions.includes('login-required')) {
                    navigate('/login');
                }
            } else if (status === 'authenticated') {
                const defaultPathname = '/smartbid';

                if (restrictions.includes('logged-in')) {
                    navigate(defaultPathname);
                } else if (
                    !Boolean(
                        getPageDisplayRestrictions(
                            user,
                            status,
                            driver_app_access
                        )[location.pathname]
                    )
                ) {
                    navigate(defaultPathname);
                }
            }
        }
        setMounted(true);
    }, [
        status,
        navigate,
        restrictions,
        mounted,
        user,
        driver_app_access,
        location.pathname,
    ]);

    if (!mounted || status === 'loading') {
        return (
            <Page fullPage={fullPage} hideSideNav={hideSideNav}>
                <Skeleton
                    height={120}
                    borderRadius={15}
                    style={{ marginBottom: 5 }}
                />
                <Skeleton height={480} borderRadius={15} />
            </Page>
        );
    }

    return (
        <Page
            title={title}
            includeBackground={includeBackground}
            wide={wide}
            noPadding={noPadding}
            fullPage={fullPage}
            hideSideNav={hideSideNav}
        >
            {show() && <>{children}</>}
        </Page>
    );
};
