import { Card } from 'components/Card';
import { Toggle } from './components';
import styles from 'styles/Revenue360.module.css';
import { getBackdate } from './helpers';
import { useLayoutEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { UnavailableText } from './UnavailableText';

const getTotalRevenue = (data, dateSpan) => {
    let startDate;
    switch (dateSpan) {
        case 'Last 30 Days':
            startDate = new Date(getBackdate(31));
            break;
        case 'Last 60 Days':
            startDate = new Date(getBackdate(61));
            break;
        case 'Last 180 Days':
            startDate = new Date(getBackdate(181));
            break;
        default:
            startDate = new Date(getBackdate(0));
            break;
    }
    const filteredData = data.filter(({ date }) => new Date(date) >= startDate);
    const initialValue = 0;

    return filteredData.reduce(
        (sum, datapoint) => sum + parseFloat(datapoint.revenue_total),
        initialValue
    );
};

const TotalRevenueValue = ({ kpiView, vehicleType, kpiData, dateSpan }) => {
    const dollarUS = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    if (kpiView === vehicleType) {
        if (kpiData.length !== 0) {
            const total_revenue = getTotalRevenue(kpiData, dateSpan);
            return (
                <span className={styles.kpi}>
                    {dollarUS.format(total_revenue)}
                </span>
            );
        } else {
            return <UnavailableText />;
        }
    } else {
        return null;
    }
};

const DateDisplay = ({ dateSpan }) => {
    switch (dateSpan) {
        case 'Last 30 Days':
            return (
                <span>
                    (
                    {`${getBackdate(30, 'short')} — ${getBackdate(0, 'short')}`}
                    )
                </span>
            );
        case 'Last 60 Days':
            return (
                <span>
                    (
                    {`${getBackdate(60, 'short')} — ${getBackdate(0, 'short')}`}
                    )
                </span>
            );
        case 'Last 180 Days':
            return (
                <span>
                    (
                    {`${getBackdate(180, 'short')} — ${getBackdate(
                        0,
                        'short'
                    )}`}
                    )
                </span>
            );
        default:
            return null;
    }
};

export const KPICard = ({
    kpiData,
    kpiView,
    setKPIView,
    loading,
    dateSpan,
}) => {
    const { van, small_straight, large_straight } = kpiData.total_revenue;

    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, []);

    return (
        <Card fullWidth fullHeight smallPadding>
            <div
                className={width < 400 ? styles.narrowHeader : styles.header}
                ref={ref}
            >
                <h1 className={styles.heading}>Total Revenue</h1>
                <Toggle
                    views={[
                        'Sprinter/Cargo Vans',
                        'Sm Straight Trucks',
                        'Lg Straight Trucks',
                    ]}
                    currentView={kpiView}
                    setCurrentView={setKPIView}
                />
            </div>
            <div className={styles.cardContent}>
                {!loading ? (
                    <>
                        <TotalRevenueValue
                            kpiView={kpiView}
                            vehicleType={'Sprinter/Cargo Vans'}
                            kpiData={van}
                            dateSpan={dateSpan}
                        />
                        <TotalRevenueValue
                            kpiView={kpiView}
                            vehicleType={'Sm Straight Trucks'}
                            kpiData={small_straight}
                            dateSpan={dateSpan}
                        />
                        <TotalRevenueValue
                            kpiView={kpiView}
                            vehicleType={'Lg Straight Trucks'}
                            kpiData={large_straight}
                            dateSpan={dateSpan}
                        />
                    </>
                ) : (
                    <Skeleton
                        style={{ display: 'flex', margin: '40% auto' }}
                        baseColor='var(--secondary-dark)'
                        highlightColor='var(--secondary)'
                        height={41}
                        width={200}
                        borderRadius={5}
                    />
                )}
                {!loading ? (
                    <>
                        <div className={styles.kpiDateLabels}>
                            <span>{!loading && dateSpan}</span>
                        </div>
                        <div className={styles.kpiDates}>
                            <DateDisplay dateSpan={dateSpan} />
                        </div>
                    </>
                ) : (
                    <div className={styles.kpiDates}>
                        <Skeleton
                            style={{ display: 'flex' }}
                            baseColor='var(--secondary-dark)'
                            highlightColor='var(--secondary)'
                            height={32}
                            width={90}
                            borderRadius={5}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
};
