import { DashboardSkeleton } from 'components/Admin';
import { ProtectedPage } from 'components/Page';
import { Suspense, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'styles/ag-theme-laneData.css';
import { useUser } from 'components/Accounts';
import DriverAppManage from 'components/Manage/DriverAppManage';
import { useVehicles } from 'providers/Vehicles';
import toast from 'react-hot-toast';

const DriverManagerPage = () => {
    const { user } = useUser();
    const { company_id, isValidating, performingAction } = useVehicles();

    useEffect(() => {
        if (performingAction) {
            toast.dismiss('sync-vehicles-notification');
        } else {
            if (isValidating) {
                toast.loading('Syncing vehicles...', {
                    id: 'sync-vehicles-notification',
                });
            } else {
                toast.success('Syncing vehicles complete!', {
                    id: 'sync-vehicles-notification',
                });
            }
        }
    }, [isValidating, performingAction]);

    return (
        <ProtectedPage
            title='Driver Manager'
            wide
            restrictions={['login-required']}
        >
            <Suspense fallback={<DashboardSkeleton />}>
                <DriverAppManage companyId={company_id} user={user} />
            </Suspense>
        </ProtectedPage>
    );
};

export default DriverManagerPage;
