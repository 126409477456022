import { Line } from 'react-chartjs-2';
import classNames from 'classnames';
import { loadsChartOptions } from './options';
import styles from 'styles/Revenue360.module.css';

export const LoadsChart = ({ id, filtered, printable, orientation }) => {
    return (
        <div
            className={classNames(
                !printable && styles.chartContainer,
                printable &&
                    orientation === 'landscape' &&
                    styles.landscapeChartContainer,
                printable &&
                    orientation === 'portrait' &&
                    styles.portraitChartContainer
            )}
        >
            <Line
                id={id}
                height={80}
                data={filtered}
                options={loadsChartOptions()}
            />
        </div>
    );
};
