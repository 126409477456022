import classNames from 'classnames';
import styles from 'styles/Revenue360.module.css';
import { ConditionalWrapper } from 'components/ConditionalWrapper';

export const Toggle = ({
    views,
    currentView,
    setCurrentView,
    hideIfOne,
    r360,
}) => {
    return hideIfOne && views.length === 1 ? (
        <></>
    ) : (
        <ConditionalWrapper
            condition={r360}
            wrapper={(children) => (
                <div className={styles.r360toggleContainer}>{children}</div>
            )}
        >
            <div className={styles.toggle}>
                {views.map((view) => (
                    <button
                        key={view}
                        className={classNames(
                            styles.option,
                            currentView === view && styles.selected
                        )}
                        onClick={() => setCurrentView(view)}
                    >
                        {view}
                    </button>
                ))}
            </div>
        </ConditionalWrapper>
    );
};
