import React from 'react';
import { FaArrowLeft, FaBolt } from 'react-icons/fa6';
import classNames from 'classnames';
import { Button } from 'components/Button';
import styles from 'styles/OptiRates.module.css';

export const ConfirmModule = ({
    processed,
    setConfirmedBatch,
    parsedData,
    setRatesInfo,
    reset,
}) => {
    return (
        <div
            className={classNames(styles.module, processed && styles.processed)}
        >
            <div className={styles.moduleStep}>2</div>
            <div className={styles.moduleHeader}>
                <h2 className={styles.moduleHeading}>Confirm</h2>
                <p className={styles.moduleCopy}>
                    By clicking "Get Bulk Rates", you confirm that your input is
                    ready to be processed.
                </p>
            </div>
            <div className={styles.moduleFooter}>
                <Button uitype='ghost' size='md' onClick={() => reset()}>
                    <FaArrowLeft />
                    Go Back to Review
                </Button>
                <Button
                    uitype='cta'
                    size='md'
                    color='primary-dark'
                    onClick={() => {
                        setConfirmedBatch(true);
                        setRatesInfo(parsedData);
                    }}
                >
                    <FaBolt />
                    Get Bulk Rates
                </Button>
            </div>
        </div>
    );
};
