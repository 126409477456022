import { Card } from 'components/Card';
import { Scatter } from 'react-chartjs-2';
import styles from 'styles/Revenue360.module.css';
import { getBackdate } from './helpers';
import { useUser } from 'components/Accounts';
import Skeleton from 'react-loading-skeleton';
import { UnavailableText } from './UnavailableText';

const formatNum = (num) => {
    return parseFloat(num)?.toFixed(2);
};

const DateDisplay = ({ dateSpan, loading }) => {
    if (loading) {
        return null;
    }
    switch (dateSpan) {
        case 'Last 30 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(30, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        case 'Last 60 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(60, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        case 'Last 180 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(180, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        default:
            return <p>{dateSpan}</p>;
    }
};

const filterData = (data, dateSpan) => {
    if (!data || data.length === 0) {
        return [];
    }

    let startDate;
    switch (dateSpan) {
        case 'Last 30 Days':
            startDate = new Date(getBackdate(31));
            break;
        case 'Last 60 Days':
            startDate = new Date(getBackdate(61));
            break;
        case 'Last 180 Days':
            startDate = new Date(getBackdate(181));
            break;
        default:
            startDate = new Date(getBackdate(0));
            break;
    }

    return data.filter(
        ({ date, rate, distance }) =>
            new Date(date) >= startDate &&
            parseFloat(rate) !== 0 &&
            parseFloat(distance) !== 0
    );
};

export const ScatterChartCard = ({ scatterData, loading, dateSpan }) => {
    const { user } = useUser();

    const options = {
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Distance (mi)',
                    font: {
                        family: 'Open Sans',
                        size: 14,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Rate',
                    font: {
                        family: 'Open Sans',
                        size: 14,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
            },
        },
    };

    const filteredScatterData = {
        vans: filterData(scatterData?.vans, dateSpan),
        small_straights: filterData(scatterData?.small_straights, dateSpan),
        large_straights: filterData(scatterData?.large_straights, dateSpan),
    };

    const data = {
        datasets: [
            {
                label: 'Sprinter/Cargo Vans',
                data: filteredScatterData?.vans?.map((obj) => {
                    return {
                        x: formatNum(obj?.distance),
                        y: formatNum(obj?.rate),
                    };
                }),
                borderColor: window
                    .getComputedStyle(document.getElementById('root'))
                    .getPropertyValue('--graph-light-blue'),
                backgroundColor: window
                    .getComputedStyle(document.getElementById('root'))
                    .getPropertyValue('--graph-light-blue-transparent'),
            },
            {
                label: 'Sm Straight Trucks',
                data: filteredScatterData?.small_straights?.map((obj) => {
                    return {
                        x: formatNum(obj?.distance),
                        y: formatNum(obj?.rate),
                    };
                }),
                borderColor: window
                    .getComputedStyle(document.getElementById('root'))
                    .getPropertyValue('--graph-orange'),
                backgroundColor: window
                    .getComputedStyle(document.getElementById('root'))
                    .getPropertyValue('--graph-orange-transparent'),
            },
            {
                label: 'Lg Straight Trucks',
                data: filteredScatterData?.large_straights?.map((obj) => {
                    return {
                        x: formatNum(obj?.distance),
                        y: formatNum(obj?.rate),
                    };
                }),
                borderColor: window
                    .getComputedStyle(document.getElementById('root'))
                    .getPropertyValue('--graph-green'),
                backgroundColor: window
                    .getComputedStyle(document.getElementById('root'))
                    .getPropertyValue('--graph-green-transparent'),
            },
        ],
    };

    return (
        <Card fullWidth fullHeight smallPadding>
            <div className={styles.header}>
                <h1 className={styles.heading}>Rate vs Distance</h1>
            </div>
            <DateDisplay dateSpan={dateSpan} loading={loading} />
            {loading ? (
                <Skeleton
                    style={{ margin: '5% auto' }}
                    baseColor='var(--secondary-dark)'
                    highlightColor='var(--secondary)'
                    height={200}
                    borderRadius={5}
                />
            ) : filteredScatterData?.vans.length !== 0 &&
              filteredScatterData?.small_straights.length !== 0 &&
              filteredScatterData?.large_straights.length !== 0 ? (
                <Scatter options={options} data={data} />
            ) : (
                <UnavailableText />
            )}
        </Card>
    );
};
