import classNames from 'classnames';
import { HiCog, HiLogout } from 'react-icons/hi';
import { useUser, usePreview } from 'components/Accounts';
import { Tab, Divider } from './components';
import styles from 'styles/UserDropdown.module.css';

export const UserDropdown = ({ show, handler }) => {
    const { user, logout } = useUser();
    const { disable } = usePreview();

    return (
        <>
            <div className={classNames(styles.wrapper, show && styles.show)}>
                <div className={styles.userInfo}>
                    <span>{user?.name}</span>
                    <span>{user?.company}</span>
                    <span>{user?.email}</span>
                </div>
                <ul onClick={() => handler(false)}>
                    <Tab
                        icon={<HiCog />}
                        label='Settings'
                        pageLink='/settings'
                    />
                    <div className={styles.dividerWrapper}>
                        <Divider />
                    </div>
                    <Tab
                        icon={<HiLogout />}
                        label='Logout'
                        onClick={() => {
                            disable();
                            logout();
                        }}
                    />
                </ul>
            </div>
            <div
                className={classNames(styles.overlay, show && styles.show)}
                onClick={() => handler(false)}
            />
        </>
    );
};
