import classNames from 'classnames';
import { Button } from 'components/Button';
import styles from 'styles/AdminModal.module.css';

export const Header = ({ err, success }) => {
    return (
        <>
            <h2 className={styles.basicHeading}>Add Vehicle</h2>
            {success && (
                <span className={classNames('success', styles.message)}>
                    Successfully created!
                </span>
            )}
            {err && <span className='err'>{err}</span>}
        </>
    );
};

export const Actions = ({ handler, reset, loading }) => {
    return (
        <>
            <Button
                uitype='ghost'
                size='md'
                onClick={() => {
                    handler(false);
                    reset();
                }}
            >
                Cancel
            </Button>
            <Button
                form='vehicle-create-form'
                uitype='cta'
                color='primary-dark'
                size='md'
                isLoading={loading}
            >
                Add Vehicle
            </Button>
        </>
    );
};
