import { Controller } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import styles from 'styles/Form.module.css';

/* styles for react-select */
const customStyles = (changed, id) => {
    return {
        container: (provided) => ({
            ...provided,
            width: '100%',
            minWidth: 215,
            fontSize: 'var(--text-base)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--primary-500)'
                : 'transparent',
            fontSize: 'var(--text-sm)',
            '&:hover': {
                backgroundColor: state.isSelected
                    ? 'var(--primary-500)'
                    : 'var(--primary-400)',
            },
        }),
        control: (provided) => ({
            ...provided,
            borderWidth: 2,
            borderRadius: 'var(--radius)',
            borderStyle: 'solid',
            transition: 'none',
            borderColor: 'var(--secondary-darker)',
            '&:hover': {
                borderColor: 'var(--secondary-darker)',
            },
            boxShadow: 'none',
            fontWeight: changed && changed[id] ? 600 : 500,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '0.1rem 0 0.1rem 0.25rem',
            borderRadius: 'var(--radius-round)',
            backgroundColor: 'var(--secondary-dark)',
            fontWeight: 600,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color:
                changed && changed[id]
                    ? 'var(--primary-800)'
                    : 'var(--grey-600)',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            padding: '0.3125rem',
            borderRadius: 'var(--radius-round)',
            color: 'var(--primary-800)',
            '&:hover': {
                backgroundColor: 'var(--everest-red-light)',
                color: 'var(--everest-red)',
                cursor: 'pointer',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 51, // to show above clear icon
        }),
    };
};

// copied over from Automail and modified value of value param in Select component
export const GroupedDropdownInput = ({
    name,
    label,
    control,
    required = false,
    defaultValue,
    groupedOptions,
    changed = false,
    setChanged = (prev) => {},
    resetOnChange = () => {},
    onChangeCallback,
    onClick = () => {},
    multiselect = false,
    type = undefined,
}) => {
    const flattenedOptions = groupedOptions.map((g) => g.options).flat(1);
    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            rules={{ required: required && '⚠ This field is required' }}
            render={({
                field: { onChange, value, ref },
                formState: { errors },
            }) => (
                <div
                    className={classNames(
                        styles.dropdownWrapper,
                        errors && errors[name] && styles.error,
                        type === 'settings' && styles.settingsInput
                    )}
                    onClick={onClick}
                >
                    <Select
                        isMulti={multiselect}
                        defaultValue={defaultValue}
                        ref={ref}
                        options={groupedOptions}
                        // value={flattenedOptions.find((c) => c.value === value)}
                        value={flattenedOptions.filter((option) =>
                            value?.includes(option.value)
                        )}
                        onChange={(e) => {
                            onChange(
                                !multiselect
                                    ? e?.value
                                    : e?.map((obj) => obj?.value)
                            );
                            if (changed) {
                                setChanged((prev) => ({
                                    ...prev,
                                    [name]: true,
                                }));
                            }
                            if (resetOnChange) {
                                resetOnChange();
                            }
                            if (onChangeCallback) {
                                onChangeCallback();
                            }
                        }}
                        styles={customStyles(changed, name)}
                        isClearable={defaultValue?.length !== 0}
                        isSearchable={true}
                    />
                </div>
            )}
        />
    );
};
