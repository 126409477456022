import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import styles from 'styles/Form.module.css';

/* styles for react-select */
const customStyles = (changed, id) => {
    return {
        container: (provided) => ({
            ...provided,
            width: '100%',
            fontSize: 'var(--text-base)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--primary-500)'
                : 'transparent',
            fontSize: 'var(--text-sm)',
            '&:hover': {
                backgroundColor: state.isSelected
                    ? 'var(--primary-500)'
                    : 'var(--primary-400)',
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderWidth: 2,
            borderStyle: 'solid',
            transition: 'none',
            borderColor: state.isFocused
                ? 'var(--primary-500)'
                : 'var(--grey-600)',
            '&:hover': {
                borderColor: state.isFocused
                    ? 'var(--primary-500)'
                    : 'var(--grey-600)',
            },
            boxShadow: 'none',
            fontWeight: changed && changed[id] ? 600 : 500,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.5rem',
            borderRadius: 'var(--radius-round)',
            backgroundColor:
                changed && changed[id]
                    ? 'var(--secondary-darker)'
                    : 'var(--secondary-dark)',
            fontWeight: changed && changed[id] ? 700 : 600,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color:
                changed && changed[id]
                    ? 'var(--primary-800)'
                    : 'var(--grey-600)',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            padding: '0.5rem',
            borderRadius: 'var(--radius-round)',
            color: 'var(--primary-800)',
            '&:hover': {
                backgroundColor: 'var(--everest-red-light)',
                color: 'var(--everest-red)',
                cursor: 'pointer',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 51, // to show above clear icon
        }),
    };
};

export const HorizontalSelect = ({
    id,
    label,
    subtitle,
    control,
    required,
    errors,
    defaultValue,
    options,
    changed,
    setChanged,
    resetOnChange,
    overrideStyles,
    className,
}) => {
    return (
        <div className={classNames(className, styles.horizontalWrapper)}>
            <div className={styles.horizontalInputWrapper}>
                {label && (
                    <div className={styles.label}>
                        <span>{label}</span>
                        {subtitle && (
                            <span className={styles.subtitle}>{subtitle}</span>
                        )}
                    </div>
                )}
                <Controller
                    control={control}
                    defaultValue={options.find((c) => c.value === defaultValue)}
                    name={id}
                    rules={{
                        required: required && '⚠ This field is required',
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            isSearchable={true}
                            defaultValue={options.find(
                                (c) => c.value === defaultValue
                            )}
                            inputRef={ref}
                            options={options}
                            value={options.find((c) => c.value === value)}
                            onChange={(e) => {
                                onChange(e.value);
                                if (changed) {
                                    setChanged((prev) => ({
                                        ...prev,
                                        [id]: true,
                                    }));
                                }
                                if (resetOnChange) {
                                    resetOnChange();
                                }
                            }}
                            styles={
                                overrideStyles
                                    ? overrideStyles(changed, id)
                                    : customStyles(changed, id)
                            }
                        />
                    )}
                />
            </div>
            <ErrorMessage
                errors={errors}
                name={id}
                as='span'
                className={classNames('err', styles.horizontalErrMessage)}
            />
        </div>
    );
};

export const HorizontalMultiSelect = ({
    id,
    label,
    subtitle,
    control,
    required,
    errors,
    defaultValue,
    options,
    changed,
    setChanged,
    resetOnChange,
}) => {
    return (
        <div className={styles.horizontalWrapper}>
            <div className={styles.horizontalInputWrapper}>
                <div className={styles.label}>
                    <span>{label}</span>
                    {subtitle && (
                        <span className={styles.subtitle}>{subtitle}</span>
                    )}
                </div>
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    name={id}
                    rules={{
                        required: required && '⚠ This field is required',
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            isSearchable={true}
                            isClearable={true}
                            isMulti
                            defaultValue={defaultValue}
                            inputRef={ref}
                            options={options}
                            value={value}
                            onChange={(val) => {
                                onChange(val);
                                if (changed && setChanged) {
                                    setChanged((prev) => ({
                                        ...prev,
                                        [id]: true,
                                    }));
                                }
                                if (resetOnChange) {
                                    resetOnChange();
                                }
                            }}
                            styles={customStyles(changed, id)}
                        />
                    )}
                />
            </div>
            <ErrorMessage
                errors={errors}
                name={id}
                as='span'
                className={classNames('err', styles.horizontalErrMessage)}
            />
        </div>
    );
};
