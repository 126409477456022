import React, { useState, useEffect, forwardRef } from 'react';
// import { HiExternalLink } from 'react-icons/hi';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { useUser } from 'components/Accounts';
import {
    Options,
    OrgUsageChart,
    UserUsageChart,
    FilterButton,
    Toggle,
} from './components';
import { UsageHeaderSkeleton, ChartSkeleton } from './Skeletons';
import { UnavailableText } from './UnavailableText';
import { getFilteredByDate, getUsers } from './helpers';
import { CSVDownloader } from './components/Export/CSVDownloader';
import styles from 'styles/Revenue360.module.css';

export const UsageChartCard = forwardRef(
    (
        {
            orgUsageData,
            userUsageData,
            years,
            dateSpan,
            setDateSpan,
            usageView,
            setUsageView,
            visible,
            setVisible,
            loading,
            setSelected,
            setOpenExportModal,
            printable,
            setPrintable,
            orientation,
            referenced,
        },
        ref
    ) => {
        const { user } = useUser();
        const company = user?.company;
        const [filtered, setFiltered] = useState(undefined);
        const [openDropdown, setOpenDropdown] = useState();
        const views = ['Individual', 'Overall'];
        const [downloadData, setDownloadData] = useState([]);
        const [filenameDate, setFilenameDate] = useState('');

        // const handleClick = () => {
        //     setSelected({
        //         smartbidUsage: true,
        //         ratesComparison: false,
        //         loadsComparison: false
        //     });
        //     setPrintable(true);
        //     setOpenExportModal(true);
        // }
        /**
         * downloads a CSV file for currently selected data in SmartBid Usage Trend chart
         */
        const getCSVData = () => {
            if (filtered === undefined) return;
            let currentData;
            const output = [{}]; //useCSVDownloader accepts an array of objects, single row export should be in one object
            if (usageView === 'Overall') {
                //saves data in one object/row labelled 'Overall' if individuals aren't selected
                output[0]['Uses Per Day'] = 'Overall';
                currentData = filtered[0];
                currentData.labels.map(
                    (el, dex) =>
                        (output[0][el] = currentData.datasets[0].data[dex])
                );
            } else {
                output.pop();
                currentData = filtered[1];
                for (const ind of currentData.datasets) {
                    const tempOutput = {};
                    tempOutput['Uses Per Day by'] = ind.label;
                    currentData.labels.map(
                        (el, dex) => (tempOutput[el] = ind.data[dex])
                    );
                    output.push(tempOutput);
                }
            }
            setFilenameDate(
                `${currentData.labels[0]} to ${
                    currentData.labels[currentData.labels.length - 1]
                }`
            );
            setDownloadData(output);
        };

        const Header = () => (
            <div
                className={classNames(
                    !printable && styles.actionsWrapper,
                    printable && styles.printActionsWrapper
                )}
            >
                <div
                    className={classNames(
                        !printable && styles.optionsWrapper,
                        printable && styles.printOptionsWrapper
                    )}
                >
                    <Options
                        dateSpan={dateSpan}
                        setDateSpan={setDateSpan}
                        custom
                        loading={loading}
                        data={[orgUsageData, userUsageData]}
                        setFiltered={setFiltered}
                        referenced={referenced}
                    />
                    <div
                        className={classNames(
                            !printable && styles.optionsRowWrapper,
                            printable && styles.printOptionsRowWrapper
                        )}
                    >
                        <Toggle
                            views={views}
                            currentView={usageView}
                            setCurrentView={setUsageView}
                        />
                        {usageView === views[0] &&
                            !referenced &&
                            !printable && (
                                <FilterButton
                                    data={filtered[1]}
                                    visible={visible}
                                    setVisible={setVisible}
                                    openDropdown={openDropdown}
                                    setOpenDropdown={setOpenDropdown}
                                />
                            )}
                    </div>
                    <CSVDownloader
                        data={downloadData}
                        filename={`SmartBid Usage from ${filenameDate}`}
                        innerText={'Export to CSV'}
                    ></CSVDownloader>
                </div>
                {/* { !printable &&
                <Button 
                    uitype='cta' 
                    color='primary' 
                    size='md' 
                    onClick={handleClick}  
                >
                    <div className={styles.exportIcon}><HiExternalLink /></div>
                    <span>Export</span>
                </Button>
            } */}
            </div>
        );

        const Charts = () => (
            <>
                {usageView === views[0] &&
                    (!visible ? (
                        <ChartSkeleton />
                    ) : (
                        <UserUsageChart
                            data={filtered[1]}
                            visible={visible}
                            years={years}
                            printable={printable}
                            orientation={orientation}
                        />
                    ))}
                {usageView === views[1] && (
                    <OrgUsageChart
                        data={filtered[0]}
                        years={years}
                        printable={printable}
                        orientation={orientation}
                    />
                )}
            </>
        );
        //Each time filtered changes which happens on tab switch or page load, this pushes filtered's data to csv ready variables
        useEffect(() => {
            getCSVData();
        }, [filtered]);

        useEffect(() => {
            if (
                !loading &&
                orgUsageData.labels.length > 0 &&
                userUsageData.labels.length > 0
            ) {
                if (company === 'Demo') {
                    for (let i = 0; i < userUsageData.datasets.length; i++) {
                        userUsageData.datasets[i].label = `User #${i + 1}`;
                    }
                }
                const filtered_data = getFilteredByDate(dateSpan, [
                    orgUsageData,
                    userUsageData,
                ]);
                setFiltered(filtered_data);
                if (setVisible) {
                    setVisible(getUsers(filtered_data[1]));
                }
            } else if (!loading) {
                setFiltered(undefined);
            }
        }, [
            orgUsageData,
            userUsageData,
            loading,
            dateSpan,
            setVisible,
            usageView,
        ]);

        return (
            <div
                className={classNames(
                    !printable && styles.chartCard,
                    printable && styles.printChartCard
                )}
                ref={ref}
            >
                <div
                    className={classNames(
                        !printable && styles.header,
                        printable && styles.printHeader
                    )}
                >
                    <h1 className={styles.heading}>SmartBid Usage Trend</h1>
                    {loading ? (
                        <UsageHeaderSkeleton />
                    ) : (
                        filtered &&
                        !(
                            orgUsageData.labels.length === 0 &&
                            userUsageData.labels.length === 0
                        ) && <Header />
                    )}
                </div>
                {loading ? (
                    <ChartSkeleton />
                ) : filtered ? (
                    <Charts />
                ) : (
                    orgUsageData.labels.length === 0 &&
                    userUsageData.labels.length === 0 && <UnavailableText />
                )}
            </div>
        );
    }
);
