import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import classNames from 'classnames';
import { usageChartOptions } from './options';
import styles from 'styles/Revenue360.module.css';

export const UserUsageChart = ({ data, visible, printable, orientation }) => {
    const id = 'user-usage-chart';
    const options = usageChartOptions(id);

    const filterByUser = useMemo(() => {
        return {
            datasets: data.datasets.filter((obj) =>
                visible.includes(obj.label)
            ),
            labels: data.labels,
        };
    }, [data, visible]);

    return (
        <div
            className={classNames(
                !printable && styles.chartContainer,
                printable &&
                    orientation === 'landscape' &&
                    styles.landscapeChartContainer,
                printable &&
                    orientation === 'portrait' &&
                    styles.portraitChartContainer
            )}
            onMouseLeave={() => {
                const chart = Chart.getChart(id);
                options.plugins.annotation.annotations[0].value = -1;
                options.plugins.annotation.annotations[0].endValue = -1;
                chart.update();
            }}
        >
            <Line id={id} height={80} data={filterByUser} options={options} />
        </div>
    );
};
