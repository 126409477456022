import classNames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import styles from 'styles/Form.module.css';

export const Input = ({
    type,
    label,
    placeholder,
    value,
    ref,
    register,
    required,
    validate,
    errors,
    watch,
    autoComplete,
    onChange,
    onBlur,
    onKeyDown,
    startIcon,
    endIcon,
    hideEndIcon,
    size,
    inverseColors,
    narrow,
    textBottomMargin,
    additionalProps,
    className,
    formStyling,
    disabled,
    hideErrorMessage = false,
}) => {
    const watchInput = watch();

    return (
        <div className={classNames(styles.wrapper, narrow && styles.narrow)}>
            <div
                className={classNames(
                    styles.input,
                    startIcon && styles.hasIcons,
                    size === 'sm' && styles.small,
                    size === 'md' && styles.medium,
                    inverseColors && styles.inverseColors,
                    narrow && styles.narrow,
                    textBottomMargin && styles.textBottomMargin,
                    formStyling && styles.formStyling,
                    className
                )}
            >
                {startIcon && <div className={styles.icon}>{startIcon}</div>}
                <label
                    htmlFor={label}
                    className={classNames(
                        (value ||
                            (typeof watchInput[label] === 'string' &&
                                watchInput[label].trim() !== '')) &&
                            styles.active
                    )}
                >
                    {placeholder}
                </label>
                <input
                    type={type}
                    name={label}
                    id={label}
                    value={value}
                    ref={ref ? ref : register}
                    {...register(label, {
                        required: required && '⚠ This field is required',
                        validate: validate,
                        onChange: onChange ? (e) => onChange(e) : () => {},
                        onBlur: onBlur ? (e) => onBlur(e) : () => {},
                    })}
                    autoComplete={autoComplete ? autoComplete : 'on'}
                    onKeyDown={onKeyDown ? (e) => onKeyDown(e) : () => {}}
                    disabled={disabled}
                    {...additionalProps}
                />
                {endIcon &&
                    (!hideEndIcon ||
                        (hideEndIcon &&
                            typeof watchInput[label] === 'string' &&
                            watchInput[label].trim() !== '')) && (
                        <div className={styles.icon}>{endIcon}</div>
                    )}
            </div>
            {!hideErrorMessage && (
                <ErrorMessage
                    errors={errors}
                    name={label}
                    as='p'
                    className={classNames('err', styles.errMessage)}
                />
            )}
        </div>
    );
};

export * from './HorizontalInput';
export * from './AutocompleteInput';
export * from './HorizontalAutocompleteInput';
export * from './GroupedDropdownInput';
export * from './HorizontalGroupedDropdownInput';
