import { smartbidServer } from 'lib/api';

export const login = async (payload) => {
    const { email, password } = payload;
    let result = {
        data: undefined,
        isError: false,
        message: '',
        token: undefined,
    };
    let status = null;

    await smartbidServer.post('/api/auth/login', { username: email, password })
        .then((res) => {
            const data = res.data;
            result.token = data.token;
        })
        .catch((err) => {
            result.isError = true;
            if (err.response) {
                status = err.response.status;
                result.message = err.response.data.message;
            }
        });

    smartbidServer.post('/api/auth/history', {
            event_type: 'login',
            user_email: email,
            is_success: !result.isError,
            error_code: status,
            error_message: result.isError ? result.message : null,
        })
        .then(() => {})
        .catch((err) => console.error(err.message));

    let verify_res = await verify({ token: result.token });
    if (verify_res.isError) {
        result = { ...verify_res, data: undefined };
    } else {
        result.data = verify_res.data;
    }

    return result;
};

export const logout = ({ email }) => {
    smartbidServer.post('/api/auth/history', {
            event_type: 'logout',
            user_email: email,
            is_success: true,
            error_code: null,
            error_message: null,
        })
        .then(() => {})
        .catch((err) => console.error(err.message));

    if (typeof window !== 'undefined') {
        localStorage.clear();
    }
};

export const verify = async (payload) => {
    const { token } = payload;
    let result = {
        data: undefined,
        isError: false,
        message: '',
        token: undefined,
    };

    if (token) {
        result.token = token;
        await smartbidServer.post('/api/auth/token', { token })
            .then((res) => {
                const data = res.data;
                if (data.email) {
                    delete data.iat;
                    result.data = { user: data, token };
                }
            })
            .catch((err) => {
                result.isError = true;
                if (err.response) {
                    result.message = err.response.data.message;
                }
            });
    } else {
        result.isError = true;
        result.message = '⚠ Login failed: Invalid email or password.';
    }

    return result;
};
