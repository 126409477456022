import { useUser } from 'components/Accounts';
import styles from 'styles/Avatar.module.css';

export const Avatar = ({ onClick }) => {
    const { user } = useUser();

    return (
        <div className={styles.avatar} onClick={onClick}>
            {user?.name && <>{user.name.substr(0, 1)}</>}
        </div>
    );
};
