import classNames from 'classnames';
import { HorizontalSelect } from 'components/Form';
import { EMISSIONS_FACTOR } from 'pages/OptiRates';
import styles from 'styles/OptiRates.module.css';

export const FormattingCopy = () => (
    <>
        <p className={styles.moduleCopy}>
            <b>Formatting for each required column includes:</b>
        </p>
        <ul className={styles.moduleList}>
            <li>
                <b>Origin</b> and <b>Destination</b> must be a "[City], [State]"
                and/or "[Zip]"
                <ul>
                    <li>
                        <b className={styles.semibold}>Examples:</b> "New York,
                        NY 10001," "10001," "New York, NY"
                    </li>
                </ul>
            </li>
            <li>
                <b>Vehicle Class</b> must be "Sprinter/Cargo Van," "Small
                Straight," "Large Straight," or "Straight Truck"
                <ul>
                    <li>
                        The CSV reader will try to match the vehicle class to
                        one of the above categories if the value doesn&apos;t
                        match exactly
                    </li>
                    <li>
                        Unrecognized values{' '}
                        <b className={styles.semibold}>
                            will be replaced by "Other" which will have "Spot
                            Quote" as the rate
                        </b>
                    </li>
                </ul>
            </li>
        </ul>
    </>
);

export const CO2EmissionCopy = () => (
    <>
        <div className={styles.moduleCopy}>
            <b>
                CO<sub>2</sub> emission
            </b>{' '}
            is calculated using this formula{' '}
            <a
                href='https://business.edf.org/insights/green-freight-math-how-to-calculate-emissions-for-a-truck-move/'
                target='_blank'
                rel='noreferrer noopener'
            >
                (sourced from the Environment Defense Fund&apos;s website)
            </a>
            : <br />
            <div className={styles.formula}>
                <b>Distance (mile)</b> * <b>Weight (ton)</b> *{' '}
                <b>Emissions Factor (gram per ton-mile)</b>
            </div>
        </div>
        <p className={styles.moduleCopy}>
            <b className={styles.semibold}>Original weight</b> is converted from
            lb to ton (2000 lb = 1 ton) for the above calculation.{' '}
            <b className={styles.semibold}>
                If no weight is provided, we assume the value as 1 ton.
            </b>
        </p>
        <p className={styles.moduleCopy}>
            <b className={styles.semibold}>Emissions factor</b> for each
            vehicle:
        </p>
        <ul className={styles.moduleList}>
            <li>
                Grams per ton mile:
                <ul>
                    <li>
                        Sprinter/Cargo Van = {EMISSIONS_FACTOR['cargo_vans']}
                    </li>
                    <li>
                        Small Straight = {EMISSIONS_FACTOR['small_straights']}
                    </li>
                    <li>
                        Large Straight = {EMISSIONS_FACTOR['large_straights']}
                    </li>
                    <li>Straight Truck = {EMISSIONS_FACTOR['straights']}</li>
                </ul>
            </li>
            <li>
                Grams per mile (where weight is not considered and will be
                excluded from the above formula):
                <ul>
                    <li>Tractor Trailer = {EMISSIONS_FACTOR['trailers']}</li>
                    <li>Refrigerated = {EMISSIONS_FACTOR['refrigerated']}</li>
                    <li>Flatbed = {EMISSIONS_FACTOR['flatbed']}</li>
                </ul>
            </li>
        </ul>
    </>
);

const customStyles = () => {
    return {
        container: (provided) => ({
            ...provided,
            width: '100%',
            fontSize: 'var(--text-sm)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--primary-500)'
                : 'transparent',
            fontSize: 'var(--text-sm)',
            '&:hover': {
                backgroundColor: state.isSelected
                    ? 'var(--primary-500)'
                    : 'var(--primary-400)',
            },
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: '0',
            borderWidth: 1,
            borderStyle: 'solid',
            transition: 'none',
            borderRadius: '5px',
            borderColor: state.isFocused
                ? 'var(--primary-500)'
                : 'var(--secondary-darker)',
            '&:hover': {
                borderColor: state.isFocused
                    ? 'var(--primary-500)'
                    : 'var(--secondary-darker)',
            },
            boxShadow: 'none',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0',
            paddingLeft: '0.25rem',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0',
            padding: '0',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            padding: '0.25rem',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
    };
};

const VEHICLE_CLASS_OPTIONS = [
    'Sprinter/Cargo Van',
    'Small Straight',
    'Large Straight',
    'Straight Truck',
    'Other',
];

export const VehicleClassDropdown = ({ id, control, errors, className }) => {
    return (
        <HorizontalSelect
            id={id}
            control={control}
            errors={errors}
            options={VEHICLE_CLASS_OPTIONS.reduce((acc, curr) => {
                acc.push({ value: curr, label: curr });
                return acc;
            }, [])}
            overrideStyles={customStyles}
            className={classNames(className, styles.fullWidth)}
        />
    );
};
