import classNames from 'classnames';
import { HiChevronDoubleRight } from 'react-icons/hi';
import { CustomDateWindow } from './CustomDateWindow';
import styles from 'styles/Revenue360.module.css';
import customDateStyles from 'styles/CustomDateWindow.module.css';

export const CustomOption = ({
    data,
    dateSpan,
    setFiltered,
    setDateSpan,
    openCustom,
    setOpenCustom,
    error,
    setError,
    closeAll,
}) => {
    return (
        <span className={customDateStyles.customOptionWrapper}>
            <li
                tabIndex='0'
                className={classNames(
                    styles.dropdownOption,
                    customDateStyles.customOption,
                    dateSpan.includes('Custom') && customDateStyles.selected
                )}
                onClick={() => setOpenCustom(true)}
                onKeyDown={(e) => {
                    switch (e.key) {
                        case 'Enter':
                            e.preventDefault();
                            setOpenCustom(true);
                            break;
                        case 'Escape':
                            e.preventDefault();
                            closeAll();
                            break;
                        default:
                            break;
                    }
                }}
            >
                <span>Custom</span>
                <div>
                    <HiChevronDoubleRight />
                </div>
            </li>
            <CustomDateWindow
                data={data}
                setFiltered={setFiltered}
                dateSpan={dateSpan}
                setDateSpan={setDateSpan}
                openCustom={openCustom}
                setOpenCustom={setOpenCustom}
                error={error}
                setError={setError}
                closeAll={closeAll}
            />
        </span>
    );
};
