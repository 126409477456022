import { Controller } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import styles from 'styles/Form.module.css';
import { ErrorMessage } from '@hookform/error-message';

/* styles for react-select */
const customStyles = (changed, id) => {
    return {
        container: (provided) => ({
            ...provided,
            width: '100%',
            fontSize: 'var(--text-base)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--primary-500)'
                : 'transparent',
            fontSize: 'var(--text-sm)',
            '&:hover': {
                backgroundColor: state.isSelected
                    ? 'var(--primary-500)'
                    : 'var(--primary-400)',
            },
        }),
        control: (provided, state) => ({
            ...provided,
            borderWidth: 2,
            borderStyle: 'solid',
            transition: 'none',
            borderColor: state.isFocused
                ? 'var(--primary-500)'
                : 'var(--grey-600)',
            '&:hover': {
                borderColor: state.isFocused
                    ? 'var(--primary-500)'
                    : 'var(--grey-600)',
            },
            boxShadow: 'none',
            fontWeight: changed && changed[id] ? 600 : 500,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            transition: 'none',
            '&:hover': {
                cursor: 'pointer',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '0.1rem 0 0.1rem 0.25rem',
            borderRadius: 'var(--radius-round)',
            backgroundColor: 'var(--secondary-dark)',
            fontWeight: 600,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color:
                changed && changed[id]
                    ? 'var(--primary-800)'
                    : 'var(--grey-600)',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            padding: '0.3125rem',
            borderRadius: 'var(--radius-round)',
            color: 'var(--primary-800)',
            '&:hover': {
                backgroundColor: 'var(--everest-red-light)',
                color: 'var(--everest-red)',
                cursor: 'pointer',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 51, // to show above clear icon
        }),
    };
};

export const HorizontalGroupedDropdownInput = ({
    id,
    subtitle,
    className,
    errors,
    name,
    label,
    control,
    required = false,
    defaultValue,
    groupedOptions,
    changed = false,
    setChanged = (prev) => {},
    resetOnChange = () => {},
    onChangeCallback,
    onClick = () => {},
    multiselect = false,
    type = undefined,
}) => {
    const flattenedOptions = groupedOptions.map((g) => g.options).flat(1);
    return (
        <div className={classNames(className, styles.horizontalWrapper)}>
            <div className={styles.horizontalInputWrapper}>
                {label && (
                    <div className={styles.label}>
                        <span>{label}</span>
                        {subtitle && (
                            <span className={styles.subtitle}>{subtitle}</span>
                        )}
                    </div>
                )}
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    name={name}
                    rules={{
                        required: required && '⚠ This field is required',
                    }}
                    render={({
                        field: { onChange, value, ref },
                        formState: { errors },
                    }) => (
                        <div style={{ width: '100%' }} onClick={onClick}>
                            <Select
                                isMulti={multiselect}
                                defaultValue={defaultValue}
                                ref={ref}
                                options={groupedOptions}
                                // value={flattenedOptions.find((c) => c.value === value)}
                                value={flattenedOptions.filter((option) =>
                                    value?.includes(option.value)
                                )}
                                onChange={(e) => {
                                    onChange(
                                        !multiselect
                                            ? e?.value
                                            : e?.map((obj) => obj?.value)
                                    );
                                    if (changed) {
                                        setChanged((prev) => ({
                                            ...prev,
                                            [name]: true,
                                        }));
                                    }
                                    if (resetOnChange) {
                                        resetOnChange();
                                    }
                                    if (onChangeCallback) {
                                        onChangeCallback();
                                    }
                                }}
                                styles={customStyles(changed, name)}
                                isClearable={defaultValue?.length !== 0}
                                isSearchable={true}
                            />
                        </div>
                    )}
                />
            </div>
            <ErrorMessage
                errors={errors}
                name={id}
                as='span'
                className={classNames('err', styles.horizontalErrMessage)}
            />
        </div>
    );
};
