import { Card } from 'components/Card';
import { DropdownButton, Toggle } from './components';
import { Line } from 'react-chartjs-2';
import styles from 'styles/Revenue360.module.css';
import { formatDateString, getRandNum, getBackdate } from './helpers';
import { useUser } from 'components/Accounts';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import { UnavailableText } from './UnavailableText';

const formatNum = (num, company) => {
    let adjustedNum = num;
    if (company === 'Demo') {
        const randNum = Math.floor(getRandNum(-1, 1));
        adjustedNum = parseInt(num) + randNum;
    }
    return adjustedNum !== 0 ? adjustedNum : null;
};

const DateDisplay = ({ dateSpan, loading }) => {
    if (loading) {
        return null;
    }
    switch (dateSpan) {
        case 'Last 30 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(30, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        case 'Last 60 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(60, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        case 'Last 180 Days':
            return (
                <p>{`${dateSpan} (${getBackdate(180, 'short')} — ${getBackdate(
                    0,
                    'short'
                )})`}</p>
            );
        default:
            return <p>{dateSpan}</p>;
    }
};

const filterData = (data, dateSpan) => {
    if (!data || data.length === 0) {
        return [];
    }

    let startDate = new Date(getBackdate(31));
    switch (dateSpan) {
        case 'Last 7 Days':
            startDate = new Date(getBackdate(8));
            break;
        case 'Last 15 Days':
            startDate = new Date(getBackdate(16));
            break;
        case 'Last 30 Days':
            startDate = new Date(getBackdate(31));
            break;
        case 'Last Month':
            startDate = new Date(getBackdate(31));
            break;
        case 'Last 60 Days':
            startDate = new Date(getBackdate(61));
            break;
        case 'Last 180 Days':
            startDate = new Date(getBackdate(181));
            break;
        default:
            startDate = new Date(getBackdate(0));
            break;
    }

    return data.filter(({ date }) => new Date(date) >= startDate);
};

export const CompletedLoadsChartCard = ({
    completedLoadsData,
    loading,
    dateSpan,
    setDateSpan,
    completedLoadsView,
    setCompletedLoadsView,
    smartbid,
}) => {
    const { user } = useUser();
    const company = user?.company;

    const filteredData = filterData(completedLoadsData, dateSpan);

    const data = {
        labels: filteredData?.map((obj) =>
            smartbid
                ? new Date(obj.date).toLocaleDateString('en-US', {
                      weekday: undefined,
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                  })
                : formatDateString(obj.date)
        ),
        datasets: smartbid
            ? [
                  {
                      label: company,
                      data: filteredData?.map((obj) =>
                          formatNum(obj.load_count_customer, company)
                      ),
                      borderColor: `${window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--primary-500')}`,
                      backgroundColor: `${window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--primary-500-transparent')}`,
                      radius: 6,
                      hoverRadius: 8,
                      showLine: false,
                  },
              ]
            : [
                  {
                      label: 'Sprinter/Cargo Vans',
                      data: filteredData?.map((obj) =>
                          formatNum(obj.van, company)
                      ),
                      borderColor: window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--graph-light-blue'),
                      backgroundColor: window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--graph-light-blue-transparent'),
                  },
                  {
                      label: 'Sm Straight Trucks',
                      data: filteredData?.map((obj) =>
                          formatNum(obj.small_straight, company)
                      ),
                      borderColor: window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--graph-orange'),
                      backgroundColor: window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--graph-orange-transparent'),
                  },
                  {
                      label: 'Lg Straight Trucks',
                      data: filteredData?.map((obj) =>
                          formatNum(obj.large_straight, company)
                      ),
                      borderColor: window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--graph-green'),
                      backgroundColor: window
                          .getComputedStyle(document.getElementById('root'))
                          .getPropertyValue('--graph-green-transparent'),
                  },
              ],
    };

    const options = {
        maintainAspectRatio: smartbid ? false : true,
        plugins: {
            legend: { display: smartbid ? false : true },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Date',
                    font: {
                        family: 'Open Sans',
                        size: 14,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
                ticks: {
                    callback: function (value) {
                        const date = this.getLabelForValue(value);
                        const formatted = format(new Date(date), 'E, MMM d');
                        return formatted;
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: '# of Loads',
                    font: {
                        family: 'Open Sans',
                        size: 14,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
                min: 0,
                ticks: {
                    callback: function (value) {
                        return value % 1 > 0 ? null : value;
                    },
                },
            },
        },
    };

    return (
        <Card fullWidth fullHeight={!smartbid} smallPadding smartbid={smartbid}>
            <div className={styles.header}>
                <h1 className={styles.heading}>Completed Loads</h1>
                {smartbid && (
                    <>
                        <DropdownButton
                            value={dateSpan}
                            setValue={setDateSpan}
                            options={[
                                'Last 7 Days',
                                'Last 15 Days',
                                'Last Month',
                            ]}
                            color='primary'
                            tight
                        />
                        <Toggle
                            views={[
                                'Sprinter/Cargo Vans',
                                'Straight Trucks',
                                'Combined',
                            ]}
                            currentView={completedLoadsView}
                            setCurrentView={setCompletedLoadsView}
                        />
                    </>
                )}
            </div>
            {!smartbid && <DateDisplay dateSpan={dateSpan} loading={loading} />}
            {loading ? (
                <Skeleton
                    style={{ margin: '5% auto' }}
                    baseColor='var(--secondary-dark)'
                    highlightColor='var(--secondary)'
                    height={200}
                    borderRadius={5}
                />
            ) : data.labels.length !== 0 ? (
                <Line data={data} options={options} />
            ) : (
                <UnavailableText />
            )}
        </Card>
    );
};
