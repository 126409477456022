export const roles = [
    { value: 'user', label: 'user' },
    { value: 'executive', label: 'executive' },
    { value: 'admin', label: 'admin' },
    { value: 'driver', label: 'driver' },
];

export const products = [
    { value: 'sb_vans', label: 'SB Vans' },
    { value: 'sb_straights', label: 'SB Straights' },
    { value: 'sb_margin_calculator', label: 'SB Margin Calculator' },
    { value: 'sb_trailers', label: 'SB Trailers' },
    { value: 'sb_company_historical', label: 'SB Company Historical' },
    { value: 'r360_smartbid_usage', label: 'R360 SmartBid Usage' },
    { value: 'r360_van_rates', label: 'R360 Van Rates' },
    { value: 'r360_straight_rates', label: 'R360 Straight Rates' },
    { value: 'r360_opportunity_map', label: 'R360 Opportunity Map' },
    { value: 'r360_combined_rates', label: 'R360 Combined Rates' },
    { value: 'r360_van_kpi', label: 'R360 Van KPI' },
    { value: 'r360_straight_kpi', label: 'R360 Straight KPI' },
    { value: 'r360_pie', label: 'R360 Pie' },
    { value: 'r360_scatter', label: 'R360 Scatter' },
    { value: 'r360_completed_loads', label: 'R360 Completed Loads' },
];

export const r360_products = [
    'r360_smartbid_usage',
    'r360_van_rates',
    'r360_straight_rates',
    'r360_opportunity_map',
    'r360_combined_rates',
    'r360_van_kpi',
    'r360_straight_kpi',
    'r360_pie',
    'r360_scatter',
    'r360_completed_loads',
];

export const accountTypes = [
    { value: 'full', label: 'full' },
    { value: 'pilot', label: 'pilot' },
    { value: 'broker', label: 'broker' },
    { value: 'courier', label: 'courier' },
];

export const vehicle_statuses = [
    'In Service',
    'In Service Confirmed',
    'On A Load',
    'Out of Service',
];

export const vehicle_classes = [
    'Sprinter/Cargo Van',
    'Small Straight',
    'Large Straight',
    'Tractor Trailer',
    'Flatbed',
    'Refrigerated',
];
