import { useContext } from 'react';
import { UserContext, UserDispatch } from './UserProvider';
import { usePreview } from './usePreview';

export function useUser() {
    const { preview } = usePreview();
    const { user, token, status } = useContext(UserContext);
    const { login, logout } = useContext(UserDispatch);

    let adjustedUser = { ...user, ...preview };
    if (user) {
        adjustedUser.preview = Boolean(preview);
    } else {
        adjustedUser = undefined;
    }

    return { user: adjustedUser, token, status, login, logout };
}
