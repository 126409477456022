import { DashboardSkeleton } from 'components/Admin';
import { ProtectedPage } from 'components/Page';
import { Suspense, useEffect } from 'react';
import { VehicleManager } from 'components/Manage';
import { useVehicles } from 'providers/Vehicles';
import toast from 'react-hot-toast';

const VehicleManagerPage = () => {
    const { isValidating, performingAction } = useVehicles();

    useEffect(() => {
        if (performingAction) {
            toast.dismiss('sync-vehicles-notification');
        } else {
            if (isValidating) {
                toast.loading('Syncing vehicles...', {
                    id: 'sync-vehicles-notification',
                });
            } else {
                toast.success('Syncing vehicles complete!', {
                    id: 'sync-vehicles-notification',
                });
            }
        }
    }, [isValidating, performingAction]);

    return (
        <ProtectedPage
            title='Vehicle Manager'
            wide
            restrictions={['login-required']}
        >
            <Suspense fallback={<DashboardSkeleton />}>
                <VehicleManager />
            </Suspense>
        </ProtectedPage>
    );
};

export default VehicleManagerPage;
