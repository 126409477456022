import { createContext } from 'react';
import axios from 'axios';
import useSWR, { useSWRConfig } from 'swr';
import { useUser } from 'components/Accounts';
import { outputError } from 'helpers/error';
import { isValidAdmin } from 'helpers/user';

const fetcher = (url, headers) =>
    axios
        .get(url, { headers })
        .then((res) => res.data?.companies)
        .catch((err) =>
            outputError({
                err,
                source: 'client:companies:fetcher:axios_get',
            })
        );

const initialContext = {
    companies: [],
};

const initialDispatch = {
    syncOpportunities: undefined,
};

export const CompaniesContext = createContext(initialContext);
export const CompaniesDispatch = createContext(initialDispatch);

export const CompaniesProvider = ({ children }) => {
    const { user, token } = useUser();
    // add [limit] to params if needed
    const { data, isValidating } = useSWR(
        isValidAdmin(user)
            ? [
                  process.env.REACT_APP_SMARTBID_SERVER_BASE_URL +
                      `/api/companies`,
                  { 'x-access-token': token },
              ]
            : null,
        fetcher,
        {
            onErrorRetry: (
                error,
                _key,
                _config,
                revalidate,
                { retryCount }
            ) => {
                // never retry on 404.
                if (error.status === 404) return;
                // only retry up to 10 times.
                if (retryCount >= 10) return;
                // retry after 1.5 seconds.
                setTimeout(() => revalidate({ retryCount }), 1500);
            },
        }
    );
    const { mutate } = useSWRConfig();

    const syncOpportunities = async () => {
        await mutate([
            process.env.REACT_APP_SMARTBID_SERVER_BASE_URL + `/api/companies`,
            { 'everest-authorization': token },
        ]);
    };

    return (
        <CompaniesContext.Provider
            value={{ companies: data ? data : [], isValidating }}
        >
            <CompaniesDispatch.Provider
                value={{
                    syncOpportunities,
                }}
            >
                {children}
            </CompaniesDispatch.Provider>
        </CompaniesContext.Provider>
    );
};
