import { Card } from 'components/Card';
import { Line } from 'react-chartjs-2';
import styles from 'styles/Revenue360.module.css';
import { formatDateString } from './helpers';
import { UnavailableText } from './UnavailableText';

const percentChange = (first, second) => {
    if (first === null || first === 0) {
        return null;
    } else if (second === null || second === 0) {
        return null;
    }
    return ((second - first) / first) * 100;
};

const getDateAgo = (days) => {
    const now = new Date();
    const backdate = new Date(now.setDate(now.getDate() - days));
    const midnight = backdate.toISOString().slice(0, 10) + 'T05:00:00.000Z';
    return midnight;
};

export const TrendChartCard = ({ completedLoadsData }) => {
    // const lastweekdata = completedLoadsData.filter(obj => new Date(obj.date) >= new Date(getDateAgo(8)));
    const lastweekdata = completedLoadsData;

    let percentChangeData = [];
    if (lastweekdata.length !== 0) {
        let van_first = null;
        let straight_first = null;
        let van_second = null;
        let straight_second = null;

        let j = 0;
        if (getDateAgo(31) === lastweekdata?.at(0).date) {
            van_first = lastweekdata[j].van;
            straight_first = lastweekdata[j].straight;
            j++;
        }

        for (let i = 30; i > 0; i--) {
            if (
                j < lastweekdata.length &&
                getDateAgo(i) === lastweekdata[j].date
            ) {
                van_second = lastweekdata[j].van;
                straight_second = lastweekdata[j].straight;
                j++;
            } else if (j >= lastweekdata.length) {
                van_second = null;
                straight_second = null;
                j++;
            }

            percentChangeData.push({
                date: getDateAgo(i),
                van:
                    j <= lastweekdata.length
                        ? percentChange(van_first, van_second)
                        : null,
                straight:
                    j <= lastweekdata.length
                        ? percentChange(straight_first, straight_second)
                        : null,
            });

            van_first = van_second;
            straight_first = straight_second;
        }
    }

    const data = {
        labels: percentChangeData.map((obj) => formatDateString(obj.date)),
        datasets: [
            {
                label: 'Sprinter/Cargo Vans',
                data: percentChangeData.map((obj) => obj.van),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Straight Trucks',
                data: percentChangeData.map((obj) => obj.straight),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                    font: {
                        family: 'Open Sans',
                        size: 14,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
            },
            y: {
                title: {
                    display: true,
                    text: '% Change',
                    font: {
                        family: 'Open Sans',
                        size: 14,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
            },
        },
    };
    return (
        <Card fullWidth smallPadding>
            <div className={styles.header}>
                <h1 className={styles.heading}>Volume Trend</h1>
            </div>
            {completedLoadsData?.length !== 0 ? (
                <Line data={data} options={options} />
            ) : (
                <UnavailableText />
            )}
        </Card>
    );
};
