import { createContext, useReducer, useState } from 'react';
import safeStringify from 'fast-safe-stringify';
import { useAsyncEffect } from 'use-async-effect';
import { useNavigate } from 'react-router-dom';
import { smartbidServer } from 'lib/api';
import { login, logout, verify } from './auth';

const initialContext = {
    user: undefined,
    token: undefined,
    status: 'unauthenticated',
};

const initialDispatch = {
    loginDispatch: undefined,
    logoutDispatch: undefined,
};

const processUserPayload = (payload) => {
    if (payload) {
        return {
            ...payload,
            google_api_key:
                payload.google_api_key ||
                process.env.REACT_APP_EVEREST_GOOGLE_API_KEY,
        };
    }
    return payload;
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'login':
            return {
                user: processUserPayload(action.payload.user),
                token: action.payload.token,
                status: 'authenticated',
            };
        case 'logout':
            return {
                user: undefined,
                token: undefined,
                status: 'unauthenticated',
            };
        case 'verify':
            return {
                user: processUserPayload(action.payload.user),
                token: action.payload.token,
                status: 'authenticated',
            };
        case 'loading':
            return {
                ...state,
                status: 'loading',
            };
        case 'authenticating':
            return {
                ...state,
                status: 'authenticating',
            };
        case 'reset':
            return {
                user: undefined,
                token: undefined,
                status: 'unauthenticated',
            };
        default:
            throw new Error('Invalid dispatch.');
    }
};

export const UserContext = createContext(initialContext);
export const UserDispatch = createContext(initialDispatch);

export const UserProvider = ({ children }) => {
    const [mounted, setMounted] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialContext);
    const navigate = useNavigate();

    const loginUser = async ({ email, password }) => {
        dispatch({ type: 'authenticating' });
        const result = await login({ email, password });
        if (!result.isError) {
            dispatch({
                type: 'login',
                payload: {
                    user: result.data.user,
                    token: result.data.token,
                },
            });
            if (typeof window !== 'undefined') {
                localStorage.setItem(
                    'token',
                    safeStringify({ token: result.data.token })
                );
            }
            navigate('/smartbid');
        } else {
            dispatch({ type: 'reset' });
            return { message: result.message };
        }
    };

    const logoutUser = async () => {
        dispatch({ type: 'loading' });
        logout({ email: state.user.email });
        dispatch({ type: 'logout' });
        navigate('/');
    };

    const verifyUser = async ({ token }) => {
        dispatch({ type: 'authenticating' });
        const result = await verify({ token });
        if (!result.isError) {
            const session = await smartbidServer.post('/api/auth/session', {
                user: result.data.user,
            });
            const { user, token } = session.data;
            dispatch({
                type: 'verify',
                payload: { user, token },
            });
            if (typeof window !== 'undefined') {
                localStorage.setItem('token', safeStringify({ token }));
            }
        } else {
            dispatch({ type: 'reset' });
            return { message: result.message };
        }
    };

    useAsyncEffect(
        async (isActive) => {
            const verify = async () => {
                const tokenString = JSON.parse(localStorage.getItem('token'));
                const savedToken = tokenString?.token;
                if (savedToken) {
                    await verifyUser({ token: savedToken });
                    if (!isActive()) return;
                }
            };

            if (!mounted) {
                await verify();
            }

            const refresh = setInterval(
                async () => {
                    await verify();
                },
                60 * 60 * 1000
            ); // refresh token every 60 minutes

            setMounted(true);
            return () => {
                setMounted(false);
                clearInterval(refresh);
            };
        },
        [mounted]
    );

    return (
        <UserContext.Provider
            value={{
                user: state.user,
                token: state.token,
                status: state.status,
            }}
        >
            <UserDispatch.Provider
                value={{ login: loginUser, logout: logoutUser }}
            >
                {children}
            </UserDispatch.Provider>
        </UserContext.Provider>
    );
};
