import { ProtectedPage } from 'components/Page';
import { useCallback, useEffect, useState } from 'react';
import { smartbidServer } from 'lib/api';

const MarketMap = () => {
    const [iframeUrl, setIframeUrl] = useState(undefined);

    const getIframeUrl = useCallback(async () => {
        await smartbidServer
            .get('/api/smartbid/market-map')
            .then((res) => {
                setIframeUrl(res.data.iframe_url);
            })
            .catch((err) => {
                console.error(err.message);
            });
    }, []);

    useEffect(() => {
        getIframeUrl();
    }, [getIframeUrl]);

    return (
        <ProtectedPage
            title='Market Map'
            wide
            restrictions={['login-required']}
        >
            <iframe
                src={iframeUrl}
                style={{ border: 'none' }}
                height={800}
                title={`market-map-dashboard`}
            />
        </ProtectedPage>
    );
};

export default MarketMap;
