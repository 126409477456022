import styles from 'styles/Card.module.css';
import classNames from 'classnames';

export const Card = ({
    narrow,
    wide,
    fullWidth,
    fullHeight,
    minPadding,
    smallPadding,
    style,
    smartbid,
    children,
}) => {
    return (
        <div
            className={classNames(
                styles.card,
                narrow && styles.narrow,
                wide && styles.wide,
                minPadding && styles.minPadding,
                smallPadding && styles.smallPadding,
                fullWidth && styles.fullWidth,
                fullHeight && styles.fullHeight,
                smartbid && styles.smartbid
            )}
            style={style}
        >
            <div className={styles.cardContent}>{children}</div>
        </div>
    );
};
