import classNames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import styles from 'styles/Form.module.css';

export const HorizontalInput = ({
    type,
    id,
    label,
    subtitle,
    register,
    required,
    validate,
    errors,
    onChange,
    autoComplete,
    defaultValue,
    changed,
    setChanged,
    resetOnChange,
    watch,
}) => {
    const watchInput = watch ? watch(id) : undefined;

    return (
        <div className={styles.horizontalWrapper}>
            <div className={styles.horizontalInputWrapper}>
                <label htmlFor={id} className={styles.label}>
                    <span>{label}</span>
                    {subtitle && (
                        <span className={styles.subtitle}>{subtitle}</span>
                    )}
                </label>
                <input
                    type={type}
                    name={id}
                    id={id}
                    ref={register}
                    defaultValue={defaultValue}
                    {...register(id, {
                        required: required && '⚠ This field is required',
                        validate:
                            required ||
                            (typeof watchInput === 'string' &&
                                watchInput.trim() !== '')
                                ? validate
                                : () => {},
                        onChange: onChange
                            ? (e) => onChange(e)
                            : () => {
                                  if (changed) {
                                      setChanged((prev) => ({
                                          ...prev,
                                          [id]: true,
                                      }));
                                  }
                                  if (resetOnChange) {
                                      resetOnChange();
                                  }
                              },
                    })}
                    autoComplete={autoComplete ? autoComplete : 'on'}
                    className={classNames(
                        styles.horizontalInput,
                        errors[id] && styles.errInput,
                        changed && changed[id] && styles.changed
                    )}
                />
            </div>
            <ErrorMessage
                errors={errors}
                name={id}
                as='span'
                className={classNames('err', styles.horizontalErrMessage)}
            />
        </div>
    );
};
