import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { automailChartOptions } from './options';
import styles from 'styles/Revenue360.module.css';
import { formatDateString } from 'components/Revenue360/helpers';

export const AutomailUsageChart = ({ data, dateRange }) => {
    const id = 'automail-usage-chart';
    const options = automailChartOptions(id);
    const graphColorOptions = [
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-orange'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-orange-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-green'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-green-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-purple-dark'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-purple-dark-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-aqua-dark'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-aqua-dark-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-gold'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-gold-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-salmon-dark'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-salmon-dark-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-blue'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-blue-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-green-pale'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-green-pale-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-pink-light'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-pink-light-transparent'),
        ],
        [
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-red-dark'),
            window
                .getComputedStyle(document.getElementById('root'))
                .getPropertyValue('--graph-red-dark-transparent'),
        ],
    ];

    const chartData = [];
    for (let i = 0; i < data.length; i++) {
        chartData.push({
            label: data[i][0].name,
            data: data[i]?.map((obj) => Number.parseFloat(obj.loads_booked)),
            borderColor: graphColorOptions[i > 10 ? i % 10 : i][0],
            backgroundColor: graphColorOptions[i > 10 ? i % 10 : i][1],
        });
    }
    const dataRefined = {
        labels: dateRange?.map((obj) => formatDateString(+obj)),
        datasets: chartData,
    };

    return (
        <div
            className={styles.chartContainer}
            onMouseLeave={() => {
                const chart = Chart.getChart(id);
                options.plugins.annotation.annotations[0].value = -1;
                options.plugins.annotation.annotations[0].endValue = -1;
                chart.update();
            }}
        >
            <Line
                id='automail-usage-chart'
                height={80}
                data={dataRefined}
                options={options}
            />
        </div>
    );
};
