import React, { lazy, Suspense, useState } from 'react';
import { nanoid } from 'nanoid';
import { ScrollSyncNode } from 'scroll-sync-react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { HiUpload, HiPlusSm, HiPencil, HiTrash, HiEye } from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { usePreview } from 'components/Accounts';
import { Button } from 'components/Button';
import { products } from 'data';
import styles from 'styles/Admin.module.css';

const Searchbar = lazy(() =>
    import('./Searchbar').then((module) => ({ default: module.Searchbar }))
);
const Filter = lazy(() =>
    import('./Filter').then((module) => ({ default: module.Filter }))
);
const ImportModal = lazy(() =>
    import('./ImportModal').then((module) => ({ default: module.ImportModal }))
);
const ReviewModal = lazy(() =>
    import('./ImportModal').then((module) => ({ default: module.ReviewModal }))
);
const CreateModal = lazy(() =>
    import('./CreateModal').then((module) => ({ default: module.CreateModal }))
);
const UpdateModal = lazy(() =>
    import('./UpdateModal').then((module) => ({ default: module.UpdateModal }))
);
const DeleteModal = lazy(() =>
    import('./DeleteModal').then((module) => ({ default: module.DeleteModal }))
);

export const TableHeader = ({
    setSearch,
    filters,
    setFilters,
    users,
    count,
    filteredUsers,
}) => {
    const [openFilter, setOpenFilter] = useState(false);
    const [openReviewImport, setOpenReviewImport] = useState(false);
    const [openImport, setOpenImport] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [randomPassword, setRandomPassword] = useState('');
    const [parsedData, setParsedData] = useState([]);

    return (
        <Suspense
            fallback={
                <Skeleton
                    height={110}
                    borderRadius={15}
                    style={{ marginBottom: '0.25rem' }}
                />
            }
        >
            <div className={styles.tableHeader}>
                <div className={styles.heading}>
                    <div className={styles.title}>
                        <div className={styles.userTitle}>
                            <h1>Users</h1>
                            <span className={styles.count}>
                                {count} user{count !== 1 ? 's' : ''}
                            </span>
                        </div>
                        <div className={styles.filterSearchRow}>
                            <Filter
                                open={openFilter}
                                handler={setOpenFilter}
                                filters={filters}
                                setFilters={setFilters}
                                users={users}
                                filteredUserCount={filteredUsers.length}
                            />
                            <Searchbar setSearch={setSearch} />
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            uitype='cta'
                            color='primary-dark'
                            size='md'
                            onClick={() => setOpenImport(true)}
                        >
                            <HiUpload />
                            Import CSV
                        </Button>
                        <Button
                            uitype='cta'
                            color='primary-dark'
                            size='md'
                            onClick={() => {
                                setOpenCreate(true);
                                setRandomPassword(nanoid(32));
                            }}
                        >
                            <HiPlusSm />
                            Add User
                        </Button>
                    </div>
                </div>
                <ScrollSyncNode>
                    <div className={styles.scroller}>
                        <div className={styles.labels}>
                            <span>Username</span>
                            <span>Company</span>
                            <span>Role</span>
                            <span>Type</span>
                            <span>Products</span>
                        </div>
                    </div>
                </ScrollSyncNode>
            </div>
            <ReviewModal
                open={openReviewImport}
                handler={setOpenReviewImport}
                importHandler={setOpenImport}
                parsedData={parsedData}
            />
            <ImportModal
                open={openImport}
                handler={setOpenImport}
                setParsedData={setParsedData}
                reviewHandler={setOpenReviewImport}
            />
            <CreateModal
                open={openCreate}
                handler={setOpenCreate}
                randomPassword={randomPassword}
            />
        </Suspense>
    );
};

const ProductBubble = ({ name }) => {
    return <div className={styles.bubble}>{name}</div>;
};

export const UserRow = ({ user }) => {
    const {
        email,
        name,
        role,
        type,
        company,
        sb_vans,
        sb_straights,
        sb_margin_calculator,
        sb_trailers,
        sb_company_historical,
        r360_smartbid_usage,
        r360_van_rates,
        r360_straight_rates,
        r360_opportunity_map,
        r360_combined_rates,
        r360_van_kpi,
        r360_straight_kpi,
        r360_pie,
        r360_scatter,
        r360_completed_loads,
    } = user;
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const { inherit } = usePreview();

    const userProducts = {
        sb_vans,
        sb_straights,
        sb_margin_calculator,
        sb_trailers,
        sb_company_historical,
        r360_smartbid_usage,
        r360_van_rates,
        r360_straight_rates,
        r360_opportunity_map,
        r360_combined_rates,
        r360_van_kpi,
        r360_straight_kpi,
        r360_pie,
        r360_scatter,
        r360_completed_loads,
    };

    const productCount = () => {
        let count = 0;
        const productValues = Object.values(userProducts);
        for (let i = 0; i < productValues.length; i++) {
            if (productValues[i]) {
                count++;
            }
        }
        return count;
    };

    return (
        <Suspense
            fallback={
                <Skeleton
                    height={70}
                    borderRadius={15}
                    style={{ marginBottom: '0.25rem' }}
                />
            }
        >
            <div className={styles.userRow}>
                <div className={styles.username}>
                    <span>{name}</span>
                    <span>{email}</span>
                </div>
                <span>{!company ? '—' : company}</span>
                <span>{role}</span>
                <span>{type}</span>
                <span className={styles.productsWrapper}>
                    <div className={styles.productCount}>{productCount()}</div>
                    <ScrollContainer className={styles.products} id='#products'>
                        {products.map(({ value, label }) => (
                            <span key={value}>
                                {userProducts[value] && (
                                    <ProductBubble name={label} />
                                )}
                            </span>
                        ))}
                    </ScrollContainer>
                </span>
                <div className={styles.actions}>
                    <span className={styles.previewSpan}>
                        <Button
                            uitype='icon'
                            radius='round'
                            color='secondary-dark'
                            onClick={() => {
                                inherit(user);
                            }}
                        >
                            <HiEye title='View Website as User' />
                        </Button>
                    </span>
                    <Button
                        uitype='icon'
                        radius='round'
                        color='primary'
                        onClick={() => setOpenUpdate(true)}
                    >
                        <HiPencil title='Edit User' />
                    </Button>
                    <Button
                        uitype='icon'
                        radius='round'
                        color='red'
                        onClick={() => setOpenDelete(true)}
                    >
                        <HiTrash title='Delete User' />
                    </Button>
                </div>
            </div>
            <UpdateModal
                open={openUpdate}
                handler={setOpenUpdate}
                user={user}
                userProducts={userProducts}
            />
            <DeleteModal
                open={openDelete}
                handler={setOpenDelete}
                user={user}
            />
        </Suspense>
    );
};
