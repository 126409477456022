import { useState } from 'react';
import classNames from 'classnames';
import PlacesAutocomplete from 'lib/placesAutocomplete';
import styles from 'styles/Autocomplete.module.css';
import Skeleton from 'react-loading-skeleton';
import { Input } from '.';
import { Button } from 'components/Button';
import { FaCircleXmark } from 'react-icons/fa6';

const InputSkeleton = () => (
    <Skeleton
        baseColor='var(--secondary-dark)'
        highlightColor='var(--secondary)'
        width={328}
        height={45}
        borderRadius={8}
    />
);

// TODO: replace once AutocompleteWrapper has been extended
export const AutocompleteInput = ({
    id,
    label,
    value,
    setValue,
    defaultValue,
    onSelect,
    onKeyDown,
    onClear,
    onChange,
    formStyling,
    register,
    errors,
    validate,
    watch,
    required,
    hideTooltip = false,
    hideErrorMessage = false,
    overrideInputVal = false,
}) => {
    const [inputVal, setInputVal] = useState('');
    const onError = (_status, clearSuggestions) => {
        //TO DO: Add error status handling
        clearSuggestions();
    };

    let location = '';
    try {
        location = new window.google.maps.LatLng(39, -98);
        if (typeof location === 'undefined') {
            return <InputSkeleton />;
        }
    } catch {
        return <InputSkeleton />;
    }

    return (
        <PlacesAutocomplete
            key={id}
            value={
                // override to listen to value param as long as value exists
                overrideInputVal &&
                typeof value !== 'undefined' &&
                value !== null
                    ? value
                    : inputVal || value?.includes('location-')
                    ? inputVal
                    : value
            } // when input is empty, cellValue is of form `id-index` where id contains location (e.g. location-0)
            onChange={(e) => {
                if (onChange) onChange(e);
                setInputVal(e.replace(', USA', '').replace(', Canada', ''));
                setValue(id, e.replace(', USA', '').replace(', Canada', ''));
            }}
            onSelect={(e) => {
                if (onSelect) onSelect(e);
                setInputVal(e.replace(', USA', '').replace(', Canada', ''));
                setValue(id, e.replace(', USA', '').replace(', Canada', ''));
            }}
            onError={onError}
            updateOnBlur={(firstSuggestion) => {
                if (firstSuggestion) {
                    const addr = firstSuggestion.description
                        .replace(', USA', '')
                        .replace(', Canada', '');
                    setInputVal(addr);
                    setValue(id, addr);
                }
            }}
            searchOptions={{
                componentRestrictions: { country: ['us', 'ca'] },
                location: location,
                radius: 802,
                types: [
                    'locality',
                    'administrative_area_level_3',
                    'postal_code',
                ],
            }}
            googleCallbackName={id}
            highlightFirstSuggestion
        >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div
                    className={classNames(
                        styles.autocompleteWrapper,
                        formStyling
                            ? styles.horizontalInput
                            : styles.locationInput
                    )}
                >
                    <Input
                        type='text'
                        label={id}
                        // placeholder={
                        //     typeof placeholder === 'function'
                        //         ? placeholder()
                        //         : placeholder
                        // }
                        register={register}
                        required={required}
                        validate={validate}
                        errors={errors}
                        watch={watch}
                        autoComplete='off'
                        endIcon={
                            <Button
                                uitype='icon'
                                radius='round'
                                color='secondary-darker'
                                size='md'
                                tabIndex='-1'
                                onClick={(e) => {
                                    if (e.detail === 1) {
                                        setValue(id, '');
                                        setInputVal('');
                                        if (onClear) onClear();
                                    }
                                }}
                                tooltip={!hideTooltip ? 'Clear input' : null}
                            >
                                <FaCircleXmark />
                            </Button>
                        }
                        size='md'
                        additionalProps={{ ...getInputProps() }}
                        formStyling
                        hideErrorMessage={hideErrorMessage}
                    />
                    {suggestions.length > 0 && (
                        <div className={styles.suggestionDropdown}>
                            {suggestions.map((suggestion) => (
                                <div
                                    {...getSuggestionItemProps(suggestion)}
                                    key={suggestion.description}
                                    className={classNames(
                                        suggestion.active && styles.active,
                                        styles.suggestion
                                    )}
                                >
                                    <span>
                                        {suggestion.description
                                            .replace(', USA', '')
                                            .replace(', Canada', '')}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </PlacesAutocomplete>
    );
};
