import { useState } from 'react';
import { Title } from 'react-head';
import classNames from 'classnames';
import { useUser } from 'components/Accounts';
import { NavBar, SideNav } from 'components/NavBar';
import styles from 'styles/Page.module.css';

export const Page = ({
    includeBackground,
    title,
    wide,
    noPadding,
    fullPage,
    children,
    hideSideNav,
}) => {
    const [openSideNav, setOpenSideNav] = useState(false);
    const { user } = useUser();

    return (
        <>
            <NavBar
                wide={wide}
                openSideNav={openSideNav}
                setOpenSideNav={setOpenSideNav}
            />
            <main
                className={classNames(
                    styles.main,
                    includeBackground && styles.background,
                    fullPage && styles.fullPage,
                    wide && styles.wide,
                    noPadding && styles.noPadding,
                    user?.preview && styles.previewOn
                )}
                data-cy='home-pg'
            >
                <Title>{title && title + ' |'} Everest</Title>
                {/* { !hideSideNav && <SideNav open={openSideNav} handler={setOpenSideNav} /> } */}
                <SideNav open={openSideNav} handler={setOpenSideNav} />
                <div
                    className={classNames(
                        styles.container,
                        !hideSideNav && styles.hasSideNav
                    )}
                >
                    <section className={styles.section}>{children}</section>
                </div>
            </main>
        </>
    );
};
