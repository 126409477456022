import React, { useState, useEffect } from 'react';
import { AutomailUsageChart, DropdownButton } from './components';
import { ChartSkeleton } from './Skeletons';
import { UnavailableText } from './UnavailableText';
import styles from 'styles/Revenue360.module.css';

export const AutomailUsageChartCard = ({
    automailUsageData,
    automailUsageFilter,
    loading,
    automailDateSpan,
    setAutomailDateSpan,
}) => {
    const [filtered, setFiltered] = useState(undefined);
    const [dateRange, setDateRange] = useState(undefined);

    useEffect(() => {
        if (!automailUsageData || !automailUsageFilter) return;
        const tempData = {};
        const tempDateRange = {};
        for (let ele of automailUsageData) {
            const dateString = `${Date.parse(
                new Date(ele.item_created_timestamp).toDateString()
            )}`;
            tempDateRange[dateString] = true;
            if (!tempData[ele.user_id]) {
                tempData[ele.user_id] = {
                    name: automailUsageFilter.user_info[ele.user_id],
                    [dateString]: 1,
                };
                continue;
            }
            tempData[ele.user_id][dateString]
                ? (tempData[ele.user_id][dateString] += 1)
                : (tempData[ele.user_id][dateString] = 1);
        }
        const filteredTempData = [];

        const keysTempData = Object.keys(tempData);
        const dataFilterRecurse = (keysArray, count = 0) => {
            if (keysArray.length === count) return;
            if (!tempData[keysArray[count]].name) {
                //removes data that isn't attached to an employee, possibly usage from testing
                keysArray.splice(count, 1);
                return dataFilterRecurse(keysArray, count);
            }
            filteredTempData[count] = [];
            for (let [key, value] of Object.entries(
                tempData[keysArray[count]]
            )) {
                if (key === 'name') continue;
                filteredTempData[count].push({
                    name: tempData[keysArray[count]].name,
                    date: new Date(+key).toString(),
                    loads_booked: value,
                });
            }
            filteredTempData[count].sort(
                (a, b) => Date.parse(a.date) - Date.parse(b.date)
            );
            count += 1;
            return dataFilterRecurse(keysArray, count);
        };
        dataFilterRecurse(keysTempData);
        setDateRange(Object.keys(tempDateRange).sort((a, b) => +a - +b));
        setFiltered([...filteredTempData]);
    }, [automailUsageData, automailUsageFilter]);

    return (
        <div className={styles.chartCard}>
            <div className={styles.header}>
                <h1 className={styles.heading}>Automail Usage Trend</h1>
                <DropdownButton
                    value={automailDateSpan}
                    setValue={setAutomailDateSpan}
                    options={[
                        'Last Week',
                        'Last 2 Weeks',
                        'Last 3 Weeks',
                        'Last Month',
                    ]}
                    color='primary'
                    tight
                />
            </div>
            {loading ? (
                <ChartSkeleton />
            ) : filtered && filtered.length !== 0 ? (
                <AutomailUsageChart data={filtered} dateRange={dateRange} />
            ) : (
                <UnavailableText />
            )}
        </div>
    );
};
