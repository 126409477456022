import { r360_products } from 'data';

export const isValidRevenue360 = (user) => {
    const role = user?.role;
    const sub = user?.subscription;
    const subType = user?.subscription?.type;

    let sub_arr;
    if (sub) {
        sub_arr = Object.keys(sub).filter(
            (elem) => typeof sub[elem] === 'boolean' && sub[elem]
        );
    }

    return (
        (role === 'executive' || role === 'admin') &&
        sub_arr?.some((elem) => r360_products.includes(elem)) &&
        subType !== 'pilot'
    );
};

export const isValidAdmin = (user) => {
    const domain = user?.email
        ? user.email.substring(user.email.lastIndexOf('@') + 1)
        : '';

    return (
        user?.role === 'admin' &&
        user?.company === 'Everest' &&
        domain === 'useeverest.com'
    );
};
