import React, { lazy, Suspense } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { useUser } from 'components/Accounts';
import { ProtectedPage } from 'components/Page';
import { DashboardSkeleton } from 'components/Admin';
import styles from 'styles/Admin.module.css';

const Dashboard = lazy(() =>
    import('components/Admin').then((module) => ({ default: module.Dashboard }))
);

const fetchWithToken = async (url, token) => {
    if (!token) {
        throw new Error();
    }
    const res = await fetch(url, { headers: { 'x-access-token': token } });
    const data = await res.json();
    if (data.message && data.message.includes('⚠')) {
        throw new Error();
    }
    return data;
};

const Admin = () => {
    const { token } = useUser();
    const { cache } = useSWRConfig();
    const { data, error } = useSWR(
        [
            process.env.REACT_APP_SMARTBID_SERVER_BASE_URL + '/api/users/all',
            token,
        ],
        fetchWithToken,
        {
            onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                // Never retry on 404.
                if (error.status === 404) return;

                // Only retry up to 10 times.
                if (retryCount >= 10) return;

                // Retry after 1.5 seconds.
                setTimeout(() => revalidate({ retryCount }), 1500);
            },
        }
    );

    const cachedData = cache.get(
        process.env.REACT_APP_SMARTBID_SERVER_BASE_URL + '/api/users/query-all'
    );

    return (
        <ProtectedPage
            title='Admin Dashboard'
            wide
            restrictions={['login-required']}
        >
            <Suspense fallback={<DashboardSkeleton />}>
                <div className={styles.content}>
                    {error || !data ? (
                        cachedData ? (
                            <Dashboard users={cachedData} />
                        ) : (
                            <DashboardSkeleton />
                        )
                    ) : (
                        <Dashboard users={data} />
                    )}
                </div>
            </Suspense>
        </ProtectedPage>
    );
};

export default Admin;
