import classNames from 'classnames';
import { FaCircleDot, FaRegCircle } from 'react-icons/fa6';
import styles from 'styles/Revenue360.module.css';

export const CSVInterface = ({
    userCount,
    filteredCount,
    usageDateSpan,
    ratesDateSpan,
    loadsDateSpan,
    csvSelected,
    setCSVSelected,
    chartView,
    setChartView,
    chartViews,
    usageView,
    ratesView,
    loadsView,
}) => {
    const selectOptions = [
        {
            key: 'csv-chart-smartbid-usage',
            onClick: () => {
                setCSVSelected('smartbidUsage');
                setChartView(usageView);
            },
            selected: csvSelected === 'smartbidUsage',
            label: (
                <span>
                    SmartBid Usage Trend&nbsp;
                    <span
                        style={{
                            fontSize: 'var(--text-base)',
                            color: 'var(--primary-500)',
                        }}
                    >
                        ({usageDateSpan}
                        {userCount !== filteredCount &&
                            chartView === 'Individual' &&
                            `, ${filteredCount}/${userCount} Users`}
                        )
                    </span>
                </span>
            ),
        },
        {
            key: 'csv-chart-rates-comparison',
            onClick: () => {
                setCSVSelected('ratesComparison');
                setChartView(ratesView);
            },
            selected: csvSelected === 'ratesComparison',
            label: (
                <span>
                    Rates Comparison&nbsp;
                    <span
                        style={{
                            fontSize: 'var(--text-base)',
                            color: 'var(--primary-500)',
                        }}
                    >
                        ({ratesDateSpan})
                    </span>
                </span>
            ),
        },
        {
            key: 'csv-chart-loads-comparison',
            onClick: () => {
                setCSVSelected('loadsComparison');
                setChartView(loadsView);
            },
            selected: csvSelected === 'loadsComparison',
            label: (
                <span>
                    Loads Comparison&nbsp;
                    <span
                        style={{
                            fontSize: 'var(--text-base)',
                            color: 'var(--primary-500)',
                        }}
                    >
                        ({loadsDateSpan})
                    </span>
                </span>
            ),
        },
    ];

    return (
        <>
            <div>
                <h2>Select Chart</h2>
                <ul>
                    {selectOptions.map(({ key, onClick, selected, label }) => (
                        <li key={key} onClick={() => onClick()}>
                            <div
                                className={classNames(
                                    selected && styles.selectedExport
                                )}
                            >
                                {selected ? <FaCircleDot /> : <FaRegCircle />}
                            </div>
                            {label}
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <h2>Select View</h2>
                <ul>
                    {csvSelected === 'smartbidUsage' &&
                        chartViews.smartbidUsage.map((view) => (
                            <li key={view} onClick={() => setChartView(view)}>
                                <div
                                    className={classNames(
                                        chartView === view &&
                                            styles.selectedExport
                                    )}
                                >
                                    {chartView === view ? (
                                        <FaCircleDot />
                                    ) : (
                                        <FaRegCircle />
                                    )}
                                </div>
                                <span>{view}</span>
                            </li>
                        ))}
                    {csvSelected === 'ratesComparison' &&
                        chartViews.ratesComparison.map((view) => (
                            <li key={view} onClick={() => setChartView(view)}>
                                <div
                                    className={classNames(
                                        chartView === view &&
                                            styles.selectedExport
                                    )}
                                >
                                    {chartView === view ? (
                                        <FaCircleDot />
                                    ) : (
                                        <FaRegCircle />
                                    )}
                                </div>
                                <span>{view}</span>
                            </li>
                        ))}
                    {csvSelected === 'loadsComparison' &&
                        chartViews.loadsComparison.map((view) => (
                            <li key={view} onClick={() => setChartView(view)}>
                                <div
                                    className={classNames(
                                        chartView === view &&
                                            styles.selectedExport
                                    )}
                                >
                                    {chartView === view ? (
                                        <FaCircleDot />
                                    ) : (
                                        <FaRegCircle />
                                    )}
                                </div>
                                <span>{view}</span>
                            </li>
                        ))}
                </ul>
            </div>
        </>
    );
};
