import { Chart } from 'chart.js';
import { format } from 'date-fns';

export const ratesChartOptions = (id) => {
    const options = {
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y) {
                            label += new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }).format(context.parsed.y);
                        }
                        return label;
                    },
                },
            },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        scaleID: 'x',
                        value: -1,
                        endValue: -1,
                        borderColor: `${window
                            .getComputedStyle(document.getElementById('root'))
                            .getPropertyValue('--grey-600')}`,
                        borderDash: [3, 3],
                    },
                ],
            },
        },
        onHover(e, el) {
            const chart = Chart.getChart(id);
            if (el && el.length) {
                options.plugins.annotation.annotations[0].value = el[0].index;
                options.plugins.annotation.annotations[0].endValue =
                    el[0].index;
                chart.update();
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                ticks: {
                    callback: function (value) {
                        const date = this.getLabelForValue(value);
                        const formatted = format(new Date(date), 'E, MMM d');
                        return formatted;
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                // min: minY,
                title: {
                    display: true,
                    text: 'Rate',
                    font: {
                        family: 'Open Sans',
                        size: 18,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
                ticks: {
                    // exlclude year from labels
                    callback: function (value) {
                        return new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(value);
                    },
                },
            },
        },
    };

    return options;
};

export const loadsChartOptions = () => {
    return {
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        plugins: {
            legend: { display: false },
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                ticks: {
                    callback: function (value) {
                        const date = this.getLabelForValue(value);
                        const formatted = format(new Date(date), 'E, MMM d');
                        return formatted;
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                min: 0,
                title: {
                    display: true,
                    text: 'Loads',
                    font: {
                        family: 'Open Sans',
                        size: 18,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
            },
        },
    };
};

export const usageChartOptions = (id) => {
    const options = {
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        plugins: {
            legend: { display: false },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        scaleID: 'x',
                        value: -1,
                        endValue: -1,
                        borderColor: `${window
                            .getComputedStyle(document.getElementById('root'))
                            .getPropertyValue('--grey-600')}`,
                        borderDash: [3, 3],
                    },
                ],
            },
        },
        onHover(e, el) {
            const chart = Chart.getChart(id);
            if (el && el.length) {
                options.plugins.annotation.annotations[0].value = el[0].index;
                options.plugins.annotation.annotations[0].endValue =
                    el[0].index;
                chart.update();
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                ticks: {
                    callback: function (value) {
                        const date = this.getLabelForValue(value);
                        const formatted = format(new Date(date), 'E, MMM d');
                        return formatted;
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                min: 0,
                title: {
                    display: true,
                    text: 'Uses per Day',
                    font: {
                        family: 'Open Sans',
                        size: 18,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
                ticks: {
                    precision: 0,
                },
            },
        },
    };
    return options;
};

export const automailChartOptions = (id) => {
    const options = {
        maintainAspectRatio: false,
        animation: {
            duration: 0,
        },
        plugins: {
            legend: { display: true },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        scaleID: 'x',
                        value: -1,
                        endValue: -1,
                        borderColor: `${window
                            .getComputedStyle(document.getElementById('root'))
                            .getPropertyValue('--grey-600')}`,
                        borderDash: [3, 3],
                    },
                ],
            },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        return `${context[0].label} Drafted Emails per User`;
                    },
                },
            },
        },
        onHover(e, el) {
            const chart = Chart.getChart(id);
            if (el && el.length) {
                options.plugins.annotation.annotations[0].value = el[0].index;
                options.plugins.annotation.annotations[0].endValue =
                    el[0].index;
                chart.update();
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                ticks: {
                    callback: function (value) {
                        const date = this.getLabelForValue(value);
                        const formatted = format(new Date(date), 'E, MMM d');
                        return formatted;
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                min: 0,
                title: {
                    display: true,
                    text: 'Drafted Emails per Day',
                    font: {
                        family: 'Open Sans',
                        size: 16,
                        style: 'normal',
                        lineHeight: 1.2,
                        weight: 700,
                    },
                    color: `${window
                        .getComputedStyle(document.getElementById('root'))
                        .getPropertyValue('--grey-600')}`,
                },
                ticks: {
                    precision: 0,
                },
            },
        },
    };
    return options;
};
