import { useCSVDownloader } from 'react-papaparse';
import styles from 'styles/Revenue360.module.css';

export const CSVDownloader = ({ data, filename, innerText }) => {
    const { CSVDownloader, Type } = useCSVDownloader();

    return (
        <CSVDownloader
            type={Type.Button}
            filename={filename}
            config={{ delimiter: ',' }}
            data={data}
            className={styles.CSVDownloader}
        >
            {innerText}
        </CSVDownloader>
    );
};
