import { createContext, useEffect, useReducer, useState } from 'react';
import safeStringify from 'fast-safe-stringify';

const initialContext = {
    preview: undefined,
};

const dispatchContext = {
    inheritDispatch: undefined,
    updateDispatch: undefined,
    disableDispatch: undefined,
};

const cleanSubscription = (user) => {
    if (!user?.subscription) {
        user.subscription = {
            sb_vans: Boolean(user?.sb_vans),
            sb_straights: Boolean(user?.sb_straights),
            sb_margin_calculator: Boolean(user?.sb_margin_calculator),
            sb_trailers: Boolean(user?.sb_trailers),
            sb_company_historical: Boolean(user?.sb_company_historical),
            r360_smartbid_usage: Boolean(user?.r360_smartbid_usage),
            r360_van_rates: Boolean(user?.r360_van_rates),
            r360_straight_rates: Boolean(user?.r360_straight_rates),
            r360_opportunity_map: Boolean(user?.r360_opportunity_map),
            r360_combined_rates: Boolean(user?.r360_combined_rates),
            r360_van_kpi: Boolean(user?.r360_van_kpi),
            r360_straight_kpi: Boolean(user?.r360_straight_kpi),
            r360_pie: Boolean(user?.r360_pie),
            r360_scatter: Boolean(user?.r360_scatter),
            r360_gross_profit: Boolean(user?.r360_gross_profit),
            r360_completed_loads: Boolean(user?.r360_completed_loads),
            type: user?.type,
        };
    }

    return user.subscription;
};

const clean = (user) => {
    return {
        role: user?.role,
        company: user?.company,
        rate_multiplier: user?.rate_multiplier,
        subscription: cleanSubscription(user),
        inherited: {
            name: user?.name,
        },
        customized: user?.customized,
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'inherit':
            return {
                preview: action.payload,
            };
        case 'update':
            return {
                preview: action.payload,
            };
        case 'disable':
            return {
                preview: undefined,
            };
        default:
            throw new Error('Invalid dispatch.');
    }
};

export const PreviewContext = createContext(initialContext);
export const PreviewDispatch = createContext(dispatchContext);

export const PreviewProvider = ({ children }) => {
    const [mounted, setMounted] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialContext);

    const inheritPreview = (user) => {
        const preview = clean(user);
        dispatch({ type: 'inherit', payload: preview });
        sessionStorage.setItem('preview', safeStringify(preview));
    };

    const updatePreview = (updated_preview) => {
        const preview = clean(updated_preview);
        dispatch({ type: 'update', payload: preview });
        sessionStorage.setItem('preview', safeStringify(preview));
    };

    const disablePreview = () => {
        dispatch({ type: 'disable' });
        sessionStorage.removeItem('preview');
    };

    const verifyPreview = (preview) => {
        const roles = ['user', 'executive', 'admin'];
        if (!roles.includes(preview.role)) {
            return false;
        }
        if (typeof preview.company !== 'string') {
            return false;
        }
        if (typeof preview.rate_multiplier !== 'number') {
            return false;
        }

        const subscription = preview.subscription;
        const type = subscription?.type;
        delete subscription?.type;

        const productValues = Object.keys(subscription);
        if (productValues.some((product) => typeof product !== 'boolean')) {
            return false;
        }
        if (typeof type !== 'string') {
            return false;
        }

        return true;
    };

    const defaultPreview = () => {
        const dp = {
            role: 'user',
            company: 'Everest',
            rate_multiplier: 1,
            subscription: {
                sb_vans: true,
                sb_straights: false,
                sb_margin_calculator: false,
                sb_trailers: false,
                sb_company_historical: false,
                r360_smartbid_usage: false,
                r360_van_rates: false,
                r360_straight_rates: false,
                r360_opportunity_map: false,
                r360_combined_rates: false,
                r360_van_kpi: false,
                r360_straight_kpi: false,
                r360_pie: false,
                r360_scatter: false,
                r360_gross_profit: false,
                r360_completed_loads: false,
                type: 'full',
            },
        };
        sessionStorage.setItem('preview', safeStringify(dp));
        return dp;
    };

    useEffect(() => {
        if (mounted) {
            const preview = JSON.parse(sessionStorage.getItem('preview'));
            if (preview && !verifyPreview(preview)) {
                dispatch({ type: 'update', payload: defaultPreview() });
            } else {
                dispatch({ type: 'update', payload: preview });
            }
        }

        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [mounted]);

    return (
        <PreviewContext.Provider value={{ preview: state.preview }}>
            <PreviewDispatch.Provider
                value={{
                    update: updatePreview,
                    inherit: inheritPreview,
                    disable: disablePreview,
                }}
            >
                {children}
            </PreviewDispatch.Provider>
        </PreviewContext.Provider>
    );
};
