import { AutocompleteInput } from './AutocompleteInput';
import styles from 'styles/Form.module.css';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

export const HorizontalAutocompleteInput = ({
    id,
    subtitle,
    label,
    register,
    errors,
    watch,
    validate,
    setValue,
    required,
}) => {
    return (
        <div className={styles.horizontalWrapper}>
            <div className={styles.horizontalInputWrapper}>
                <label htmlFor={id} className={styles.label}>
                    <span>{label}</span>
                    {subtitle && (
                        <span className={styles.subtitle}>{subtitle}</span>
                    )}
                </label>
                <AutocompleteInput
                    id={id}
                    value={watch(id) || ''}
                    setValue={setValue}
                    label={label}
                    register={register}
                    errors={errors}
                    watch={watch}
                    validate={validate}
                    onSelect={() => {}}
                    onChange={() => {}}
                    placeholder='Enter city and state'
                    formStyling
                    hideTooltip
                    required={required}
                    hideErrorMessage={true}
                    overrideInputVal
                />
            </div>
            <ErrorMessage
                errors={errors}
                name={id}
                as='span'
                className={classNames('err', styles.horizontalErrMessage)}
            />
        </div>
    );
};
