import { HiFilter } from 'react-icons/hi';
import { FilterDropdown } from './FilterDropdown';
import { Button } from 'components/Button';
import styles from 'styles/UsageFilter.module.css';

export const FilterButton = ({
    data,
    visible,
    setVisible,
    openDropdown,
    setOpenDropdown,
}) => {
    return (
        <span className={styles.filterWrapper}>
            <Button
                uitype='cta'
                color='primary'
                size='sm'
                radius='sm'
                onClick={() => setOpenDropdown(true)}
            >
                <HiFilter /> Filter
            </Button>
            <FilterDropdown
                data={data}
                visible={visible}
                setVisible={setVisible}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
            />
        </span>
    );
};
