export const isValidString = (variable) => {
    return (
        typeof variable === 'string' &&
        variable !== null &&
        variable.trim() !== ''
    );
};

export const isValidObject = (variable) => {
    return (
        typeof variable === 'object' &&
        variable !== null &&
        Object.keys(variable).length !== 0
    );
};
