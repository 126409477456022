import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import classNames from 'classnames';
import { usageChartOptions } from './options';
import styles from 'styles/Revenue360.module.css';

export const OrgUsageChart = ({ data, printable, orientation }) => {
    const id = 'org-usage-chart';
    const options = usageChartOptions(id);

    return (
        <div
            className={classNames(
                !printable && styles.chartContainer,
                printable &&
                    orientation === 'landscape' &&
                    styles.landscapeChartContainer,
                printable &&
                    orientation === 'portrait' &&
                    styles.portraitChartContainer
            )}
            onMouseLeave={() => {
                const chart = Chart.getChart(id);
                options.plugins.annotation.annotations[0].value = -1;
                options.plugins.annotation.annotations[0].endValue = -1;
                chart.update();
            }}
        >
            <Line
                id='org-usage-chart'
                height={80}
                data={data}
                options={options}
            />
        </div>
    );
};
