import React from 'react';
import classNames from 'classnames';
import { FaCheck } from 'react-icons/fa6';
import { Button } from 'components/Button';
import { Input } from 'components/Form';
import styles from 'styles/OptiRates.module.css';
import { VehicleClassDropdown } from '../components';

export const DataCell = ({
    isEditing,
    setIsEditing,
    register,
    watch,
    control,
    errors,
    attr,
    value,
    id,
    row,
    setParsedData,
    flaggedErr,
}) => {
    const updateCell = (e, force) => {
        if (force || e?.keyCode === 13) {
            setIsEditing(false);
            setParsedData((prev) => [
                ...prev.slice(
                    0,
                    prev.findIndex((row) => row.id === id)
                ),
                {
                    ...row,
                    [attr]: watch(`${attr}-${id}`),
                },
                ...prev.slice(prev.findIndex((row) => row.id === id) + 1),
            ]);
        }
    };

    const getDisplayValue = () => {
        if (value?.trim() === '') {
            if (['Origin', 'Destination', 'Vehicle Class'].includes(attr)) {
                return 'NULL';
            }
            return '—';
        }
        return value;
    };

    return (
        <>
            {!isEditing ? (
                <span
                    className={classNames(
                        !row.ignored && flaggedErr && styles.flaggedErr
                    )}
                    onClick={() => setIsEditing(true)}
                >
                    {getDisplayValue()}
                </span>
            ) : (
                <div className={styles.rowEditWrapper}>
                    {attr === 'Vehicle Class' ? (
                        <VehicleClassDropdown
                            id={`${attr}-${id}`}
                            control={control}
                            errors={errors}
                        />
                    ) : (
                        <Input
                            type='text'
                            label={`${attr}-${id}`}
                            register={register}
                            errors={errors}
                            value={watch(`${attr}-${id}`)}
                            watch={watch}
                            className={styles.input}
                            onKeyDown={updateCell}
                        />
                    )}
                    <Button
                        uitype='cta'
                        color='primary'
                        tooltip='Save Changes'
                        onClick={(e) => updateCell(e, true)}
                    >
                        <FaCheck />
                    </Button>
                </div>
            )}
        </>
    );
};
