import { format } from 'date-fns';
import { getFilteredByDate } from 'components/Revenue360/helpers';

const formatDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd');
};

export const formatData = (dateSpan, data, visible) => {
    const filteredUsage = getFilteredByDate(dateSpan[0], [data[0], data[1]]);
    const filteredRates = getFilteredByDate(dateSpan[1], [data[2], data[3]]);
    const filteredLoads = getFilteredByDate(dateSpan[1], [data[4], data[5]]);

    const orgUsageColumns = [
        'date',
        ...filteredUsage[0].datasets.map((obj) => {
            return obj.label.toLowerCase().replace(' ', '_');
        }),
    ];
    const orgUsageData = filteredUsage[0].datasets[0].data.map((d, idx) => {
        return {
            [orgUsageColumns[0]]: formatDate(filteredUsage[0].labels[idx]),
            [orgUsageColumns[1]]: d ? parseInt(d) : '0',
        };
    });

    const filteredUsersUsage = filteredUsage[1].datasets.filter((obj) =>
        visible.includes(obj.label)
    );
    const userUsageColumns = [
        'date',
        ...filteredUsersUsage.map((obj) => {
            return obj.label.toLowerCase().replace(' ', '_');
        }),
    ];
    let userUsageData = [];
    for (let i = 0; i < filteredUsage[1].labels.length; i++) {
        const currUsage = {
            [userUsageColumns[0]]: formatDate(filteredUsage[1].labels[i]),
        };
        for (let j = 0; j < filteredUsersUsage.length; j++) {
            const d = filteredUsersUsage[j].data[i];
            currUsage[userUsageColumns[j + 1]] = d ? parseInt(d) : 0;
        }
        userUsageData.push(currUsage);
    }

    const ratesColumns = [
        'date',
        'vehicle_type',
        ...filteredRates[0].datasets.map((obj) => {
            return obj.label.toLowerCase().replace(' ', '_');
        }),
    ];
    const vanRatesData = filteredRates[0].labels.map((date, idx) => {
        const market = filteredRates[0].datasets[0].data[idx];
        const company = filteredRates[0].datasets[1].data[idx];
        return {
            [ratesColumns[0]]: formatDate(date),
            [ratesColumns[1]]: 'Sprinter/Cargo Vans',
            [ratesColumns[2]]: market ? parseFloat(market).toFixed(3) : null,
            [ratesColumns[3]]: company ? parseFloat(company).toFixed(3) : null,
        };
    });
    const smallStraightRatesData = filteredRates[1].labels.map((date, idx) => {
        const market = filteredRates[1].datasets[0].data[idx];
        const company = filteredRates[1].datasets[1].data[idx];
        return {
            [ratesColumns[0]]: formatDate(date),
            [ratesColumns[1]]: 'Small Straights',
            [ratesColumns[2]]: market ? parseFloat(market).toFixed(3) : null,
            [ratesColumns[3]]: company ? parseFloat(company).toFixed(3) : null,
        };
    });

    const loadsColumns = [
        'date',
        'vehicle_type',
        ...filteredRates[0].datasets.map((obj) => {
            return obj.label.toLowerCase().replace(' ', '_');
        }),
    ];
    const vanLoadsData = filteredLoads[0].labels.map((date, idx) => {
        const market = filteredLoads[0].datasets[0].data[idx];
        const company = filteredLoads[0].datasets[1].data[idx];
        return {
            [loadsColumns[0]]: formatDate(date),
            [loadsColumns[1]]: 'Sprinter/Cargo Vans',
            [loadsColumns[2]]: market ? parseFloat(market) : null,
            [loadsColumns[3]]: company ? parseFloat(company) : null,
        };
    });
    const smallStraightLoadsData = filteredLoads[1].labels.map((date, idx) => {
        const market = filteredLoads[1].datasets[0].data[idx];
        const company = filteredLoads[1].datasets[1].data[idx];
        return {
            [loadsColumns[0]]: formatDate(date),
            [loadsColumns[1]]: 'Small Straights',
            [loadsColumns[2]]: market ? parseFloat(market) : null,
            [loadsColumns[3]]: company ? parseFloat(company) : null,
        };
    });

    return {
        orgUsage: {
            filename: 'org_smartbid_usage',
            data: orgUsageData,
        },
        userUsage: {
            filename: 'individual_smartbid_usage',
            data: userUsageData,
        },
        vanRates: {
            filename: 'sprinter_cargo_van_rates',
            data: vanRatesData,
        },
        smallStraightRates: {
            filename: 'small_straight_rates',
            data: smallStraightRatesData,
        },
        vanLoads: {
            filename: 'sprinter_cargo_van_loads',
            data: vanLoadsData,
        },
        smallStraightLoads: {
            filename: 'small_straight_loads',
            data: smallStraightLoadsData,
        },
    };
};
