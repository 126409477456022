import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useUser } from 'components/Accounts';
import styles from 'styles/OptiRates.module.css';
import classNames from 'classnames';
import { isValidString } from 'helpers/condition';

const displayText = (
    val,
    isLoading = false,
    isIgnored = false,
    isNumber = false
) => {
    if (isLoading && !isIgnored) {
        return (
            <Skeleton
                style={{ display: 'flex' }}
                baseColor='var(--secondary-darker)'
                highlightColor='var(--secondary-dark)'
                height={16}
                width={100}
                borderRadius={5}
            />
        );
    }
    if (!isNumber && !isValidString(val)) {
        return '—';
    }
    if (isNumber && (val === 99999 || !val)) {
        return '—';
    }
    return val;
};

export const ResultsRow = ({ parsedData, idx, isLoading }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const row = parsedData[idx];

    const { user } = useUser();
    const short_name = user?.short_name;

    return (
        <div
            className={classNames(
                row.ignored && styles.flaggedIgnore,
                styles.importTableRow
            )}
        >
            <div>{displayText(row['Origin'])}</div>
            <div>
                {displayText(row['Origin Country'], isLoading, row.ignored)}
            </div>
            <div>{displayText(row['Destination'])}</div>
            <div>
                {displayText(
                    row['Destination Country'],
                    isLoading,
                    row.ignored
                )}
            </div>
            <div>{row['Vehicle Class']}</div>
            <div>{displayText(row['Weight (lb)'])}</div>
            <div>
                {displayText(
                    row['Distance (mi)'],
                    isLoading,
                    row.ignored,
                    true
                )}
            </div>
            <div>
                {displayText(
                    row['CO2 Emission (metric ton)'],
                    isLoading,
                    row.ignored,
                    true
                )}
            </div>
            <div>
                {displayText(row['SmartBid Rate'], isLoading, row.ignored)}
            </div>
            <div>
                {displayText(
                    row['SmartBid Total Rate'],
                    isLoading,
                    row.ignored
                )}
            </div>
            <div>
                {displayText(
                    row[`${short_name} Historical Rate`],
                    isLoading,
                    row.ignored
                )}
            </div>
            <div>
                {displayText(
                    row[`${short_name} Historical Total Rate`],
                    isLoading,
                    row.ignored
                )}
            </div>
        </div>
    );
};
