import React, { useState, useCallback } from 'react';
import { usePapaParse, useCSVDownloader } from 'react-papaparse';
import { useDropzone } from 'react-dropzone';
import { FaTableList } from 'react-icons/fa6';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { CO2EmissionCopy, FormattingCopy } from './components';
import styles from 'styles/OptiRates.module.css';

const CONTENT_ROWS_LIMIT = 1000;

// helper function for leading 0s used to order rows via ID
const pad = (num, size) => {
    var s = '0000000000' + num;
    return s.substring(s.length - size);
};

const validateVehicleClass = (line, terms) => {
    if (typeof line !== 'string' || line === null) {
        return false;
    }
    return terms.some((term) => line.toUpperCase().includes(term));
};

const isSprinterCargoVan = (line) => {
    const terms = ['SPRINTER', 'VAN', 'C 12 FOOT'];
    return validateVehicleClass(line, terms);
};

const isSmallStraightTruck = (line) => {
    const terms = ['SMALL STRAIGHT', '18 FOOT'];
    return validateVehicleClass(line, terms);
};

const isLargeStraightTruck = (line) => {
    const terms = [
        'STRAIGHT',
        'LARGE STRAIGHT',
        '20 FOOT',
        '24 FOOT',
        '26 FOOT',
        'BOX TRUCK',
    ];
    return validateVehicleClass(line, terms);
};

const isStraightTruck = (line) => {
    return isSmallStraightTruck(line) || isLargeStraightTruck(line);
};

const isTractorTrailer = (line) => {
    const terms = [
        'FULL TRUCK LOAD',
        'TRACTOR',
        'DRY VAN',
        'SEMI',
        '18-WHEELER',
    ];
    return validateVehicleClass(line, terms);
};

const isRefrigerated = (line) => {
    const terms = [
        'REEFER',
        'FRIDGE',
        'TEMPERATURE-CONTROLLER',
        'COOLER TRUCK',
        'REFRIGERATED',
    ];
    return validateVehicleClass(line, terms);
};

const isFlatbed = (line) => {
    const terms = ['PLATFORM TRUCK', 'FLAT DECK TRUCK', 'FLATBED', 'FLAT BED'];
    return validateVehicleClass(line, terms);
};

export const ImportModule = ({ setImported, setParsedData }) => {
    const [err, setErr] = useState(undefined);

    const { readString } = usePapaParse();
    const { CSVDownloader, Type } = useCSVDownloader();

    const onDropAccepted = useCallback(
        (acceptedFiles) => {
            setErr(undefined);
            const reader = new FileReader();

            reader.onabort = () => console.error('File reading was aborted');
            reader.onerror = () => console.error('File reading failed');
            reader.onload = () => {
                readString(reader.result, {
                    complete: (results) => {
                        setParsedData([]);
                        let file = results.data;
                        const header = file[0];

                        let includes_origin = false;
                        let includes_destination = false;
                        let includes_vehicle_class = false;
                        for (let heading of header) {
                            if (heading.toUpperCase() === 'ORIGIN') {
                                includes_origin = true;
                            } else if (
                                heading.toUpperCase() === 'DESTINATION'
                            ) {
                                includes_destination = true;
                            } else if (
                                heading.toUpperCase() === 'VEHICLE CLASS'
                            ) {
                                includes_vehicle_class = true;
                            }
                        }

                        if (
                            !(
                                includes_origin &&
                                includes_destination &&
                                includes_vehicle_class
                            )
                        ) {
                            setErr(
                                'Detected missing columns. Ensure that "Origin", "Destination", and "Vehicle Class" are included in the CSV file.'
                            );
                            return;
                        }

                        if (file.length > CONTENT_ROWS_LIMIT + 1) {
                            setErr(
                                `CSV file exceeds ${CONTENT_ROWS_LIMIT} rows that can be used to get bulk rates.`
                            );
                            return;
                        }

                        // remove newline at the end if one exists
                        if (file[file.length - 1].length === 1) {
                            file = file.slice(0, file.length - 1);
                        }

                        const res = [];
                        for (let i = 1; i < file.length; i++) {
                            const row = file[i];
                            const parsed_row = header.reduce(
                                (acc, curr, idx) => {
                                    const original_value = row[idx]?.trim();

                                    // adjust casing to be uniform when computing rates later
                                    if (curr.toUpperCase() === 'ORIGIN') {
                                        acc['Origin'] = original_value;
                                    } else if (
                                        curr.toUpperCase() === 'DESTINATION'
                                    ) {
                                        acc['Destination'] = original_value;
                                    } else if (
                                        curr.toUpperCase() === 'VEHICLE CLASS'
                                    ) {
                                        let vehicle_class = 'Other';
                                        if (
                                            isSprinterCargoVan(original_value)
                                        ) {
                                            vehicle_class =
                                                'Sprinter/Cargo Van';
                                        } else if (
                                            isSmallStraightTruck(original_value)
                                        ) {
                                            vehicle_class = 'Small Straight';
                                        } else if (
                                            isLargeStraightTruck(original_value)
                                        ) {
                                            vehicle_class = 'Large Straight';
                                        } else if (
                                            isStraightTruck(original_value)
                                        ) {
                                            vehicle_class = 'Straight Truck';
                                        } else if (
                                            isTractorTrailer(original_value)
                                        ) {
                                            vehicle_class = 'Tractor Trailer';
                                        } else if (
                                            isRefrigerated(original_value)
                                        ) {
                                            vehicle_class = 'Refrigerated';
                                        } else if (isFlatbed(original_value)) {
                                            vehicle_class = 'Flatbed';
                                        }
                                        acc['Vehicle Class'] = vehicle_class;
                                    } else if (
                                        curr.toUpperCase() === 'WEIGHT'
                                    ) {
                                        acc['Weight (lb)'] = original_value;
                                    } else {
                                        acc[curr] = original_value;
                                    }
                                    return acc;
                                },
                                {}
                            );
                            res.push({
                                ...parsed_row,
                                id: `${pad(i, 6)}-${nanoid()}`,
                            });
                        }
                        setParsedData(res);
                        setImported(true);
                    },
                });
            };

            acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
        },
        [readString, setParsedData, setImported]
    );

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
        useDropzone({
            accept: {
                'text/csv': [],
            },
            maxFiles: 1,
            onDropAccepted,
        });

    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    return (
        <div className={styles.module}>
            <div className={styles.moduleHeader}>
                <h2 className={styles.moduleHeading}>
                    Get Bulk Rates for a Spreadsheet
                </h2>
                <p className={styles.moduleCopy}>
                    Get rates for multiple US and Canadian routes.
                </p>
                <p className={styles.moduleCopy}>
                    Use{' '}
                    <CSVDownloader
                        type={Type.Link}
                        filename={'everest-rates-batch-template'}
                        config={{ delimiter: ',' }}
                        data={[
                            {
                                Origin: 'New York, NY',
                                Destination: '32819',
                                'Vehicle Class': 'Sprinter/Cargo Van',
                                'Weight (lb)': '',
                            },
                        ]}
                        className={styles.inlineCsvDownload}
                    >
                        this CSV template
                    </CSVDownloader>{' '}
                    to make sure your upload includes the required columns:
                </p>
                <ul className={styles.moduleList}>
                    <li>Origin</li>
                    <li>Destination</li>
                    <li>Vehicle Class</li>
                </ul>
                <FormattingCopy />
                <CO2EmissionCopy />
                <p className={styles.moduleCopy}>
                    <b>Max {CONTENT_ROWS_LIMIT} rows</b> at a time will be
                    processed. Files with more rows will be rejected.
                </p>
            </div>
            <div className={styles.moduleContent}>
                <div className={styles.dropzone}>
                    <div {...getRootProps()} className={styles.dropzoneRoot}>
                        <input {...getInputProps()} />
                        <div className={styles.prompt}>
                            <div className={styles.fileIcon}>
                                <FaTableList />
                            </div>
                            <p>Drop a file here, or click to select a file</p>
                            <em>(*.csv files only)</em>
                        </div>
                    </div>
                </div>
                {(acceptedFileItems.length > 0 ||
                    fileRejectionItems.length > 0) && (
                    <div className={styles.droppedFiles}>
                        {acceptedFileItems.length > 0 && (
                            <>
                                <span>Accepted File</span>
                                <ul className={styles.files}>
                                    {acceptedFileItems}
                                </ul>
                            </>
                        )}
                        {fileRejectionItems.length > 0 && (
                            <>
                                <span>Rejected File(s)</span>
                                <ul className={styles.files}>
                                    {fileRejectionItems}
                                </ul>
                            </>
                        )}
                    </div>
                )}
                {err && (
                    <span className={classNames('err', styles.error)}>
                        ⚠ {err}
                    </span>
                )}
            </div>
        </div>
    );
};
