import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from 'styles/Revenue360.module.css';

export const PrintSkeleton = ({ printable }) => (
    <>
        {printable && (
            <div className={styles.printSkeleton}>
                {/* <Skeleton
                    height={328}
                    borderRadius={8}
                /> */}
                <Skeleton height={365} borderRadius={8} />
                <Skeleton height={365} borderRadius={8} />
            </div>
        )}
    </>
);

export const UsageHeaderSkeleton = () => (
    <div className={styles.actionsSkeleton}>
        <Skeleton
            style={{ display: 'flex' }}
            baseColor='var(--secondary-dark)'
            highlightColor='var(--secondary)'
            height={32}
            width={180}
            borderRadius={5}
        />
        <Skeleton
            style={{ display: 'flex' }}
            baseColor='var(--secondary-dark)'
            highlightColor='var(--secondary)'
            height={32}
            width={260}
            borderRadius={5}
        />
        <div className={styles.exportSkeleton}>
            {/* <Skeleton
                baseColor='var(--secondary-dark)'
                highlightColor='var(--secondary)'
                height={35}
                width={120}
                borderRadius={5}
            /> */}
        </div>
    </div>
);

export const RatesLoadsHeaderSkeleton = () => (
    <div className={styles.actionsSkeleton}>
        <Skeleton
            style={{ display: 'flex' }}
            baseColor='var(--secondary-dark)'
            highlightColor='var(--secondary)'
            height={32}
            width={180}
            borderRadius={5}
        />
        <Skeleton
            style={{ display: 'flex' }}
            baseColor='var(--secondary-dark)'
            highlightColor='var(--secondary)'
            height={32}
            width={310}
            borderRadius={5}
        />
        <div className={styles.exportSkeleton}>
            {/* <Skeleton
                baseColor='var(--secondary-dark)'
                highlightColor='var(--secondary)'
                height={35}
                width={120}
                borderRadius={5}
            /> */}
        </div>
    </div>
);

export const LegendSkeleton = () => (
    <div className={styles.legendWrapper}>
        <Skeleton
            baseColor='var(--secondary-dark)'
            highlightColor='var(--secondary)'
            height={30}
            width={120}
            borderRadius={5}
        />
        <Skeleton
            baseColor='var(--secondary-dark)'
            highlightColor='var(--secondary)'
            height={30}
            width={120}
            borderRadius={5}
        />
    </div>
);

export const ChartSkeleton = () => (
    <Skeleton
        baseColor='var(--secondary-dark)'
        highlightColor='var(--secondary)'
        height={315}
        borderRadius={15}
    />
);
