import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useUser } from 'components/Accounts';
import { Card } from 'components/Card';
import { Input } from 'components/Form';
import styles from 'styles/Settings.module.css';
import { Button } from 'components/Button';
import { smartbidServer } from 'lib/api';

export const DriverRatesCard = ({ company_id }) => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [companySettings, setCompanySettings] = useState(undefined);
    const initialSettings = useRef();
    const watchRates = watch();
    const { user, token } = useUser();

    useEffect(() => {
        if (company_id && !companySettings && !loading) {
            smartbidServer.get(`/api/automail/companies/${company_id}/settings`)
                .then((e) => {
                    setCompanySettings(e.data._results);
                    initialSettings.current = e.data._results;
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company_id, loading]);

    const onSubmit = async () => {
        setLoading(true);
        const companyUpdateCalls = [];
        for (const setting in companySettings) {
            if (initialSettings.current[setting] === companySettings[setting])
                continue;
            companyUpdateCalls.push(
                smartbidServer.patch(`api/automail/companies/${company_id}/settings`,
                    { [setting]: companySettings[setting] },
                    { headers: { authorization: user?.google_api_key } }
                )
            );
        }

        Promise.allSettled(companyUpdateCalls)
            .then((e) => {
                setMessage('Driver Rates successfully updated!');
                setLoading(false);
            })
            .catch((e) => {
                setMessage('⚠ Error with updating CompanySettings');
                setLoading(false);
            });
    };

    return (
        <Card wide smallPadding>
            <h2 className={styles.heading}>Change Driver Rates</h2>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                {typeof companySettings !== 'undefined' && (
                    <>
                        <span>
                            <Input
                                type='radio'
                                label='dynamicRates'
                                register={register}
                                errors={errors}
                                watch={watch}
                                narrow
                                onChange={() => {
                                    setCompanySettings({
                                        ...companySettings,
                                        use_custom_driver_rate:
                                            !companySettings.use_custom_driver_rate,
                                    });
                                }}
                                additionalProps={{
                                    checked:
                                        !companySettings.use_custom_driver_rate,
                                }}
                            />
                            Dynamic Rates
                        </span>
                        <span>
                            <Input
                                type='radio'
                                label='flatRates'
                                register={register}
                                errors={errors}
                                watch={watch}
                                narrow
                                onChange={(e) => {
                                    setCompanySettings({
                                        ...companySettings,
                                        use_custom_driver_rate:
                                            !companySettings.use_custom_driver_rate,
                                    });
                                }}
                                additionalProps={{
                                    checked:
                                        companySettings.use_custom_driver_rate,
                                }}
                            />
                            Flat Rates
                        </span>
                    </>
                )}

                {/* Dynamic Rates section */}
                {companySettings && !companySettings.use_custom_driver_rate && (
                    <div>
                        <div className={styles.description}>
                            Drivers will receive the{' '}
                            <strong>
                                lowest rate out of the historical, market, and
                                SmartBid rates
                            </strong>{' '}
                            multiplied by the following adjustment percentage:
                            <br />
                            <br />
                        </div>
                        <Input
                            type='text'
                            placeholder='% Adjustment'
                            label='driverRateMargin'
                            value={companySettings.driver_rate_margin ?? 0} //if no value existed from a first pull from DB, can remove null coalescing if DB has a default
                            register={register}
                            required={!companySettings.use_custom_driver_rate}
                            errors={errors}
                            watch={watch}
                            autoComplete={false}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const format =
                                    /^0*[1]{0,1}([0-9]{1,2}){0,1}([.]([0-9]{0,2})?)?$|^(200){1}([.]([0]+)?)?$/;
                                if (
                                    inputValue !== '' ||
                                    format.test(inputValue)
                                ) {
                                    setCompanySettings({
                                        ...companySettings,
                                        driver_rate_margin: inputValue
                                            ? parseFloat(inputValue)
                                            : 0,
                                    });
                                }
                            }}
                        />
                    </div>
                )}
                {/* Flat Rates section */}
                {companySettings && companySettings.use_custom_driver_rate && (
                    <div>
                        <div className={styles.description}>
                            Drivers will receive the following{' '}
                            <strong>fixed rates </strong> per vehicle:
                            <br />
                            <br />
                        </div>
                        <Input
                            type='text'
                            placeholder='Van Rate ($/mi)'
                            label='vanRate'
                            value={companySettings.driver_van_rate}
                            register={register}
                            required={companySettings.use_custom_driver_rate}
                            errors={errors}
                            textBottomMargin
                            watch={watch}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const format =
                                    /^0*([0-9]{1}){0,1}([.]([0-9]{0,2})?)?$|^(10){1}([.]([0]+)?)?$/;
                                if (
                                    inputValue === '' ||
                                    format.test(inputValue)
                                ) {
                                    setCompanySettings({
                                        ...companySettings,
                                        driver_van_rate: parseFloat(inputValue),
                                    });
                                }
                            }}
                        />
                        <Input
                            type='text'
                            placeholder='Small Straight Rate ($/mi)'
                            label='smallStraightRate'
                            value={companySettings.driver_small_straight_rate}
                            register={register}
                            required={companySettings.use_custom_driver_rate}
                            autoComplete={false}
                            errors={errors}
                            textBottomMargin
                            watch={watch}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const format =
                                    /^0*([0-9]{1}){0,1}([.]([0-9]{0,2})?)?$|^(10){1}([.]([0]+)?)?$/;
                                if (
                                    inputValue === '' ||
                                    format.test(inputValue)
                                ) {
                                    setCompanySettings({
                                        ...companySettings,
                                        driver_small_straight_rate:
                                            parseFloat(inputValue),
                                    });
                                }
                            }}
                        />

                        <Input
                            type='text'
                            placeholder='Large Straight Rate ($/mi)'
                            label='largeStraightRate'
                            value={companySettings.driver_large_straight_rate}
                            register={register}
                            required={companySettings.use_custom_driver_rate}
                            autoComplete={false}
                            errors={errors}
                            textBottomMargin
                            watch={watch}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const format =
                                    /^0*([0-9]{1}){0,1}([.]([0-9]{0,2})?)?$|^(10){1}([.]([0]+)?)?$/;
                                if (
                                    inputValue === '' ||
                                    format.test(inputValue)
                                ) {
                                    setCompanySettings({
                                        ...companySettings,
                                        driver_large_straight_rate:
                                            parseFloat(inputValue),
                                    });
                                }
                            }}
                        />
                    </div>
                )}
                {message !== '' && (
                    <p className={message.includes('⚠') ? 'err' : 'success'}>
                        {message}
                    </p>
                )}
                <div className={styles.warning}>
                    <br />
                    Applies to the whole company.
                    <br />
                </div>
                <Button
                    uitype='cta'
                    color='primary-dark'
                    size='lg'
                    fullwidth
                    isLoading={loading}
                >
                    Submit Driver Rate Changes
                </Button>
            </form>
        </Card>
    );
};
