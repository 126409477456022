import React, { lazy, Suspense, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ProtectedPage } from 'components/Page';
import { useUser } from 'components/Accounts';
import { FaChevronDown } from 'react-icons/fa6';
import styles from 'styles/SmartBid.module.css';
import { formatGridData } from 'components/Revenue360/helpers';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'styles/ag-theme-laneData.css';

const SmartBidCard = lazy(() =>
    import('components/SmartBid').then((module) => ({
        default: module.SmartBidCard,
    }))
);
const SmartBidResults = lazy(() =>
    import('components/SmartBid').then((module) => ({
        default: module.SmartBidResults,
    }))
);
const GoogleMap = lazy(() =>
    import('components/MapChart/GoogleMap').then((module) => ({
        default: module.GoogleMap,
    }))
);
const FeedbackModal = lazy(() =>
    import('components/Feedback').then((module) => ({
        default: module.FeedbackModal,
    }))
);
const RevenueChartCard = lazy(() =>
    import('components/SmartBid').then((module) => ({
        default: module.RevenueChartCard,
    }))
);

const SmartBid = () => {
    const { user } = useUser();
    const sub = user?.subscription;
    const [margin, setMargin] = useState('0');
    const [accessorialCharges, setAccessorialCharges] = useState(0);
    const [result, setResult] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState(undefined);
    const [location, setLocation] = useState({
        lat: 38.18,
        lng: -87.34,
    });
    const [zoom, setZoom] = useState(4);
    const [ratesDateSpan, setRatesDateSpan] = useState('Last Month');
    const [ratesView, setRatesView] = useState('Sprinter/Cargo Vans');
    const [loadDetailRowData, setLoadDetailRowData] = useState([]);
    const [loadDetailColumnDefs, setLoadDetailColumnDefs] = useState([]);
    const [topCustomerRowData, setTopCustomerRowData] = useState([]);
    const [topCustomerColumnDefs, setTopCustomerColumnDefs] = useState([]);

    const getMidpoint = (res, type) => {
        let locations = [res.origin];
        if (res.stops && res.stops.length > 0) {
            locations = [...locations, ...res.stops];
        }
        locations.push(res.destination);

        let sum = 0;
        for (let i = 0; i < locations.length; i++) {
            sum += parseFloat(locations[i][type]);
        }
        return sum / locations.length;
    };

    const getZoom = (res) => {
        let longitude = {
            min: res.origin.longitude,
            max: res.origin.longitude,
        };
        let latitude = { min: res.origin.latitude, max: res.origin.latitude };
        if (res.stops && res.stops.length > 0) {
            for (let i = 0; i < res.stops.length; i++) {
                longitude.min = Math.min(longitude.min, res.stops[i].longitude);
                longitude.max = Math.max(longitude.max, res.stops[i].longitude);
                latitude.min = Math.min(latitude.min, res.stops[i].latitude);
                latitude.max = Math.max(latitude.max, res.stops[i].latitude);
            }
        }
        longitude.min = Math.min(longitude.min, res.destination.longitude);
        longitude.max = Math.max(longitude.max, res.destination.longitude);
        latitude.min = Math.min(latitude.min, res.destination.latitude);
        latitude.max = Math.max(latitude.max, res.destination.latitude);

        const lng_zoom =
            9.85 * Math.pow(Math.abs(longitude.max - longitude.min), -0.236); // 9.85x^-0.236
        const lat_zoom =
            9.85 * Math.pow(Math.abs(latitude.max - latitude.min), -0.236); // 9.85x^-0.236
        setZoom(Math.min(lng_zoom, lat_zoom));
    };

    useEffect(() => {
        if (Object.keys(result).length !== 0) {
            if (result.message) {
                setErr(result.message);
            }
            if (result.origin && result.destination) {
                getZoom(result);
                setLocation({
                    lat: getMidpoint(result, 'latitude'),
                    lng: getMidpoint(result, 'longitude'),
                });
            }
            if (
                err === undefined &&
                result.message === undefined &&
                result.laneInfo !== undefined
            ) {
                /** BELOW CREATES DATA FOR GRID */
                setLoadDetailColumnDefs([
                    {
                        headerName: `${
                            user?.short_name ?? ''
                        } Lane Data - Last 90 Days`,
                        children: [
                            {
                                headerName: 'Order Number',
                                field: 'uorderpk',
                                width: 120,
                                suppressMovable: true,
                            },
                            {
                                headerName: 'Vehicle Class',
                                field: 'vehicleclass_name',
                                width: 140,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Booked Date',
                                field: 'bookeddate',
                                width: 118,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Revenue',
                                field: 'revenue_linehaul',
                                width: 100,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Cost',
                                field: 'cost_linehaul',
                                width: 100,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Pickup Location',
                                field: 'pickup_location',
                                width: 140,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Pickup Date',
                                field: 'firstpickup_datetime',
                                width: 118,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Delivery Location',
                                field: 'dropoff_location',
                                width: 140,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Delivery Date',
                                field: 'lastdelivery_datetime',
                                width: 118,
                                suppressMovable: true,
                                sortable: true,
                            },
                            {
                                headerName: 'Distance',
                                field: 'distancemiles',
                                width: 100,
                                suppressMovable: true,
                                sortable: true,
                            },
                        ],
                    },
                ]);

                // setLoadDetailRowData(
                //     formatGridData(
                //         ...(result.laneInfo.van.loadList ?? [])
                //     ).concat(
                //         formatGridData(
                //             ...(result.laneInfo.small_straight.loadList ?? [])
                //         ).concat(
                //             formatGridData(
                //                 ...(result.laneInfo.large_straight.loadList ??
                //                     [])
                //             ).concat(
                //                 formatGridData(
                //                     ...(result.laneInfo.tractor.loadList ?? [])
                //                 )
                //             )
                //         )
                //     )
                // );

                // spread operator instead of concat() more readable but needs testing
                setLoadDetailRowData([
                    ...formatGridData(...(result.laneInfo.van.loadList ?? [])),
                    ...formatGridData(
                        ...(result.laneInfo.small_straight.loadList ?? [])
                    ),
                    ...formatGridData(
                        ...(result.laneInfo.large_straight.loadList ?? [])
                    ),
                    ...formatGridData(
                        ...(result.laneInfo.tractor.loadList ?? [])
                    ),
                ]);

                setTopCustomerColumnDefs([
                    {
                        headerName: 'Top Customers - Last 90 Days',
                        children: [
                            {
                                headerName: 'Customer Code',
                                field: 'customer_code',
                                width: 150,
                                suppressMovable: true,
                            },
                            {
                                headerName: 'Customer Name',
                                field: 'customer_name',
                                width: 150,
                                suppressMovable: true,
                            },
                            {
                                headerName: 'Load Count',
                                field: 'count',
                                width: 150,
                                suppressMovable: true,
                            },
                        ],
                    },
                ]);
                setTopCustomerRowData(result.laneInfo.topCustomers);
            }
        }
    }, [result, err, user?.short_name]);

    return (
        <ProtectedPage title='SmartBid' wide restrictions={['login-required']}>
            <Suspense fallback={<></>}>
                <div
                    className={classNames(styles.container, styles.scrollSnap)}
                >
                    <div className={styles.homeSection} id='home-section'>
                        <div
                            className={classNames(
                                styles.smartbidContent,
                                sub?.sb_company_historical &&
                                    styles.widerContent
                            )}
                        >
                            <SmartBidCard
                                margin={margin}
                                setMargin={setMargin}
                                setAccessorialCharges={setAccessorialCharges}
                                result={result}
                                setResult={setResult}
                                setErr={setErr}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                            <SmartBidResults
                                margin={margin}
                                accessorialCharges={accessorialCharges}
                                result={result}
                                err={err}
                                isLoading={isLoading}
                            />
                        </div>
                        <GoogleMap
                            location={location}
                            zoomLevel={zoom}
                            result={result}
                            isLoading={isLoading}
                        />
                        {Object.keys(result).length !== 0 && (
                            <span className={styles.scrollButton}>
                                <a href='#graph-section'>
                                    <FaChevronDown />
                                    {`${
                                        user?.short_name
                                            ? user?.short_name.toUpperCase()
                                            : 'COMPANY'
                                    } ANALYTICS`}
                                </a>
                            </span>
                        )}
                    </div>
                    {Object.keys(result).length !== 0 && (
                        <div id='graph-section' className={styles.laneDetails}>
                            {!isLoading &&
                                err === undefined &&
                                Object.keys(result).length !== 0 &&
                                result.rates !== undefined &&
                                result.message === undefined && (
                                    <>
                                        <RevenueChartCard
                                            result={result}
                                            company={user?.company}
                                            dateSpan={ratesDateSpan}
                                            setDateSpan={setRatesDateSpan}
                                            ratesView={ratesView}
                                            setRatesView={setRatesView}
                                        />
                                        <div
                                            className={classNames(
                                                'ag-theme-laneData',
                                                styles.laneDetailTable
                                            )}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            <AgGridReact
                                                columnDefs={
                                                    loadDetailColumnDefs
                                                }
                                                rowData={loadDetailRowData}
                                                alwaysShowVerticalScroll
                                                alwaysShowHorizontalScroll
                                            />
                                        </div>
                                        <div
                                            className={classNames(
                                                'ag-theme-laneData',
                                                styles.topCustomers
                                            )}
                                            style={{
                                                height: '100%',
                                                width: 470,
                                            }}
                                        >
                                            <AgGridReact
                                                columnDefs={
                                                    topCustomerColumnDefs
                                                }
                                                rowData={topCustomerRowData}
                                                alwaysShowVerticalScroll
                                                alwaysShowHorizontalScroll
                                            />
                                        </div>
                                    </>
                                )}
                        </div>
                    )}
                </div>
                <FeedbackModal />
            </Suspense>
        </ProtectedPage>
    );
};

export default SmartBid;
