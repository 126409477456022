import React from 'react';
import { NavLink } from 'react-router-dom';
import { Page } from 'components/Page';
import { Button } from 'components/Button';
import styles from 'styles/404.module.css';

const Error404 = () => {
    return (
        <Page includeBackground>
            <div className={styles.content}>
                <h1>404</h1>
                <p>The page you&apos;re looking for does not exist.</p>
                <NavLink to='/'>
                    <Button uitype='cta' color='primary-dark' size='lg'>
                        Go Back to Homepage
                    </Button>
                </NavLink>
            </div>
        </Page>
    );
};

export default Error404;
